<template>
  <div class="ud-flex ud-flex-col ud-flex-wrap ud-justify-center">
    <!-- FILTERS START -->
    <div class="ud-flex ud-flex-wrap ud-justify-center">
      <div
        class="
          ud-flex ud-flex-col ud-text-white ud-m-4 ud-mt-0 ud-border-gray-500
        "
      >
        Offer Filter
        <div class="ud-flex ud-justify-center">
          <TSelect
            class="ud-w-[310px] ud-flex-grow-1"
            :els="getAskNames()"
            ref="offerSelectFilter"
            v-bind:selected.sync="askFilter"
            @change="(sel, token_id, balance) => updateOfferFilter(token_id)"
          ></TSelect>
          <div
            class="
              ud-ml-2
              ud-min-w-[10px]
              ud-max-h-[40px]
              ud-flex
              ud-items-center
              ud-text-gray-300
              hover:ud-text-gray-100
              ud-cursor-pointer
            "
            @click="clearOfferFilter()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ud-h-5 ud-w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="ud-flex ud-flex-col ud-text-white ud-m-4 ud-mt-0">
        Ask Filter
        <div class="ud-flex">
          <TSelect
            class="ud-w-[310px] ud-flex-grow-1"
            :els="getAskNames()"
            ref="askSelectFilter"
            v-bind:selected.sync="askFilter"
            @change="(sel, token_id, balance) => updateAskFilter(token_id)"
          ></TSelect>
          <div
            class="
              ud-ml-2
              ud-min-w-[10px]
              ud-max-h-[40px]
              ud-flex
              ud-items-center
              ud-text-gray-300
              hover:ud-text-gray-100
              ud-cursor-pointer
            "
            @click="clearAskFilter()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ud-h-5 ud-w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- FILTERS END -->

    <div class="ud-flex ud-flex-col ud-text-gray-400 ud-justify-center ud-mt-4">
      <div v-if="items.length == 0" class="ud-flex ud-justify-center ud-mb-3">
        No offers found.
      </div>

      <div v-if="items.length > 0" class="ud-flex ud-justify-center">
        <button
          class="
            disabled:ud-opacity-40
            ud-items-center
            ud-flex
            ud-justify-center
            ud-text-base
            ud-text-gray-300
        
            ud-py-0
            ud-px-0
            hover:ud-shadow-signUp hover:ud-bg-opacity-90
            ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
          "
          @click="prevPage()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="ud-w-5 ud-h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        </button>

        <div class="ud-mx-2">{{ currentPage }} of {{ pages }}</div>

        <button
          class="
            disabled:ud-opacity-40
            ud-items-center
            ud-flex
            ud-justify-center
            ud-text-base
            ud-text-gray-300
           
            ud-py-0
            ud-px-0
            ud-max-h-8
            hover:ud-shadow-signUp hover:ud-bg-opacity-90
            ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
          "
          @click="nextPage()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="ud-w-5 ud-h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button>
      </div>
    </div>

    <!--- OFFERS START -->

    <div
      class="
        ud-flex
        ud-flex-wrap
        ud-justify-center
        ud-border-t
        ud-border-gray-700
        ud-mt-6
      "
    >
      <div
        class="ud-m-3 ud-min-w-[340px] noselect"
        v-for="(item) in paginated"
        v-bind:key="item.key"
      >
        <div>
          <div>
            <p
              class="
                ud-text-white
                ud-text-center                
                ud-text-xs
                ud-border-b-0
                ud-rounded-t-lg
                ud-flex
                ud-flex-col
                ud-border-gray-800
                ud-p-2
                ud-m-1
                ud-mb-0
              "
              style="background-color: #0b113a"
            >
             {{ item.owner }}
            </p>
          </div>
          <div
            class="
              ud-border
              ud-border-t-0
              ud-flex
              ud-flex-col
              ud-rounded-b-lg
              ud-border-gray-800
              ud-p-1
              ud-m-1
              ud-mt-0
              ud-flex-grow
            "
            style="background-color: #1d2144"
          >
            <div class="ud-flex ud-flex-row ud-flex-grow">
              <div
                class="ud-flex ud-flex-col ud-p-1 ud-basis-1/2 ud-items-center"
              >
                <div
                  class="
                    ud-text-center
                    ud-text-white
                    ud-text-xs
                    ud-border-b
                    ud-border-gray-600
                    ud-mb-1
                  "
                >
                  Offers
                </div>
                <div v-for="o in item.offerTokens" v-bind:key="o.token_id">
                  <coinChip
                    class="ud-m-1"
                    :showBalance="true"
                    :coinName="main.getTokenName(o.token_id)"
                    :balance="o.amount"
                    :owned="true"
                  ></coinChip>
                </div>
                <div
                  v-if="item.offerAmount > 0"
                  class="
                    ud-flex
                    ud-mr-1
                    ud-mt-1
                    ud-text-center
                    ud-align-right
                    ud-text-lg
                    ud-text-white
                  "
                >
                  <div class="ud-grow ud-text-right">
                    {{ item.offerAmount }}
                  </div>
                  <img
                    class="ud-mt-[4px] ud-w-6 ud-h-6"
                    src="../assets/tezos-logo.svg"
                  />
                </div>
              </div>

              <div class="ud-flex ud-flex-row ud-flex-grow">
                <div
                  class="
                    ud-flex
                    ud-flex-col
                    ud-p-1
                    ud-flex-grow
                    ud-basis-1/2
                    ud-items-center
                  "
                >
                  <div
                    class="
                      ud-text-center
                      ud-text-white
                      ud-text-xs
                      ud-border-b
                      ud-border-gray-600
                      ud-mb-1
                    "
                  >
                    In Exchange For
                  </div>
                  <div v-for="o in item.askTokens" v-bind:key="o.token_id">
                    <coinChip
                      class="ud-m-1"
                      :showBalance="true"
                      :coinName="main.getTokenName(o.token_id)"
                      :balance="o.amount"
                      :owned="true"
                    ></coinChip>
                  </div>
                  <div
                    v-if="item.askAmount > 0"
                    class="
                      ud-flex
                      ud-mr-1
                      ud-mt-1
                      ud-text-center
                      ud-align-right
                      ud-text-lg
                      ud-text-white
                    "
                  >
                    <div class="ud-grow ud-text-right">
                      {{ item.askAmount }}
                    </div>
                    <img
                      class="ud-mt-[4px] ud-w-6 ud-h-6"
                      src="../assets/tezos-logo.svg"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="ud-m-1 ud-mb-0 ud-mt-2 ud-flex-grow">
              <button
                :disabled="
                  cancelling ||
                  taking ||
                  (cancellingItem && item.key != cancellingItem)
                "
                :loading="item.key == cancellingItem"
                v-if="ownerAccount == item.owner"
                @click="cancelOffer(item.key)"
                class="
                  disabled:ud-opacity-40
                  ud-items-center
                  ud-mb-2
                  ud-w-full
                  ud-justify-center
                  ud-block-inline
                  ud-text-base
                  ud-font-bold
                  ud-text-white
                  ud-bg-red-900
                  ud-py-3
                  ud-px-8
                  hover:ud-shadow-signUp hover:ud-bg-opacity-90
                  ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
                "
              >
                <div v-if="cancellingItem != item.key">Cancel Offer</div>
                <div v-else>
                  <svg
                    class="
                      ud-inline-block ud-animate-spin
                      ud-ml--1
                      ud-mr-1 ud-mb-[2px] ud-h-5 ud-w-5 ud-text-white
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="ud-opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="ud-opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Cancelling
                </div>
              </button>
              <button
                :disabled="
                  !canTake(item) ||
                  cancelling ||
                  taking ||
                  (takingItem && item.key != takingItem) ||
                  ownerAccount == item.owner
                "
                v-if="ownerAccount != item.owner"
                :loading="item.key == takingItem"
                @click="takeOffer(item.key)"
                class="
                  disabled:ud-opacity-40
                  ud-items-center
                  ud-w-full
                  ud-justify-center
                  ud-block-inline
                  ud-text-base
                  ud-font-bold
                  ud-text-white
                  ud-bg-gray-600
                  ud-py-3
                  ud-px-8
                  hover:ud-shadow-signUp hover:ud-bg-opacity-90
                  ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
                "
              >
                <div v-if="takingItem != item.key">Take Offer</div>
                <div v-else>
                  <svg
                    class="
                      ud-inline-block ud-animate-spin
                      ud-ml--1
                      ud-mr-1 ud-mb-[2px] ud-h-5 ud-w-5 ud-text-white
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="ud-opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="ud-opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Taking
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--- OFFERS END -->
  </div>
</template>


<style>
</style>


<script>
import { OpKind } from "@taquito/taquito";
import coinChip from "../components/coinChip.vue";
import TSelect from "./ui/TSelect.vue";

export default {
  name: "orderBook",
  components: {
    coinChip,
    TSelect,
  },
  data: () => ({
    currentPage: 1,
    pageSize: 6,
    asks: [],
    askFilter: undefined,
    offerFilter: undefined,
    showError: false,
    error: undefined,
    success: false,
    successTitle: "",
    taking: false,
    takingItem: undefined,
    cancelling: false,
    cancellingItem: undefined,
    cancelled: false,
    taken: false,
    onlyTradeable: false,
    search: "",
    main: {
      default: undefined,
    },
  }),
  props: {
    value: {
      default: [],
    },
    tokenBalance: {
      default: [],
    },
    title: {
      default: "OrderBook",
    },
    showOwn: {
      default: true,
    },
    onlyOwned: {
      default: false,
    },
    ownerAccount: {
      default: "",
    },
    balance: {
      default: 0,
    },
  },
  computed: {
    indexStart() {
      return (this.currentPage - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      var a = this.items.slice(this.indexStart, this.indexEnd);

      return a;
    },
    items() {
      return this.getItems().filter((k) => {
        return (
          (this.onlyOwned && this.ownerAccount == k.owner) ||
          (!this.onlyOwned && !(this.ownerAccount == k.owner))
        );
      });
    },
    pages() {
      return Math.ceil(this.items.length / Math.ceil(this.pageSize));
    },
  },
  created() {
    this.main = this.$router.app.$children[0];
    this.main.getBalances();   
    this.main.userBalance.forEach((t) => {
      var p = {};
      p["token_id"] = t.token_id;
      p["name"] = t.name;
      this.asks.push(p);
    });
  },
  mounted() {
    this.currentPage = 1;
  },
  updated() {},
  methods: {
    reset() {
      this.currentPage = 1;
      this.clearAskFilter();
      this.clearOfferFilter();
      this.taking = false;
      this.takingItem = undefined;
      this.cancelling = false;
      this.cancellingItem = undefined;
      this.cancelled = false;
      this.taken = false;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (
        this.currentPage <
        this.getItems().filter((k) => {
          return (
            (this.onlyOwned && this.ownerAccount == k.owner) ||
            (!this.onlyOwned && !(this.ownerAccount == k.owner))
          );
        }).length /
          this.pageSize
      )
        this.currentPage++;
    },
    getAskNames() {
      return {
        available: this.asks,
        taken: [],
      };
    },
    updateAskFilter(token_id) {
      this.askFilter = token_id;
      this.currentPage = 1;
    },
    clearAskFilter() {
      this.$refs["askSelectFilter"].clear();
      this.askFilter = undefined;
    },
    updateOfferFilter(token_id) {
      this.offerFilter = token_id;
      this.currentPage = 1;
    },
    clearOfferFilter() {
      this.$refs["offerSelectFilter"].clear();
      this.offerFilter = undefined;
    },
    getItems() {
      //console.log(this.value);
      var r = [];

      this.value.forEach((o) => {
        var i = {};
        i.owner = o.owner;
        i.key = o.key;
        i.token_id = o.token_id;
        i.offerTokens = o.offer_tokens;
        i.offerAmount = o.offer_amount;
        i.askTokens = o.ask_tokens;
        i.ats = "";
        i.askTokens.forEach((e) => {
          i.ats += e.name;
        });
        i.ots = "";
        i.offerTokens.forEach((e) => {
          i.ots += e.name;
        });
        i.askAmount = o.ask_amount;
        if (this.onlyTradeable) {
          if (this.canTake(i)) r.push(i);
        } else {
          r.push(i);
        }
      });

      /// add filter for r
      /// ASK FILTER
      r = r.filter((el) => {
        if (this.askFilter >= 0) {
          if (el.askTokens) {
            var ret = false;
            el.askTokens.forEach((a) => {
              if (a.token_id == this.askFilter) {
                console.log("match for: " + a.token_id);
                ret = true;
              }
            });
            return ret;
          }
        } else return true;
      });

      r = r.filter((el) => {
        if (this.offerFilter >= 0) {
          if (el.offerTokens) {
            var ret = false;
            el.offerTokens.forEach((a) => {
              if (a.token_id == this.offerFilter) {
                console.log("match for: " + a.token_id);
                ret = true;
              }
            });
            return ret;
          }
        } else return true;
      });

      //console.log(r);
      return r;
    },
    canTake(t) {
      if (this.onlyTradeable && t.owner == this.ownerAccount) return false;
      var canTake = true;

      if (t.askAmount > this.balance) {
        console.log("Can't take insufficient funding");
        return false;
      }

      t.askTokens.some((e) => {
        if (!canTake) return;
        canTake = false;
        this.tokenBalance.some((o) => {
          if (e.token_id == o.token_id && o.balance >= e.amount) {
            canTake = true;
            return;
          }
        });
      });

      return canTake;
    },
    getRarityColor(rarity) {
      if (rarity == "F") return "#264653";
      else if (rarity == "E") return "#8E6C15";
      else if (rarity == "D") return "#2a9d8f";
      else if (rarity == "C") return "#47360B";
      else if (rarity == "B") return "#f4a261";
      else if (rarity == "A") return "#e76f51";
      else return "#000000";
    },
    async takeOffer(o) {
      console.log("Received take order " + o);
      this.clearSuccessError();
      this.taking = true;
      this.takingItem = o;

      this.main.tezos.setWalletProvider(this.main.wallet);

      const contract = await this.main.tezos.wallet.at(
        this.main.CONTRACT_ADDRESS
      );

      const market = await this.main.tezos.wallet.at(this.main.MARKET_ADDRESS);

      /// Check if we need to add operator

      var operators = [];
      var sendAmount = 0;

      this.main.offers.forEach((element) => {
        if (element.key == o) {
          if (element.ask_tokens.length > 0) {
            element["ask_tokens"].forEach((t) => {
              var v = {};
              v["add_operator"] = {};
              v.add_operator["owner"] = this.main.activeAccount;
              v.add_operator["operator"] = this.main.MARKET_ADDRESS;
              v.add_operator["token_id"] = t.token_id;
              operators.push(v);
            });
          }
          sendAmount = element.ask_amount;
        }
      });

      var operations = [];

      if (operators.length > 0) {
        operations.push({
          kind: OpKind.TRANSACTION,
          ...contract.methods.update_operators(operators).toTransferParams(),
        });
      }

      operations.push({
        kind: OpKind.TRANSACTION,
        ...market.methods.take(o).toTransferParams(),
        amount: sendAmount,
      });

      const batch = await this.main.tezos.wallet.batch(operations);

      batch
        .send()
        .then(async (op) => {
          console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(2);
          return op.opHash;
        })
        .then(async (hash) => {
          console.log("OK: " + hash);
          this.updateData();
          this.showError = false;
          this.error = undefined;
          this.success = true;
          this.successTitle = "Offer taken.";
          this.takingItem = undefined;
          this.$emit("success");
        })
        .catch((error) => {
          this.updateData();
          this.showError = true;
          this.error = error;
          this.success = false;
          console.log(error);
          console.log("Error: " + JSON.stringify(error, null, 2));
          this.takingItem = undefined;
        });
    },
    updateData() {
      this.main.getOrders();
      this.main.getBalances();
      this.main.setBalance();
      this.cancelling = false;
      this.cancellingItem = undefined;
      this.taking = false;
      this.takingItem = undefined;
    },
    clearSuccessError() {
      this.error = undefined;
      this.showError = false;
      this.success = false;
    },
    async cancelOffer(o) {
      this.clearSuccessError();
      console.log("Received cancel order " + o);
      this.cancelling = true;
      this.cancellingItem = o;

      this.main.tezos.setWalletProvider(this.main.wallet);
      this.main.tezos.wallet
        .at(this.main.MARKET_ADDRESS)
        .then((contract) => {
          return contract.methods.cancel(o).send();
        })
        .then(async (op) => {
          console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(2);
          return op.opHash;
        })
        .then(async (hash) => {
          console.log("OK: " + hash);
          this.updateData();
          this.showError = false;
          this.error = undefined;
          this.success = true;
          this.successTitle = "Offer cancelled.";
          this.cancellingItem = undefined;
          this.$emit("success");
        })
        .catch((error) => {
          this.updateData();
          this.showError = true;
          this.error = error;
          this.success = false;
          console.log(error);
          console.log("Error: " + JSON.stringify(error, null, 2));
          this.cancellingItem = undefined;
        });
    },
  },
};
</script>