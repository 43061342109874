<template>
  <!-- ====== Footer Section Start -->
  <footer
    class="
      ud-relative ud-z-10 ud-bg-primary ud-bg-opacity-5 ud-pt-[100px]
      wow
      fadeInUp
    "
    data-wow-delay=".1s"
  >
    <div class="ud-container">
      <div class="ud-flex ud-flex-wrap ud-mx-[-16px]">
        <div class="ud-w-full md:ud-w-1/2 lg:ud-w-6/12 xl:ud-w-6/12 ud-px-4">
          <div class="ud-mb-16 ud-max-w-[420px]">
            <a href="index.html" class="ud-inline-block ud-mb-8">
              <div
                style="font-family: 'RomanSD'"
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                "
              >
                TAEZARS
              </div>
            </a>
            <p
              class="
                ud-text-body-color
                ud-font-medium
                ud-ud-leading-relaxed
                ud-mb-4
                ud-text-xs
              "
            >
              Dedicated by the emperor Caesar, son of the divine Marcus
              Antoninus Pius, brother of the divine Commodus, grandson of the
              divine Antoninus Pius, great-grandson of the divine Hadrian,
              great-great-grandson of the divine Trajan, conqueror of Parthia,
              great-great-great-grandson of the divine Nerva, Lucius Septimius
              Severus Pius Pertinax Augustus Arabicus Adiabenicus, father of his
              country, Pontifex Maximus, holding the tribunician power for the
              fourth year, in the eighth year of his imperium, consul for the
              second time; and Marcus Aurelius Antoninus Caesar.
            </p>
            <div class="ud-flex ud-items-center">
              <a
                href="https://twitter.com/taezars"
                target="_blank"
                aria-label="social-link"
                class="ud-text-[#CED3F6] hover:ud-text-primary ud-mr-6"
              >
                <svg
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  viewBox="0 0 248 204"
                  class="ud-fill-current"
                >
                  <path
                    d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04
		C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66
		c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64
		c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76
		c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26
		c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z"
                  />
                </svg>
              </a>
              <a
                href="https://discord.gg/Kjm3cYJZsA"
                target="_blank"
                aria-label="social-link"
                class="ud-text-[#CED3F6] hover:ud-text-primary ud-mr-6"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 71 55"
                  class="ud-fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z"
                  />
                </svg>
              </a>
              <a
                href="https://dappradar.com/tezos/collectibles/taezars"
                target="_blank"
                aria-label="social-link"
                class="ud-text-[#CED3F6] hover:ud-text-primary ud-mr-6"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 250 250"
                  class="ud-fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M250 130C250 172.709 227.009 212.527 190.014 233.936L180.577 239.405L169.655 220.518L169.668 220.505L120.55 135.455C117.536 130.232 119.323 123.564 124.545 120.55C129.768 117.536 136.436 119.323 139.45 124.545L163.355 165.945C173.009 156.973 179.091 144.195 179.091 130C179.091 102.932 157.068 80.9091 130 80.9091C102.932 80.9091 80.9091 102.932 80.9091 130C80.9091 153.632 97.6818 173.405 119.95 178.055L133.095 200.827C132.073 200.882 131.036 200.909 130 200.909C90.9046 200.909 59.0909 169.095 59.0909 130C59.0909 90.9045 90.9046 59.0909 130 59.0909C169.095 59.0909 200.909 90.9045 200.909 130C200.909 152.282 190.573 172.164 174.455 185.173L188.227 209.036C213.1 190.655 228.182 161.268 228.182 130C228.182 75.8636 184.136 31.8182 130 31.8182C75.8636 31.8182 31.8182 75.8636 31.8182 130C31.8182 166.273 51.6045 197.991 80.9364 214.982L70.0136 233.895C34.1636 213.127 10 174.332 10 130C10 63.8364 63.8364 10 130 10C196.164 10 250 63.8364 250 130Z"
                     
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <path d="M10 10H250V250H10V10Z"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a
                href="https://objkt.com/collection/taezars"
                target="_blank"
                aria-label="social-link"
                class="ud-text-[#CED3F6] ud-mr-2"
              >
                <img class="ud-w-[85px]" :src="objktImg" @mouseover="objktImg = objktImgHover" @mouseleave="objktImg = objktImgOrig"/> 
              </a>
              <a
                href="https://www.dapp.com/app/taezars"
                target="_blank"
                aria-label="social-link"
                class="ud-text-[#CED3F6] hover:ud-text-primary ud-mr-2"
              >
                <img class="ud-w-[100px]" src="../assets/dapp.png" />
              </a>
            
              <a href="https://www.producthunt.com/posts/taezars?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-taezars" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=364665&theme=dark" alt="Taezars - Digital&#0032;Collectable&#0032;Game&#0032;Featuring&#0032;more&#0032;than&#0032;260&#0032;Roman&#0032;Coins | Product Hunt" style="width: 125px; height: 27px;" width="125" height="27" /></a>   
            </div>
           
            <div>
              
            </div>
          </div>
        </div>
        <div
          class="
            ud-w-full
            sm:ud-w-1/2
            md:ud-w-1/2
            lg:ud-w-3/12
            xl:ud-w-3/12
            ud-px-4
          "
        >
          <div class="ud-mb-16">
            <h2
              class="
                ud-font-bold ud-text-black
                dark:ud-text-white
                ud-text-xl ud-mb-10
              "
            >
              Useful Links
            </h2>
            <ul>
              <li>
                <a
                  href="https://templewallet.com/"
                  target="_blank"
                  class="
                    ud-text-base
                    ud-font-medium
                    ud-inline-block
                    ud-text-body-color
                    ud-mb-4
                    hover:ud-text-primary
                  "
                >
                  Temple Wallet
                </a>
              </li>
              <li>
                <a
                  href="https://www.kraken.com/prices/xtz-tezos-price-chart/"
                  target="_blank"
                  class="
                    ud-text-base
                    ud-font-medium
                    ud-inline-block
                    ud-text-body-color
                    ud-mb-4
                    hover:ud-text-primary
                  "
                >
                  Kraken
                </a>
              </li>
              <li>
                <a
                  href="https://www.coinbase.com/price/tezos"
                  target="_blank"
                  class="
                    ud-text-base
                    ud-font-medium
                    ud-inline-block
                    ud-text-body-color
                    ud-mb-4
                    hover:ud-text-primary
                  "
                >
                  Coinbase
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="
            ud-w-full
            sm:ud-w-1/2
            md:ud-w-1/2
            lg:ud-w-3/12
            xl:ud-w-3/12
            ud-px-4
          "
        >
          <div class="ud-mb-16">
            <h2
              class="
                ud-font-bold ud-text-black
                dark:ud-text-white
                ud-text-xl ud-mb-10
              "
            >
              Terms
            </h2>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  class="
                    ud-text-base
                    ud-font-medium
                    ud-inline-block
                    ud-text-body-color
                    ud-mb-4
                    hover:ud-text-primary
                  "
                >
                  Disclaimer
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  class="
                    ud-text-base
                    ud-font-medium
                    ud-inline-block
                    ud-text-body-color
                    ud-mb-4
                    hover:ud-text-primary
                  "
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  class="
                    ud-text-base
                    ud-font-medium
                    ud-inline-block
                    ud-text-body-color
                    ud-mb-4
                    hover:ud-text-primary
                  "
                >
                  Terms of Use
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="ud-py-8 ud-bg-primary ud-bg-opacity-10">
      <div class="ud-container">
        <p
          class="
            ud-text-body-color
            dark:ud-text-white
            ud-text-base ud-text-center
          "
        >
          <span style="font-family: 'RomanSD'">TAEZARS</span> &copy; MMXXII All
          Rights Reserved.
        </p>
      </div>
    </div>
    <div class="ud-absolute ud-right-0 ud-top-14 ud-z-[-1]">
      <svg
        width="55"
        height="99"
        viewBox="0 0 55 99"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.8" cx="49.5" cy="49.5" r="49.5" fill="#959CB1" />
        <mask
          id="mask0_94:899"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="99"
        >
          <circle opacity="0.8" cx="49.5" cy="49.5" r="49.5" fill="#4A6CF7" />
        </mask>
        <g mask="url(#mask0_94:899)">
          <circle
            opacity="0.8"
            cx="49.5"
            cy="49.5"
            r="49.5"
            fill="url(#paint0_radial_94:899)"
          />
          <g opacity="0.8" filter="url(#filter0_f_94:899)">
            <circle cx="53.8676" cy="26.2061" r="20.3824" fill="white" />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f_94:899"
            x="12.4852"
            y="-15.1763"
            width="82.7646"
            height="82.7646"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="10.5"
              result="effect1_foregroundBlur_94:899"
            />
          </filter>
          <radialGradient
            id="paint0_radial_94:899"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(49.5 49.5) rotate(90) scale(53.1397)"
          >
            <stop stop-opacity="0.47" />
            <stop offset="1" stop-opacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </div>
    <div class="ud-absolute ud-left-0 ud-bottom-24 ud-z-[-1]">
      <svg
        width="79"
        height="94"
        viewBox="0 0 79 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.3"
          x="-41"
          y="26.9426"
          width="66.6675"
          height="66.6675"
          transform="rotate(-22.9007 -41 26.9426)"
          fill="url(#paint0_linear_94:889)"
        />
        <rect
          x="-41"
          y="26.9426"
          width="66.6675"
          height="66.6675"
          transform="rotate(-22.9007 -41 26.9426)"
          stroke="url(#paint1_linear_94:889)"
          stroke-width="0.7"
        />
        <path
          opacity="0.3"
          d="M50.5215 7.42229L20.325 1.14771L46.2077 62.3249L77.1885 68.2073L50.5215 7.42229Z"
          fill="url(#paint2_linear_94:889)"
        />
        <path
          d="M50.5215 7.42229L20.325 1.14771L46.2077 62.3249L76.7963 68.2073L50.5215 7.42229Z"
          stroke="url(#paint3_linear_94:889)"
          stroke-width="0.7"
        />
        <path
          opacity="0.3"
          d="M17.9721 93.3057L-14.9695 88.2076L46.2077 62.325L77.1885 68.2074L17.9721 93.3057Z"
          fill="url(#paint4_linear_94:889)"
        />
        <path
          d="M17.972 93.3057L-14.1852 88.2076L46.2077 62.325L77.1884 68.2074L17.972 93.3057Z"
          stroke="url(#paint5_linear_94:889)"
          stroke-width="0.7"
        />
        <defs>
          <linearGradient
            id="paint0_linear_94:889"
            x1="-41"
            y1="21.8445"
            x2="36.9671"
            y2="59.8878"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4A6CF7" stop-opacity="0.62" />
            <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_94:889"
            x1="25.6675"
            y1="95.9631"
            x2="-42.9608"
            y2="20.668"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4A6CF7" stop-opacity="0" />
            <stop offset="1" stop-color="#4A6CF7" stop-opacity="0.51" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_94:889"
            x1="20.325"
            y1="-3.98039"
            x2="90.6248"
            y2="25.1062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4A6CF7" stop-opacity="0.62" />
            <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_94:889"
            x1="18.3642"
            y1="-1.59742"
            x2="113.9"
            y2="80.6826"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4A6CF7" stop-opacity="0" />
            <stop offset="1" stop-color="#4A6CF7" stop-opacity="0.51" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_94:889"
            x1="61.1098"
            y1="62.3249"
            x2="-8.82468"
            y2="58.2156"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4A6CF7" stop-opacity="0.62" />
            <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_94:889"
            x1="65.4236"
            y1="65.0701"
            x2="24.0178"
            y2="41.6598"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4A6CF7" stop-opacity="0" />
            <stop offset="1" stop-color="#4A6CF7" stop-opacity="0.51" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </footer>
  <!-- ====== Footer Section End -->
</template>

<script>
export default {
  name: "TFooter",
  data: () => ({
    objktImg: "/images/objkt.png",
    objktImgHover: "/images/objkt_hover.png",
    objktImgOrig: "/images/objkt.png"
  }),
};
</script>