
<template>
  <div class="ud-flex ud-justify-end ud-items-center ud-pr-1 lg:ud-pr-0">
    <a
      v-on:click="$emit('click')"
      href="#"
      class="
        ud-hidden ud-items-center
        lg:ud-flex
        ud-text-base ud-font-bold ud-text-white ud-bg-primary ud-py-3 ud-px-8
        md:ud-px-9
        lg:ud-px-6
        xl:ud-px-9
        hover:ud-shadow-signUp hover:ud-bg-opacity-90
        ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
      "
    >
      <span class="mdi mdi-wallet-outline ud-text-xl ud-mr-1"></span>{{text}}
    </a>
  </div>
</template>
<script>
export default {
  name: "TButton",
  props: {
    text: {
      default: "BUTTON",
    }
  },
  mounted: function() {   
  }
};
</script>
          