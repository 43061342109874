<template>
  <div>
    <a
      v-if="!router"
      :href="href"         
      class="        
        menu-scroll
        ud-text-base
        text-dark
        dark:ud-text-white
        group-hover:ud-opacity-70
        ud-py-2
        lg:ud-py-6 lg:ud-inline-flex lg:ud-px-0
        ud-flex ud-mx-8
        lg:ud-mr-0 lg:ud-ml-8
        xl:ud-ml-12
      "
    >
      {{ name }}
    </a>
    <router-link
      v-if="router"
      :force="true"
      :to="href"        
      class="            
        menu-scroll
        ud-text-base
        text-dark
        dark:ud-text-white
        group-hover:ud-opacity-70
        ud-py-2
        lg:ud-py-6 lg:ud-inline-flex lg:ud-px-0
        ud-flex ud-mx-8
        lg:ud-mr-0 lg:ud-ml-8
        xl:ud-ml-12
      "
    >
      {{ name }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "headerLink",
  props: {
    name: {
      default: "LINK",
    },
    href: {
      default: "#",
    },
    router: {
      default: false,
    },
  },
  methods: { 
   
  },
  mounted: function() { 
  
  }
};
</script>