<template>
  <header
    class="
      header
      ud-bg-transparent
      ud-absolute
      ud-top-0
      ud-left-0
      ud-z-40
      ud-w-full
      ud-flex
      ud-items-center
    "
  >
    <div class="ud-container">
      <div
        class="
          ud-flex
          ud-mx-[-16px]
          ud-items-center
          ud-justify-between
          ud-relative
          ud-max-h-[90px]
        "
      >
        <div class="ud-px-2 ud-w-60 ud-max-w-full">
          <a href="/" class="ud-w-full ud-block ud-py-8 header-logo">
            <div
              style="font-family: 'RomanSD'"
              class="
                ud-text-black
                dark:ud-text-white
                ud-mb-0
                ud-justify-center
                ud-text-center
                ud-items-center
                ud-font-bold
                ud-text-4xl
                ud-flex
                ud-flex-col
              "
            >
              <div class="ud-text-yellow ud-mt-2 ud-pt-0">TAEZARS</div>
              <div
                class="
                  ud-text-xs
                  ud-p-0
                  ud-flex
                  ud-text-[8px]
                  ud-mt-[-6px]
                  ud-text-center
                  ud-justify-center
                "
              >
                <div class="ud-text-gray-400 ud-m-0 ud-p-0">
                  VOLUME I: The Twelve Caesars
                </div>
              </div>
            </div>
          </a>
        </div>
        <div
          class="ud-flex ud-px-4 ud-justify-between ud-items-center ud-w-full"
        >
          <div>
            <button
              id="navbarToggler"
              aria-label="Mobile Menu"
              class="
                ud-block ud-absolute ud-right-4 ud-top-1/2 ud-translate-y-[-50%]
                lg:ud-hidden
                focus:ud-ring-2
                ud-ring-primary ud-px-3 ud-py-[6px] ud-rounded-lg
              "
            >
              <span
                class="
                  ud-relative
                  ud-w-[30px]
                  ud-h-[2px]
                  ud-my-[6px]
                  ud-block
                  ud-bg-dark
                  dark:ud-bg-white
                "
              ></span>
              <span
                class="
                  ud-relative
                  ud-w-[30px]
                  ud-h-[2px]
                  ud-my-[6px]
                  ud-block
                  ud-bg-dark
                  dark:ud-bg-white
                "
              ></span>
              <span
                class="
                  ud-relative
                  ud-w-[30px]
                  ud-h-[2px]
                  ud-my-[6px]
                  ud-block
                  ud-bg-dark
                  dark:ud-bg-white
                "
              ></span>
            </button>
            <nav
              id="navbarCollapse"
              class="
                ud-absolute ud-py-5
                lg:ud-py-0 lg:ud-px-4
                xl:ud-px-6
                ud-bg-white
                dark:ud-bg-dark
                lg:dark:ud-bg-transparent lg:ud-bg-transparent
                ud-shadow-lg ud-rounded-lg ud-max-w-[250px] ud-w-full
                lg:ud-max-w-full lg:ud-w-full
                ud-right-4 ud-top-full ud-hidden
                lg:ud-block lg:ud-static lg:ud-shadow-none
              "
            >
              <ul class="ud-blcok lg:ud-flex">
                <li class="ud-relative ud-group">
                  <headerLink v-if="!connected" name="Home" href="#home" />
                </li>
                <li class="ud-relative ud-group">
                  <headerLink
                    v-if="!connected"
                    name="Features"
                    href="#features"
                  />
                </li>
                <li class="ud-relative ud-group">
                  <headerLink
                    v-if="!connected"
                    name="Screenshots"
                    href="#screenshots"
                  />
                </li>
                <li class="ud-relative ud-group">
                  <headerLink
                    v-if="!connected"
                    name="How to Play"
                    href="#howtoplay"
                  />
                </li>
                <li class="ud-relative ud-group">
                  <headerLink
                    v-if="!connected"
                    name="PFPs"
                    href="#pfps"
                  />
                </li>
                <li
                  class="md:ud-hidden ud-relative ud-group"
                  v-on:click="$emit('connect')"
                >
                  <headerLink
                    v-if="!connected"
                    name="Connect Wallet"
                    href="#"
                  />
                </li>
                <li class="ud-relative ud-group">
                  <headerLink
                    router="true"
                    v-if="connected"
                    name="Collection"
                    href="/collection"
                  />
                </li>
                <li class="ud-relative ud-group">
                  <headerLink
                    router="true"
                    v-if="connected"
                    name="Mint"
                    href="/coinminter"
                  />
                </li>
                <li
                  v-show="connected"
                  class="ud-relative ud-group submenu-item"
                >
                  <a
                    href="javascript:void(0)"
                    class="
                      ud-text-base
                      text-dark
                      dark:ud-text-white
                      group-hover:ud-opacity-70
                      ud-py-2
                      lg:ud-py-6 lg:ud-inline-flex lg:ud-pl-0 lg:ud-pr-4
                      ud-flex ud-mx-8
                      lg:ud-mr-0 lg:ud-ml-8
                      xl:ud-ml-12
                      ud-relative
                      after:ud-absolute
                      after:ud-w-2
                      after:ud-h-2
                      after:ud-border-b-2
                      after:ud-border-r-2
                      after:ud-border-current
                      after:ud-rotate-45
                      lg:after:ud-right-0
                      after:ud-right-1
                      after:ud-top-1/2
                      after:ud-translate-y-[-50%]
                      after:ud-mt-[-2px]
                    "
                  >
                    Market
                  </a>
                  <div
                    class="
                      submenu
                      ud-hidden ud-relative
                      lg:ud-absolute
                      ud-w-[250px] ud-top-full
                      lg:ud-top-[110%]
                      ud-left-0 ud-rounded-md
                      lg:ud-shadow-lg
                      ud-p-4
                      lg:ud-block lg:ud-opacity-0 lg:ud-invisible
                      group-hover:ud-opacity-100
                      lg:group-hover:ud-visible lg:group-hover:ud-top-full
                      ud-bg-white
                      dark:ud-bg-dark
                      ud-transition-[top] ud-duration-300 ud-overscroll-contain
                    "
                  >
                    <router-link
                      to="/market/0"
                      class="
                        headerLink
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Create Offer
                    </router-link>
                    <router-link
                      to="/market/1"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Your Offers
                    </router-link>
                    <router-link
                      to="/market/2"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Browse Market
                    </router-link>
                  </div>
                </li>
                <li
                  v-show="connected"
                  class="ud-relative ud-group submenu-item"
                >
                  <a
                    href="javascript:void(0)"
                    class="
                      ud-text-base
                      text-dark
                      dark:ud-text-white
                      group-hover:ud-opacity-70
                      ud-py-2
                      lg:ud-py-6 lg:ud-inline-flex lg:ud-pl-0 lg:ud-pr-4
                      ud-flex ud-mx-8
                      lg:ud-mr-0 lg:ud-ml-8
                      xl:ud-ml-12
                      ud-relative
                      after:ud-absolute
                      after:ud-w-2
                      after:ud-h-2
                      after:ud-border-b-2
                      after:ud-border-r-2
                      after:ud-border-current
                      after:ud-rotate-45
                      lg:after:ud-right-0
                      after:ud-right-1
                      after:ud-top-1/2
                      after:ud-translate-y-[-50%]
                      after:ud-mt-[-2px]
                    "
                  >
                    Cursus Honorum
                  </a>
                  <div
                    class="
                      submenu
                      ud-hidden ud-relative
                      lg:ud-absolute
                      ud-w-[250px] ud-top-full
                      lg:ud-top-[110%]
                      ud-left-0 ud-rounded-md
                      lg:ud-shadow-lg
                      ud-p-4
                      lg:ud-block lg:ud-opacity-0 lg:ud-invisible
                      group-hover:ud-opacity-100
                      lg:group-hover:ud-visible lg:group-hover:ud-top-full
                      ud-bg-white
                      dark:ud-bg-dark
                      ud-transition-[top] ud-duration-300 ud-overscroll-contain
                    "
                  >
                    <router-link
                      to="/cursus-honorum/0"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Julius Caesar
                    </router-link>
                    <router-link
                      to="/cursus-honorum/1"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Augustus
                    </router-link>
                    <router-link
                      to="/cursus-honorum/2"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Tiberius
                    </router-link>
                    <router-link
                      to="/cursus-honorum/3"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Caligula
                    </router-link>
                    <router-link
                      to="/cursus-honorum/4"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Claudius
                    </router-link>
                    <router-link
                      to="/cursus-honorum/5"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Nero
                    </router-link>
                    <router-link
                      to="/cursus-honorum/6"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Galba
                    </router-link>
                    <router-link
                      to="/cursus-honorum/7"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Otho
                    </router-link>
                    <router-link
                      to="/cursus-honorum/8"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Vitellius
                    </router-link>
                    <router-link
                      to="/cursus-honorum/9"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Vespasian
                    </router-link>
                    <router-link
                      to="/cursus-honorum/10"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Titus
                    </router-link>
                    <router-link
                      to="/cursus-honorum/11"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Domitian
                    </router-link>
                  </div>
                </li>
                <li class="ud-relative ud-group">
                  <headerLink
                    router="true"
                    v-if="connected"
                    name="Aureus"
                    href="/aureus"
                  />
                </li>
                <li
                  v-show="connected"
                  class="ud-relative ud-group submenu-item"
                >
                  <a
                    href="javascript:void(0)"
                    class="
                      ud-text-base
                      text-dark
                      dark:ud-text-white
                      group-hover:ud-opacity-70
                      ud-py-2
                      lg:ud-py-6 lg:ud-inline-flex lg:ud-pl-0 lg:ud-pr-4
                      ud-flex ud-mx-8
                      lg:ud-mr-0 lg:ud-ml-8
                      xl:ud-ml-12
                      ud-relative
                      after:ud-absolute
                      after:ud-w-2
                      after:ud-h-2
                      after:ud-border-b-2
                      after:ud-border-r-2
                      after:ud-border-current
                      after:ud-rotate-45
                      lg:after:ud-right-0
                      after:ud-right-1
                      after:ud-top-1/2
                      after:ud-translate-y-[-50%]
                      after:ud-mt-[-2px]
                    "
                  >
                    Games
                  </a>
                  <div
                    class="
                      submenu
                      ud-hidden ud-relative
                      lg:ud-absolute
                      ud-w-[250px] ud-top-full
                      lg:ud-top-[110%]
                      ud-left-0 ud-rounded-md
                      lg:ud-shadow-lg
                      ud-p-4
                      lg:ud-block lg:ud-opacity-0 lg:ud-invisible
                      group-hover:ud-opacity-100
                      lg:group-hover:ud-visible lg:group-hover:ud-top-full
                      ud-bg-white
                      dark:ud-bg-dark
                      ud-transition-[top] ud-duration-300 ud-overscroll-contain
                    "
                  >
                    <router-link
                      to="/games/tesserae"
                      class="
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                    Tesserae
                    </router-link>                
                  </div>
                </li>
                <li
                  v-show="connected"
                  class="ud-relative ud-group submenu-item"
                >
                  <a
                    href="javascript:void(0)"
                    class="
                      ud-text-base
                      text-dark
                      dark:ud-text-white
                      group-hover:ud-opacity-70
                      ud-py-2
                      lg:ud-py-6 lg:ud-inline-flex lg:ud-pl-0 lg:ud-pr-4
                      ud-flex ud-mx-8
                      lg:ud-mr-0 lg:ud-ml-8
                      xl:ud-ml-12
                      ud-relative
                      after:ud-absolute
                      after:ud-w-2
                      after:ud-h-2
                      after:ud-border-b-2
                      after:ud-border-r-2
                      after:ud-border-current
                      after:ud-rotate-45
                      lg:after:ud-right-0
                      after:ud-right-1
                      after:ud-top-1/2
                      after:ud-translate-y-[-50%]
                      after:ud-mt-[-2px]
                    "
                  >
                    {{ address }}
                  </a>
                  
                
                  <div
                    class="
                      submenu
                      ud-hidden ud-relative
                      lg:ud-absolute
                      ud-w-[250px] ud-top-full
                      lg:ud-top-[110%]
                      ud-left-0 ud-rounded-md
                      lg:ud-shadow-lg
                      ud-p-4
                      lg:ud-block lg:ud-opacity-0 lg:ud-invisible
                      group-hover:ud-opacity-100
                      lg:group-hover:ud-visible lg:group-hover:ud-top-full
                      ud-bg-white
                      dark:ud-bg-dark
                      ud-transition-[top] ud-duration-300
                    "
                  >
                  <a
                      href="#"
                      v-on:click="$emit('topup')"
                      class="
                      submenu
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Top-Up Account
                    </a>
                    <a
                      href="#"
                      v-on:click="$emit('disconnect')"
                      class="
                      submenu
                        ud-block ud-text-sm ud-rounded ud-py-[10px] ud-px-4
                        text-dark
                        dark:ud-text-white
                        hover:ud-opacity-70
                      "
                    >
                      Disconnect
                    </a>
                  
                  </div>
                </li>
                <li v-show="connected" class="ud-relative">
                  <a href="#">
                  <div
                    class="
                      ud-py-2
                      lg:ud-py-6 lg:ud-px-0
                      ud-mx-0
                      lg:ud-mr-0 lg:ud-ml-6
                      xl:ud-ml-6
                      ud-justify-center ud-items-center
                      ud-flex 
                    "
                  >
                    <div class="ud-text-yellow">
                      {{ getNumber(main.walletBalance / 1e6) }}
                    </div>
                    <div class="ud-text-yellow">
                      <img
                        class="ud-ml-1 ud-h-4 !ud-max-w-none"                     
                        src="../assets/tezos-logo.svg"
                      />
                    </div>
                  </div>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <TButton v-if="!connected" @click="$emit('connect')" text="Connect" />
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped>
@font-face {
  font-family: "RomanSD";
  src: url("../assets/RomanSD.ttf") format("truetype");
}
</style>
<script>
import headerLink from "./header/headerLink.vue";
import TButton from "./TButton.vue";
import numeral from "numeral";

export default {
  name: "THeader",
  components: {
    headerLink,
    TButton,
  },
  props: {
    connected: {
      default: false,
    },
    address: {
      default: "none",
    },
  },
  methods: {
    getNumber(n) {
      return numeral(n).format("0,0.0");
    },
  },
  data: () => ({
    main: undefined   
  }),
  created: function () {
    this.main = this.$router.app.$children[0];
  },
  mounted: async function () {
   
    // ===== responsive navbar
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");

    navbarToggler.addEventListener("click", () => {
      navbarToggler.classList.toggle("navbarTogglerActive");
      navbarCollapse.classList.toggle("ud-hidden");
    });

    //===== close navbar-collapse when a  clicked
    document
      .querySelectorAll("#navbarCollapse ul li:not(.submenu-item) a")
      .forEach((e) =>
        e.addEventListener("click", () => {
          navbarToggler.classList.remove("navbarTogglerActive");
          navbarCollapse.classList.add("ud-hidden");
        })
      );

    const submenuItems = document.querySelectorAll(".submenu-item");
    
    submenuItems.forEach((el) => {
      el.addEventListener("click", () => {
        el.querySelector(".submenu").classList.toggle("ud-hidden");
      });
    });
  },
};
</script>