<template>
  <div class="backImg">
    <section
      id="home"
      class="
        ud-relative ud-z-10 ud-pt-[100px] ud-pb-[110px]
        md:ud-pt-[100px] md:ud-pb-[120px]
        xl:ud-pt-[100px] xl:ud-pb-[160px]
        2xl:ud-pt-[100px] 2xl:ud-pb-[200px]
        ud-min-h-full
      "
    >
      <div class="ud-container">     
        <TTrade v-if="$route.params.id == 0" :balances="main.userBalance" @sucess="updateData()"/>
        <orderBook
          ref="ob1"
          v-if="$route.params.id == 1"
          style="margin-top: 10px"
          :value="this.main.getOrderBook()"
          title="Taezars Market"
          :balance="this.main.walletBalance"
          :ownerAccount="this.main.activeAccount"
          :onlyOwned="true"
          :showOwn="true"
          :main="this.$router.app.$children[0]"
          :tokenBalance="this.main.userBalance"
          @sucess="updateData()"
        />
        <orderBook
          ref="ob2"
          v-if="$route.params.id == 2"
          style="margin-top: 10px"
          :value="this.main.getOrderBook()"
          title="Taezars Market"
          :balance="this.main.walletBalance"
          :ownerAccount="this.main.activeAccount"
          :onlyOwned="false"
          :showOwn="false"
          :main="this.$router.app.$children[0]"
          :tokenBalance="this.main.userBalance"
          @sucess="updateData()"
        />
      </div>
    </section>
  </div>
</template>

<style scoped>
</style>
<script>
import TTrade from "../components/TTrade.vue";
import orderBook from "../components/orderBook.vue";

export default {
  name: "TMarket",
  components: {
    orderBook: orderBook,
    TTrade
  },
  data: () => ({
    main: undefined,
  }),
  computed: {},
  created: async function () {
      window.scrollTo(0,0);
    this.main = this.$router.app.$children[0];
    await this.main.getBalances();
    this.$forceUpdate();
  },
  beforeRouteUpdate(to, from, next) {    
    if (this.$refs['ob1']) this.$refs['ob1'].reset();
    if (this.$refs['ob2']) this.$refs['ob2'].reset();    
    next();
    window.scrollTo(0, 0);   
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");
  },
  mounted: async function () {
    //console.log(this.main.tokenGroups);
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");
  },
  methods: {
    async updateData() {
      await this.main.getBalances();
      this.main.setBalance();       
      this.$forceUpdate();
    },
    getRarityColor(rarity) {
      return this.main.getRarityColor(rarity);
    },
  },
};
</script>