import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import VueLazyLoadVideo from 'vue-lazyload-video'

// Register Components
// LazyVideo & LazyVideoAsGIF
Vue.use(VueLazyLoadVideo)

//const loadimage = require('./assets/loading.gif')
//const loadimage = require('./assets/loading-4.webp')
const loadimage = require('./assets/loading6.gif')

Vue.use(VueLazyload, {
  preLoad: 1,
  loading: loadimage,
  attempt: 1,
  throttleWait: 150
})

Vue.use(VueLazyload);

import './index.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

new Vue({
  router,  
  render: h => h(App),
}).$mount('#app')
