<template>
  <div>
    <section
      id="home"
      class="
        ud-relative ud-z-10 ud-pt-[100px] ud-pb-[20px]
        md:ud-pt-[100px] md:ud-pb-[30px]
        xl:ud-pt-[100px] xl:ud-pb-[40px]
        2xl:ud-pt-[100px] 2xl:ud-pb-[50px]
        ud-min-h-full ud-w-full
        backImg
      "
    >
      <div class="ud-container">
        <div class="ud-flex ud-flex-wrap">
          <div class="ud-w-full ud-px-4">
            <div
              class="ud-mx-auto ud-text-center ud-mb-10 ud-mt-4 wow fadeInUp ud-max-w-[900px]"
              data-wow-delay=".1s"
            >
             <h2
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-6
                  ud-text-center
                "
                style="font-family: 'RomanSD';"
              >
              Tesserae
              </h2>
              <p
                class="
                  ud-text-sm ud-text-body-color ud-leading-relaxed
                  md:ud-leading-relaxed
                "
              >
              Dice (tesserae) was probably the most popular game among Roman games for adults. Among the Greeks it was mainly a pastime of the upper classes, but in Rome its extension was much greater, reaching gambling houses, professional players and it is even known that many emperors who loved gambling and betting were also fond of gambling with roman dice.
             
              </p>
              <p
                class="
                  ud-text-sm ud-text-body-color ud-leading-relaxed
                  md:ud-leading-relaxed
                  ud-mt-6
                  ud-mb-6
                "
              >
              The rules for playing Taezars tesserae are simple: If the sum of the dice you roll is <span class="ud-text-white">12</span> you receive <span class="ud-text-yellow">5 times</span> the amount played. If you roll the <span class="ud-text-white">same face</span> on all three dices you receive <span class="ud-text-yellow">10 times</span> the amount played.             
              </p>
              <div class="ud-flex ud-flex-wrap ud-w-full ud-mt-4 ud-gap-4 ud-justify-center">
                <div class="ud-flex ud-flex-col">
                <div
              class="
                ud-p-2
                ud-pl-0
                ud-rounded-t-xl
                ud-border
                ud-border-gray-800
                ud-border-b-0
                noselect
                ud-text-center
                ud-text-white
              "
              style="
                /* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "
            >
              Tesserae Table
            </div>       
                <div class="ud-border ud-border-gray-800 ud-p-2"
                style="background-color: #1C2042;"
                >
                  <diceRoller class="noselect" @roll_success="rollSuccess()" @roll_start="rollStart()"></diceRoller>
                </div>
              </div>
                <div class="ud-text-body-color ud-w-[400px]">  
                  <div
              class="
                ud-p-2
                ud-pl-0
                ud-rounded-t-xl
                ud-border
                ud-border-gray-800
                ud-border-b-0
                noselect
                ud-text-center
                ud-text-white
              "
              style="/* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "
            >
              Recent Rolls
            </div>       
            <div v-if="rolls" class="ud-flex-col ud-border ud-justify-center ud-border-gray-800 recentRollsBack ud-overflow-auto ud-h-[426px]">
                  <div class="ud-flex ud-text-center ud-justify-center ud-text-sm ud-border-b ud-border-gray-900 ud-flex-col ud-p-2" v-for="(r, ridx) in rolls.slice().reverse()" :key="ridx">                 
                    <div class="ud-flex ud-justify-center">
                    <span class="ud-text-white">{{r.user.substring(0,5) + "..." + r.user.substring(r.user.length-5, r.user.length)}}</span>
                    &nbsp;rolled&nbsp;
                    <span class="ud-ml-1" v-for="(d, idx) in r.dice" :key="idx">
                      <img class="ud-w-4 ud-h-4 ud-mr-2 ud-mt-[2px]" :src="'/images/dice/1/'+d+'.png'"/>
                    </span>
                    {{getTimeAgo(r.ts)}}
                  
                      </div>  
                      <div> </div>   
                  </div>  
                  
                  </div>                
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      
    
   
    </section>
  </div>
</template>

<style scoped>
.backImg {
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/caesars/0.jpeg");
  background-color: #0d133e;
  background-blend-mode: overlay;
  background-size: cover;
}

.recentRollsBack {
  background-color: #1d2144;
  /*background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/backgrounds/ranking_back.png");*/
}
</style>

<script>
import diceRoller from "../components/diceRoller.vue";
import moment from "moment";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);

export default {
  name: "TTesserae",
  components: { 
    diceRoller
  },

  data: () => ({
    main: undefined,
    globalDisabled: false,
    rolls: undefined,
    lastRollsInterval: null
  }),
  methods: {
    getTimeAgo(t) {
      let timeAgo = new TimeAgo("en-US");  
      return timeAgo.format(new moment(t).toDate());
    },
    getLatestRolls(n) {
      return new Promise((resolve, reject) => {       
        this.main.tezos.contract
          .at(this.main.TESSERAE_ADDRESS)
          .then((contract) => {                 
            return contract.contractViews.last_rolls( n ).executeView({
              viewCaller: this.main.activeAccount
            });
          })
          .then((res) => {                
            resolve(JSON.parse(JSON.stringify(res)));
          })
          .catch((error) => {
            console.log(error);
            ///console.log("Error: " + JSON.stringify(error, null, 2));
            reject();
          });
      });
    },
    async startInterval() {
    console.log("Retrieving rolls...");
    this.rolls = await this.getLatestRolls(25);
    this.main.rollsUpdateTimer = setInterval( async () => {     
      this.rolls = await this.getLatestRolls(25);
    }, 30000)
  },
  stopInterval() {
    console.log("stopping updates");
    clearInterval(this.main.rollsUpdateTimer);
  },
  rollStart() {
    console.log("roll start");
    this.stopInterval();
  },
  rollSuccess() {
    console.log("roll finished");
    this.startInterval();
  }
  },  
  created: async function () {
    window.scrollTo(0, 0);
    this.main = this.$router.app.$children[0];
    //await this.main.getBalances();
    this.$forceUpdate();
  },
  mounted: async function () {
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");      
    this.startInterval();
    
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.main.rollsUpdateTimer);
    next();
  },
};
</script>
