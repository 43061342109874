<template>
  <div 
    class="      
      ud-flex
      ud-flex-col
      ud-text-center
      ud-h-full
      ud-items-center
      ud-justify-center      
      ud-mt-0
      ud-mx-0     
      ud-opacity-90
      ud-mb-0
      ud-border
      ud-border-gray-800
      ud-bg-transparent
      ud-rounded-b
    "  
  >
  <div class="ud-flex ud-h-full ud-flex-col ud-m-4">    
    <div
      class="
        ud-text-body ud-opacity-100 ud-text-xl ud-flex-grow ud-flex ud-flex-col
      "
    >
    <p class="ud-max-w-[320px] ud-text-xs ud-mb-3">Aerarium Saturni accumulates 10% of all mints. Be the first player to get the <i>Aureus</i> coin for each of the twelve emperors and claim the prize.</p>      
      <div
        class="ud-flex ud-items-center ud-justify-center ud-text-4xl ud-mb-4 ud-font-bold ud-text-yellow"       
      >
        {{ getNumber(main.prize / 1e6)
        }}<img class="ud-h-9 ud-ml-2" src="../assets/tezos-logo.svg" />
      </div>
    </div>

    <div class="ud-w-full">
      <button
        v-on:click="claim_prize()"
        :disabled="!canClaim() || claiming"
        class="
          disabled:ud-opacity-40
          ud-items-center
          ud-justify-center
          ud-block-inline
          ud-text-base
          ud-font-bold
          ud-text-white
          ud-bg-primary
          ud-py-3
          ud-px-8
          ud-w-full
          hover:ud-shadow-signUp hover:ud-bg-opacity-90
          ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
        "
      >
        <div v-if="!claiming">Claim</div>
        <div v-else>
          <svg
            class="
              ud-inline-block ud-animate-spin
              ud-ml--1
              ud-mr-1 ud-mb-[2px] ud-h-5 ud-w-5 ud-text-white
            "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="ud-opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="ud-opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Claiming...
        </div>
      </button>
    </div>

  </div>
   

   
  </div>
</template>

<style scoped>



.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: default;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script>
import numeral from "numeral";

export default {
  name: "claimPrize",
  components: {},
  props: {
    prize: {
      default: 0,
    },
    prize_given: {
      default: false,
    },
  },
  data: () => ({
    claiming: false,
  }),
  created: async function () {
    this.main = this.$router.app.$children[0];
  },
  mounted: async function () {},
  methods: {
    getNumber(n) {
      return numeral(n).format("0,0.0");
    },
    canClaim() {
      if (this.main.canClaimPrize && !this.main.prize_given) {
        return true;
      }
      return false;
    },
    claim_prize() {
      this.claiming = true;
      this.main.tezos.setWalletProvider(this.main.wallet);
      this.main.tezos.wallet
        .at(this.main.CONTRACT_ADDRESS)
        .then((contract) => {
          return contract.methods.claim_prize().send();
        })
        .then(async (op) => {
          console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(1);
          return op.opHash;
        })
        .then(async (hash) => {
          console.log("SUCCESS:" + hash);
          this.claiming = false;
          this.main.canClaimPrize = false;
          this.main.getBalances();
        })
        .catch((error) => {
          console.log("Error: " + JSON.stringify(error, null, 2));
          this.claiming = false;
        });
    },
  },
};
</script>
