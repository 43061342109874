<template>
  <div>
    <section
      id="home"
      class="
        ud-relative ud-z-10 ud-pt-[100px] ud-pb-[20px]
        md:ud-pt-[100px] md:ud-pb-[30px]
        xl:ud-pt-[100px] xl:ud-pb-[40px]
        2xl:ud-pt-[100px] 2xl:ud-pb-[50px]
        ud-min-h-full ud-w-full
        backImg
      "
    >
      <div class="ud-container">
        <div class="ud-flex ud-flex-wrap">
          <div class="ud-w-full ud-px-4">
            <div
              class="ud-mx-auto ud-text-center ud-mb-10 ud-mt-4 wow fadeInUp"
              data-wow-delay=".1s"
            >
             <h2
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-6
                  ud-text-center
                "
                style="font-family: 'RomanSD';"
              >
                Aureus
              </h2>
              <p
                class="
                  ud-text-sm ud-text-body-color ud-leading-relaxed
                  md:ud-leading-relaxed
                "
              >
              The gold denarius, simply called aureus for short, was the basic gold monetary unit of ancient Rome and the Roman world. Originally valued at 25 denarii of pure silver, it was used for large payments and commercial transactions beyond the borders of the empire. The passing of the years brought changes and from approximately 7.96 grams in its beginnings of the 1st century BC, it gradually depreciated until it weighed approximately 4.6 grams and, in addition, contained less amount of noble metal and a higher proportion of silver and copper. With Diocletian's reform, the aureus recovered value up to 5.4 grams and, finally, in the time of Constantine the Great, it was replaced by the aureus solid, a bridge to the medieval world.
               
              </p>
              <p
                class="
                  ud-text-sm ud-mt-4 ud-text-body-color ud-leading-relaxed
                  md:ud-leading-relaxed
                "
              >
              Get, through the "Cursus Honurum", the required denarii from each of the twelve Caesars and mint or find in the market the two Legio aureus to be able to get one of the only 10 aureus for each of the emperors.
                
              </p>
            </div>
          </div>
        </div>
      </div>
      
    
     <div>
        
      <div
        class="
          ud-flex
          ud-flex-row
          ud-flex-wrap
          ud-content-center
          ud-justify-items-center
          ud-justify-center
          ud-pb-[50px]
        "
      >
    
        <TClaimable
            v-for="i in main.aureusData"
            v-bind:key="i.claimID"
            :showPointer="false"
            :ref="i.claimID"
            class="
              ud-flex
              ud-flex-col
              ud-content-center
              ud-justify-items-center
              ud-justify-center
            "
            :claimableType="2"
            :claimID="i.claimID"
            :disableButton="globalDisabled"
            @claiming="globalDisabled = true"
            @success="claimSuccess()"
            @error="globalDisabled = false"
          />
       
      </div>
   
  </div>
    </section>
  </div>
</template>

<style scoped>
.backImg {
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/caesars/0.jpeg");
  background-color: #0d133e;
  background-blend-mode: overlay;
  background-size: cover;
}
</style>

<script>
import TClaimable from "../components/TClaimable.vue";

export default {
  name: "TMinter",
  components: {
    TClaimable
  },

  data: () => ({
    main: undefined,
    globalDisabled: false
  }),
  methods: {
    async claimSuccess() {
      await this.main.getBalances();
      this.$forceUpdate();
      this.$nextTick(() => {        
        this.globalDisabled = false;
        Object.keys(this.$refs).forEach((k) => {
          this.$refs[k][0].setData();
        });
      });
    },
  },
  created: async function () {
    window.scrollTo(0, 0);
    this.main = this.$router.app.$children[0];
    await this.main.getBalances();
    this.$forceUpdate();
  },
  mounted: async function () {
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");   
  }
};
</script>
