<template>
  <div>
    <section
      id="home"
      class="
        backImg
        ud-relative ud-z-10 ud-pt-[100px]
        md:ud-pt-[100px]
        xl:ud-pt-[100px]
        2xl:ud-pt-[100px]
        ud-w-full
        wow
        fadeInUp
      "
      data-wow-delay=".1s"
      :style="{
        backgroundImage: 'url(' + backgroundImage + ')',
      }"
    >
      <div
        class="
          ud-flex
          ud-flex-col
          ud-flex-wrap
          ud-content-center
          ud-justify-items-center
          ud-justify-center
          ud-w-full
        "
      >
        <!-- LEVEL 1 START -->
        <div
          class="
            ud-flex
            ud-flex-col
            ud-content-center
            ud-justify-items-center
            ud-justify-center
            ud-mb-5
          "
          v-for="i in items"
          v-bind:key="i.id"
        >
          <h1
            class="
              ud-text-black
              dark:ud-text-white
              ud-font-bold ud-text-3xl
              sm:ud-text-4xl
              md:ud-text-5xl
              ud-leading-tight
              sm:ud-leading-tight
              md:ud-leading-tight
              ud-m-6 ud-mb-1 ud-mt-4 ud-text-center
            "
            style="font-family: 'RomanSD'"
          >
            {{ i.title }}
          </h1>

          <p
            class="
              ud-text-body-color             
              ud-leading-relaxed
              md:ud-leading-relaxed
              ud-m-10 ud-mt-2 ud-text-center
              ud-text-sm
            "
          >
            {{ i.desc }}
          </p>
          <TClaimable
            :showPointer="true"
            :ref="i.claimID"
            class="
              ud-flex
              ud-flex-col
              ud-content-center
              ud-justify-items-center
              ud-justify-center
            "
            :claimableType="1"
            :claimID="i.claimID"
            :disableButton="globalDisabled"
            @claiming="globalDisabled = true"
            @success="claimSuccess()"
            @error="globalDisabled = false"
          />

          <!-- LEVEL 2 START -->
          <div
            class="ud-flex ud-flex-row ud-justify-center ud-mt-14 ud-flex-wrap"
          >
            <div v-for="c in i.children" v-bind:key="c.id" class="ud-flex">
              <TClaimable
                :showPointer="true"
                :ref="c.claimID"
                class="
                  ud-flex
                  ud-flex-col
                  ud-content-center
                  ud-justify-items-center
                  ud-justify-center
                "
                :claimableTitle="c.title"
                :claimableDesc="c.desc"
                :claimableType="1"
                :claimID="c.claimID"
                :disableButton="globalDisabled"
                @claiming="globalDisabled = true"
                @success="claimSuccess()"
                @error="globalDisabled = false"
              />
            </div>
          </div>
          <!-- LEVEL 2 END -->
        </div>
      </div>
      <!-- LEVEL 1 END -->
    </section>

    <section class="ud-pb-40 shapeImg">
      <!-- LEVEL 3 START -->
      <h1
        v-if="getLevel1().length > 0"
        class="
          ud-text-black
          dark:ud-text-white
          ud-font-bold ud-text-3xl
          sm:ud-text-3xl
          md:ud-text-4xl
          ud-leading-tight
          sm:ud-leading-tight
          md:ud-leading-tight
          ud-m-6 ud-pb-10 ud-pt-20 ud-mt-0 ud-text-center
        "
      >
        LEVEL 1 REQUIREMENTS
      </h1>
      <div
        class="
          ud-flex
          ud-flex-wrap
          ud-content-center
          ud-justify-items-center
          ud-justify-center
          ud-flex-row
          ud-p-4
        "
      >
        <div v-for="d in getLevel1()" v-bind:key="d.id">
          <TClaimable
            :ref="d.claimID"
            class="
              ud-flex
              ud-flex-col
              ud-content-center
              ud-justify-items-center
              ud-justify-center
            "
            :showPointer="true"
            :claimableType="1"
            :claimID="d.claimID"
            :disableButton="globalDisabled"
            @claiming="globalDisabled = true"
            @success="claimSuccess()"
            @error="globalDisabled = false"
          />
        </div>
      </div>
      <!-- LEVEL 3 END -->

      <!-- LEVEL 4 START -->
      <h1
        v-if="getLevel2().length > 0"
        class="
          ud-text-black
          dark:ud-text-white
          ud-font-bold ud-text-3xl
          sm:ud-text-3xl
          md:ud-text-4xl
          ud-leading-tight
          sm:ud-leading-tight
          md:ud-leading-tight
          ud-m-6 ud-pb-10 ud-pt-20 ud-mt-0 ud-text-center
        "
      >
        LEVEL 2 REQUIREMENTS
      </h1>
      <div
        class="
          ud-flex
          ud-flex-wrap
          ud-content-center
          ud-justify-items-center
          ud-justify-center
          ud-flex-row
          ud-p-4
        "
      >
        <div v-for="d in getLevel2()" v-bind:key="d.id">
          <TClaimable
            :ref="d.claimID"
            class="
              ud-flex
              ud-flex-col
              ud-content-center
              ud-justify-items-center
              ud-justify-center
            "
            :claimableType="1"
            :claimID="d.claimID"
            :disableButton="globalDisabled"
            @claiming="globalDisabled = true"
            @success="claimSuccess()"
            @error="globalDisabled = false"
          />
        </div>
      </div>
      <!-- LEVEL 4 END -->
    </section>
  </div>
</template>



<style scoped>
.backImg {
  background-color: #0d133e;
  background-blend-mode: overlay;
  background-size: cover;
}

.shapeImg {
  background-image: url("../assets/shape.svg");
}
</style>

<script>
import TClaimable from "../components/TClaimable.vue";

export default {
  name: "TCursus",
  components: {
    TClaimable,
  },
  data: () => ({
    main: undefined,
    items: [],
    active: [],
    selectedClaimID: 0,
    backgroundImage: "",
    globalDisabled: false,
  }),
  watch: {
    backgroundImage: {
      handler() {
        
      },
    },
    active: {
      handler(newValue, oldValue) {
        if (newValue[0]) {
          this.selectedClaimID = newValue[0].claimID;
          if (oldValue[0])
            console.log(oldValue[0].claimID + " => " + newValue[0].claimID);
        }
      },
      deep: true,
    },
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    async claimSuccess() {
      await this.main.getBalances();
      this.$forceUpdate();
      this.$nextTick(() => {
        this.setData();
        this.globalDisabled = false;         
        Object.keys(this.$refs).forEach( k => {
          try { this.$refs[k][0].setData(); } catch (e) { 
            // passs 
          }         
        });
      });
    },
    updateClaimID(val) {
      this.active = val;
    },
    setData() {
      //console.log("Setting data...");
      this.items = [];
      var bals = {};
      var k = 0;
      this.main.userBalance.forEach((b) => {
        bals[k] = b.balance;
        k++;
      });

      var m = {};

      var cid = 0;
      var owned = false;

      m = {
        id: cid,
        title: this.main.chData[this.$route.params.id].title,
        desc: this.main.chData[this.$route.params.id].desc,
        token_id: this.main.chData[this.$route.params.id].token_id,
        claimID: this.main.chData[this.$route.params.id].claimID,
        children: [],
      };

      this.main.chData[this.$route.params.id].reqs.forEach((mr) => {
        for (var i = 0; i < mr.amount; i++) {
          cid++;
          owned = false;
          if (bals[mr.token_id] > 0) {
            owned = true;
            bals[mr.token_id]--;
          }
          var c1 = {
            id: cid,
            token_id: mr.token_id,
            claimID: mr.claimID,
            title: mr.title,
            desc: mr.desc,
            owned: owned,
            children: [],
          };

          mr.reqs.forEach((mr1) => {
            for (var j = 0; j < mr1.amount; j++) {
              cid++;
              owned = false;
              if (bals[mr1.token_id] > 0) {
                owned = true;
                bals[mr1.token_id]--;
              }
              var c2 = {
                id: cid,
                token_id: mr1.token_id,
                claimID: mr1.claimID,
                owned: owned,
                children: [],
              };

              if (mr1.reqs) {
                mr1.reqs.forEach((mr2) => {
                  for (var k = 0; k < mr2.amount; k++) {
                    cid++;
                    owned = false;
                    if (bals[mr2.token_id] > 0) {
                      owned = true;
                      bals[mr2.token_id]--;
                    }
                    var c3 = {
                      id: cid,
                      token_id: mr2.token_id,
                      claimID: mr2.claimID,
                      owned: owned,
                      children: [],
                    };

                    if (mr2.reqs) {
                      mr2.reqs.forEach((mr3) => {
                        for (var l = 0; l < mr3.amount; l++) {
                          cid++;
                          owned = false;
                          if (bals[mr3.token_id] > 0) {
                            owned = true;
                            bals[mr3.token_id]--;
                          }
                          var c4 = {
                            id: cid,
                            token_id: mr3.token_id,
                            claimID: mr3.claimID,
                            owned: owned,
                            children: [],
                          };
                          c3.children.push(c4);
                        }
                      });
                    }
                    c2.children.push(c3);
                  }
                });
              }
            }
            c1.children.push(c2);
          });
          m.children.push(c1);
        }
      });

      this.items.push(m);
    },
    getRequirements(rs) {
      var reqs = {};
      rs.forEach((m) => {
        reqs[m.token_id] = m.amount;
      });
      return reqs;
    },
    getReward(rw) {
      var r = {};
      r[rw] = 1;
      return r;
    },
    getLevel1() {
      /// [...new Map(c.children.map((item) => [item['claimID'], item])).values(),].filter((f) => f.claimID != undefined)
      var l1 = [];
      this.items.forEach((i) => {
        i.children.forEach((c) => {
          [
            ...new Map(
              c.children.map((item) => [item["claimID"], item])
            ).values(),
          ]
            .filter((f) => f.claimID != undefined)
            .forEach((d) => {
              l1.push(d);
            });
        });
      });
      return l1;
    },
    getLevel2() {
      /// [...new Map(c.children.map((item) => [item['claimID'], item])).values(),].filter((f) => f.claimID != undefined)
      var l1 = [];
      this.items.forEach((i) => {
        i.children.forEach((c) => {
          [
            ...new Map(
              c.children.map((item) => [item["claimID"], item])
            ).values(),
          ]
            .filter((f) => f.claimID != undefined)
            .forEach((d) => {
              [
                ...new Map(
                  d.children.map((item) => [item["claimID"], item])
                ).values(),
              ]
                .filter((f) => f.claimID != undefined)
                .forEach((e) => {
                  l1.push(e);
                });
            });
        });
      });
      return l1;
    },
  },
  created: async function () {
    window.scrollTo(0, 0);
    this.main = this.$router.app.$children[0];
    this.setData();
    this.backgroundImage = this.main.CDN + "/caesars/" + this.$route.params.id + ".jpeg";
  },
  mounted: async function () {
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");
   
  },
  beforeRouteUpdate(to, from, next) {   
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");

    next();
    window.scrollTo(0, 0);
    this.globalDisabled=false;
    this.setData();
    this.backgroundImage = this.main.CDN + "/caesars/" + this.$route.params.id + ".jpeg";
  },
};
</script>

