<template>
  <div>
    <!-- ====== Hero Section Start -->
    <section
      id="home"
      class="
        ud-relative ud-z-10 ud-pt-[120px] ud-pb-[110px]
        md:ud-pt-[150px] md:ud-pb-[120px]
        xl:ud-pt-[180px] xl:ud-pb-[160px]
        2xl:ud-pt-[210px] 2xl:ud-pb-[200px]
        backImg
      "
    >
      <div class="ud-container">
        <div class="ud-flex ud-flex-wrap ud-mx-[-16px]">
          <div class="ud-w-full ud-px-4">
            <div
              class="ud-mx-auto ud-max-w-[570px] ud-text-center wow fadeInUp"
              data-wow-delay=".2s"
            >
              <h1
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-5xl
                  ud-leading-tight
                  sm:ud-leading-tight
                  md:ud-leading-tight
                  ud-mb-6 ud-mt-6
                "
                style="font-family: 'RomanSD'"
              >
                The Twelve Caesars
              </h1>
              <p
                class="
                  ud-font-medium ud-text-lg
                  md:ud-text-xl
                  ud-leading-relaxed
                  md:ud-leading-relaxed
                  ud-text-body-color
                  dark:ud-text-white dark:ud-opacity-90
                  ud-mb-14
                "
              >
                <span class="ud-text-cyan-400">Learn</span> About Ancient Rome
                and the Lives of the Twelve Caesars through the
                <i>Cursus Honorum</i>.
                <span class="ud-text-cyan-400">Collect</span> and
                <span class="ud-text-cyan-400">Trade</span>
                the More than <strong>260</strong> Unique Roman Imperial Coins
                and <span class="ud-text-cyan-400">Compete</span> in the race
                for the Unique Ten <i>Aureus</i> Tokens for each of the Twelve
                Caesars. Be the First Player to Get the Twelve Caesars
                <i>Aureus</i> and
                <span class="ud-text-cyan-400">Claim</span> the Aerarium
                Saturni.
              </p>
              <div class="ud-flex ud-items-center ud-justify-center">
                <a
                  href="#howtoplay"
                  class="
                    menu-scroll
                    ud-text-base
                    ud-font-semibold
                    ud-text-white
                    ud-bg-primary
                    ud-py-4
                    ud-px-8
                    hover:ud-bg-opacity-80
                    ud-mx-2
                    ud-rounded-md
                    ud-transition
                    ud-duration-300
                    ud-ease-in-out
                  "
                >
                  Get Started
                </a>
                <a
                  href="#features"
                  class="
                    menu-scroll
                    ud-text-base
                    ud-font-semibold
                    ud-text-black
                    ud-bg-black
                    ud-bg-opacity-10
                    dark:ud-text-white dark:ud-bg-white dark:ud-bg-opacity-10
                    ud-py-4 ud-px-8
                    hover:ud-bg-opacity-20
                    dark:hover:ud-bg-opacity-20
                    ud-mx-2
                    ud-rounded-md
                    ud-transition
                    ud-duration-300
                    ud-ease-in-out
                  "
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div> -->
      <div class="ud-absolute ud-top-0 ud-right-0 ud-z-[-1]">
        <svg
          width="450"
          height="556"
          viewBox="0 0 450 556"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="277" cy="63" r="225" fill="url(#paint0_linear_25:217)" />
          <circle
            cx="17.9997"
            cy="182"
            r="18"
            fill="url(#paint1_radial_25:217)"
          />
          <circle
            cx="76.9997"
            cy="288"
            r="34"
            fill="url(#paint2_radial_25:217)"
          />
          <circle
            cx="325.486"
            cy="302.87"
            r="180"
            transform="rotate(-37.6852 325.486 302.87)"
            fill="url(#paint3_linear_25:217)"
          />
          <circle
            opacity="0.8"
            cx="184.521"
            cy="315.521"
            r="132.862"
            transform="rotate(114.874 184.521 315.521)"
            stroke="url(#paint4_linear_25:217)"
          />
          <circle
            opacity="0.8"
            cx="356"
            cy="290"
            r="179.5"
            transform="rotate(-30 356 290)"
            stroke="url(#paint5_linear_25:217)"
          />
          <circle
            opacity="0.8"
            cx="191.659"
            cy="302.659"
            r="133.362"
            transform="rotate(133.319 191.659 302.659)"
            fill="url(#paint6_linear_25:217)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_25:217"
              x1="-54.5003"
              y1="-178"
              x2="222"
              y2="288"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint1_radial_25:217"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(17.9997 182) rotate(90) scale(18)"
            >
              <stop offset="0.145833" stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0.08" />
            </radialGradient>
            <radialGradient
              id="paint2_radial_25:217"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(76.9997 288) rotate(90) scale(34)"
            >
              <stop offset="0.145833" stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0.08" />
            </radialGradient>
            <linearGradient
              id="paint3_linear_25:217"
              x1="226.775"
              y1="-66.1548"
              x2="292.157"
              y2="351.421"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_25:217"
              x1="184.521"
              y1="182.159"
              x2="184.521"
              y2="448.882"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_25:217"
              x1="356"
              y1="110"
              x2="356"
              y2="470"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_25:217"
              x1="118.524"
              y1="29.2497"
              x2="166.965"
              y2="338.63"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="ud-absolute ud-bottom-0 ud-left-0 ud-z-[-1]">
        <svg
          width="364"
          height="201"
          viewBox="0 0 364 201"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.88928 72.3303C33.6599 66.4798 101.397 64.9086 150.178 105.427C211.155 156.076 229.59 162.093 264.333 166.607C299.076 171.12 337.718 183.657 362.889 212.24"
            stroke="url(#paint0_linear_25:218)"
          />
          <path
            d="M-22.1107 72.3303C5.65989 66.4798 73.3965 64.9086 122.178 105.427C183.155 156.076 201.59 162.093 236.333 166.607C271.076 171.12 309.718 183.657 334.889 212.24"
            stroke="url(#paint1_linear_25:218)"
          />
          <path
            d="M-53.1107 72.3303C-25.3401 66.4798 42.3965 64.9086 91.1783 105.427C152.155 156.076 170.59 162.093 205.333 166.607C240.076 171.12 278.718 183.657 303.889 212.24"
            stroke="url(#paint2_linear_25:218)"
          />
          <path
            d="M-98.1618 65.0889C-68.1416 60.0601 4.73364 60.4882 56.0734 102.431C120.248 154.86 139.905 161.419 177.137 166.956C214.37 172.493 255.575 186.165 281.856 215.481"
            stroke="url(#paint3_linear_25:218)"
          />
          <circle
            opacity="0.8"
            cx="214.505"
            cy="60.5054"
            r="49.7205"
            transform="rotate(-13.421 214.505 60.5054)"
            stroke="url(#paint4_linear_25:218)"
          />
          <circle cx="220" cy="63" r="43" fill="url(#paint5_radial_25:218)" />
          <defs>
            <linearGradient
              id="paint0_linear_25:218"
              x1="184.389"
              y1="69.2405"
              x2="184.389"
              y2="212.24"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_25:218"
              x1="156.389"
              y1="69.2405"
              x2="156.389"
              y2="212.24"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_25:218"
              x1="125.389"
              y1="69.2405"
              x2="125.389"
              y2="212.24"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_25:218"
              x1="93.8507"
              y1="67.2674"
              x2="89.9278"
              y2="210.214"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_25:218"
              x1="214.505"
              y1="10.2849"
              x2="212.684"
              y2="99.5816"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint5_radial_25:218"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(220 63) rotate(90) scale(43)"
            >
              <stop offset="0.145833" stop-color="white" stop-opacity="0" />
              <stop offset="1" stop-color="white" stop-opacity="0.08" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <!-- </div> -->
    </section>
    <!-- ====== Hero Section End -->

    <!-- ====== Features Section Start -->
    <section
      id="features"
      class="ud-bg-primary ud-bg-opacity-[3%] ud-pt-[120px] ud-pb-[20px]"
    >
      <div class="ud-container">
        <div class="ud-flex ud-flex-wrap">
          <div class="ud-w-full ud-px-4">
            <div
              class="
                ud-mx-auto ud-max-w-[800px] ud-text-center ud-mb-[100px]
                wow
                fadeInUp
              "
              data-wow-delay=".1s"
            >
              <h2
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-12
                "
              >
                Main Features
              </h2>

              <p
                class="
                  ud-text-body-color ud-text-base
                  md:ud-text-lg
                  ud-leading-relaxed
                  md:ud-leading-relaxed
                  ud-mb-4
                "
              >
                The coin was one of the pillars of the Roman Empire and an
                indispensable tool for its inhabitants. Salaries, taxes, public
                works and payments of all kinds were settled with pieces minted
                in gold, silver, brass or bronze: aureus, denarius, sesterces,
                dupondians or aces.
              </p>
              <p
                class="
                  ud-text-body-color ud-text-base
                  md:ud-text-lg
                  ud-leading-relaxed
                  md:ud-leading-relaxed
                  ud-mb-4
                "
              >
                Produced in huge quantities and shipped from the capital to the
                provinces, coins were also one of the state's most important
                propaganda instruments. The portraits of the emperors were
                accompanied by great public works, military victories and
                allegories of effective government, a repertoire of images
                intended to spread the greatness of Rome.
              </p>
              <p
                class="
                  ud-text-body-color ud-text-base
                  md:ud-text-lg
                  ud-leading-relaxed
                  md:ud-leading-relaxed
                  ud-mb-4
                "
              >
                Taezars is a digital collectable game which features
              </p>
            </div>
          </div>
        </div>

        <div class="ud-flex ud-flex-wrap ud-mx-[-16px]">
          <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
            <div class="ud-mb-[70px] wow fadeInUp" data-wow-delay=".15s">
              <div
                class="
                  ud-w-[70px]
                  ud-h-[70px]
                  ud-flex
                  ud-items-center
                  ud-justify-center
                  ud-rounded-md
                  ud-bg-primary
                  ud-bg-opacity-10
                  ud-mb-10
                  ud-text-primary
                "
              >
                <img
                  src="../assets/tezos-logo.svg"
                  width="40"
                  height="41"
                  viewBox="0 0 40 41"
                  class="ud-fill-current"
                />
              </div>
              <h3
                class="
                  ud-font-bold ud-text-black
                  dark:ud-text-white
                  ud-text-xl
                  sm:ud-text-2xl
                  lg:ud-text-xl
                  xl:ud-text-2xl
                  ud-mb-5
                "
              >
                Built on Tezos
              </h3>
              <p
                class="
                  ud-text-body-color
                  ud-text-base
                  ud-leading-relaxed
                  ud-font-medium
                  ud-pr-[10px]
                "
              >
                Taezars is built on the most energy-efficient blockchain
                technology. Unlike other blockchains, Tezos is eco-friendly as
                it requires significantly less energy and cost to operate. May
                not be the most popular blockchain out there but we know it is
                the best.
              </p>
            </div>
          </div>
          <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
            <div class="ud-mb-[70px] wow fadeInUp" data-wow-delay=".2s">
              <div
                class="
                  ud-w-[70px]
                  ud-h-[70px]
                  ud-flex
                  ud-items-center
                  ud-justify-center
                  ud-rounded-md
                  ud-bg-primary
                  ud-bg-opacity-10
                  ud-mb-10
                  ud-text-primary
                "
              >
                <img
                  src="../assets/coins.svg"
                  width="50"
                  height="50"
                  viewBox="0 0 40 40"
                  class="ud-fill-current"
                />
              </div>
              <h3
                class="
                  ud-font-bold ud-text-black
                  dark:ud-text-white
                  ud-text-xl
                  sm:ud-text-2xl
                  lg:ud-text-xl
                  xl:ud-text-2xl
                  ud-mb-5
                "
              >
                Coin Mint
              </h3>
              <p
                class="
                  ud-text-body-color
                  ud-text-base
                  ud-leading-relaxed
                  ud-font-medium
                  ud-pr-[10px]
                "
              >
                Mint or claim more than <strong>260</strong> types of carefully
                crafted 3D-animated coins. Not only they are beautiful but they
                are accompained of a detailed description and the history behind
                them. If you get lucky, you can mint a <i>Legio Aureus</i> coin
                which are very highly valued.
              </p>
            </div>
          </div>
          <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
            <div class="ud-mb-[70px] wow fadeInUp" data-wow-delay=".25s">
              <div
                class="
                  ud-w-[70px]
                  ud-h-[70px]
                  ud-flex
                  ud-items-center
                  ud-justify-center
                  ud-rounded-md
                  ud-bg-primary
                  ud-bg-opacity-10
                  ud-mb-10
                  ud-text-primary
                "
              >
                <img
                  src="../assets/diploma.svg"
                  width="50"
                  height="50"
                  viewBox="0 0 40 40"
                  class="ud-fill-current"
                />
              </div>
              <h3
                class="
                  ud-font-bold ud-text-black
                  dark:ud-text-white
                  ud-text-xl
                  sm:ud-text-2xl
                  lg:ud-text-xl
                  xl:ud-text-2xl
                  ud-mb-5
                "
              >
                Cursus Honorum
              </h3>
              <p
                class="
                  ud-text-body-color
                  ud-text-base
                  ud-leading-relaxed
                  ud-font-medium
                  ud-pr-[10px]
                "
              >
                Learn about ancient Rome and the lives of the Twelve Caesars and
                grow your collection by completing the
                <i>Cursus Honorum</i> sets by exchanging lower rarity coins for
                more special and unique ones. Complete them and then try get the
                <i>Aureus</i> coins for each Caesar.
              </p>
            </div>
          </div>
          <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
            <div class="ud-mb-[70px] wow fadeInUp" data-wow-delay=".1s">
              <div
                class="
                  ud-w-[70px]
                  ud-h-[70px]
                  ud-flex
                  ud-items-center
                  ud-justify-center
                  ud-rounded-md
                  ud-bg-primary
                  ud-bg-opacity-10
                  ud-mb-10
                  ud-text-primary
                "
              >
                <img
                  src="../assets/trade.svg"
                  width="50"
                  height="50"
                  viewBox="0 0 40 40"
                  class="ud-fill-current"
                />
              </div>
              <h3
                class="
                  ud-font-bold ud-text-black
                  dark:ud-text-white
                  ud-text-xl
                  sm:ud-text-2xl
                  lg:ud-text-xl
                  xl:ud-text-2xl
                  ud-mb-5
                "
              >
                Market
              </h3>
              <p
                class="
                  ud-text-body-color
                  ud-text-base
                  ud-leading-relaxed
                  ud-font-medium
                  ud-pr-[10px]
                "
              >
                Buy, sell or exchange coins to try to make a profit or to help
                you and other players complete the collection and the
                <i>Cursus Honorum</i> sets cheaper and quicker.
                <i>Legio Aureus</i> coins are really difficult to mint so, if
                you are lucky, you can sell them for a big profit.
              </p>
            </div>
          </div>
          <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
            <div class="ud-mb-[70px] wow fadeInUp" data-wow-delay=".15s">
              <div
                class="
                  ud-w-[70px]
                  ud-h-[70px]
                  ud-flex
                  ud-items-center
                  ud-justify-center
                  ud-rounded-md
                  ud-bg-primary
                  ud-bg-opacity-10
                  ud-mb-10
                  ud-text-primary
                "
              >
                <img
                  src="../assets/album.svg"
                  width="50"
                  height="50"
                  viewBox="0 0 40 40"
                  class="ud-fill-current"
                />
              </div>
              <h3
                class="
                  ud-font-bold ud-text-black
                  dark:ud-text-white
                  ud-text-xl
                  sm:ud-text-2xl
                  lg:ud-text-xl
                  xl:ud-text-2xl
                  ud-mb-5
                "
              >
                Collection
              </h3>
              <p
                class="
                  ud-text-body-color
                  ud-text-base
                  ud-leading-relaxed
                  ud-font-medium
                  ud-pr-[10px]
                "
              >
                See your completion progress and compare yourself to other
                players in your collection and daily rankings. Your score is
                determined by the amount and rarity of the coins you hold. Try
                to be the first to get all the available coin types and complete
                your collection.
              </p>
            </div>
          </div>
          <div class="ud-w-full md:ud-w-1/2 lg:ud-w-1/3 ud-px-4">
            <div class="ud-mb-[70px] wow fadeInUp" data-wow-delay=".2s">
              <div
                class="
                  ud-w-[70px]
                  ud-h-[70px]
                  ud-flex
                  ud-items-center
                  ud-justify-center
                  ud-rounded-md
                  ud-bg-primary
                  ud-bg-opacity-10
                  ud-mb-10
                  ud-text-primary
                "
              >
                <img
                  src="../assets/icon_caesar_coin.png"
                  width="60"
                  height="60"
                  viewBox="0 0 40 45"
                  class="ud-fill-current"
                />
              </div>
              <h3
                class="
                  ud-font-bold ud-text-black
                  dark:ud-text-white
                  ud-text-xl
                  sm:ud-text-2xl
                  lg:ud-text-xl
                  xl:ud-text-2xl
                  ud-mb-5
                "
              >
                Aureus
              </h3>
              <p
                class="
                  ud-text-body-color
                  ud-text-base
                  ud-leading-relaxed
                  ud-font-medium
                  ud-pr-[10px]
                "
              >
                There are <strong>ONLY TEN</strong> aureus Caesars coins for
                each of the Twelve Caesars. Be the first player to complete the
                12 <i>Aureus</i> by minting the required
                <i>Aureus Legions</i> and completing each
                <i>Cursus Honorum</i> and claim the <i>Aerarium Saturni</i>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====== Features Section End -->

    <!-- ====== Screenshots Section Start -->
    <section
      id="screenshots"
      class="ud-bg-primary ud-bg-opacity-[3%] ud-pt-[100px] ud-pb-[20px]"
    >
      <div class="ud-container">
        <div class="ud-flex ud-flex-wrap">
          <div class="ud-w-full ud-px-4">
            <div
              class="
                ud-mx-auto ud-max-w-[1200px] ud-text-center ud-mb-[100px]
                wow
                fadeInUp
              "
              data-wow-delay=".1s"
            >
              <h2
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-12
                "
              >
                Screenshots
              </h2>

              <div
                class="
                  ud-flex ud-w-full ud-justify-center ud-flex-wrap ud-gap-8
                "
              >
                <div>
                  <img
                    class="ud-w-[320px] sm:ud-w-[500px]"
                    src="https://delphin.fra1.cdn.digitaloceanspaces.com/screenshots/1.png"
                  />
                </div>
                <div>
                  <img
                    class="ud-w-[320px] sm:ud-w-[500px]"
                    src="https://delphin.fra1.cdn.digitaloceanspaces.com/screenshots/2.png"
                  />
                </div>
                <div>
                  <img
                    class="ud-w-[320px] sm:ud-w-[500px]"
                    src="https://delphin.fra1.cdn.digitaloceanspaces.com/screenshots/3.png"
                  />
                </div>
                <div>
                  <img
                    class="ud-w-[320px] sm:ud-w-[500px]"
                    src="https://delphin.fra1.cdn.digitaloceanspaces.com/screenshots/4.png"
                  />
                </div>
                <div>
                  <h2
                    class="
                      ud-text-black
                      dark:ud-text-white
                      ud-font-bold ud-text-3xl
                      sm:ud-text-4xl
                      md:ud-text-[45px]
                      ud-mb-12 ud-mt-[20px]
                    "
                  >
                    Minting
                  </h2>
                  <video                   
                    loop
                    autoplay
                    muted
                    controls="true"
                    playsInline
                  >
                    <source
                      src="https://delphin.fra1.cdn.digitaloceanspaces.com/screenshots/video.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====== Screenshots Section End -->

    <section id="howtoplay" class="ud-relative ud-z-10 ud-py-[100px]">
      <div class="ud-container">
        <div class="ud-flex ud-flex-wrap ud-mx-[-16px]">
          <div class="ud-w-full ud-px-4">
            <div
              class="
                ud-mx-auto ud-max-w-[570px] ud-text-center ud-mb-20
                wow
                fadeInUp
              "
              data-wow-delay=".1s"
            >
              <h2
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-4
                "
              >
                How to Play
              </h2>
              <p
                class="
                  ud-text-body-color ud-text-base
                  md:ud-text-lg
                  ud-leading-relaxed
                  md:ud-leading-relaxed
                  ud-mb-8
                "
              >
                In order to play Taezars: The Twelve Caesars, you need XTZ (the
                native cryptocurrency of the Tezos Blockchain) and a mobile
                wallet or a wallet extension for your browser.
              </p>
              <ul
                class="
                  ud-text-body-color ud-leading-relaxed ud-text-justify
                  md:ud-leading-relaxed
                  ud-list-disc ud-text-sm ud-mx-6
                "
              >
                <li class="ud-mb-4">
                  Download a Tezos wallet to store the XTZ and the tokens you
                  mint. There are many options but our favorite is
                  <a
                    class="
                      ud-text-sm
                      ud-font-medium
                      ud-inline-block
                      ud-text-gray-300
                      hover:ud-text-primary
                    "
                    target="_blank"
                    href="https://templewallet.com"
                    >Temple Wallet</a
                  >. You can also use it on your mobile phone or tablet!
                </li>
                <li class="ud-mb-4">
                  Add XTZ to your wallet by going to any of the multiple
                  exchanges (<a
                    href="https://www.kraken.com/prices/xtz-tezos-price-chart/"
                    target="_blank"
                    class="
                      ud-text-sm
                      ud-font-medium
                      ud-inline-block
                      ud-text-body-color
                      hover:ud-text-primary
                    "
                  >
                    Kraken
                  </a>
                  or
                  <a
                    href="https://www.coinbase.com/price/tezos"
                    target="_blank"
                    class="
                      ud-text-sm
                      ud-font-medium
                      ud-inline-block
                      ud-text-body-color
                      hover:ud-text-primary
                    "
                  >
                    Coinbase
                  </a>
                  for instance). You can also follow the instructions on the
                  video below.
                </li>
                <li class="ud-mb-4">
                  Start minting legion coins. Legions are the base for
                  completing the <i>Cursus Honorum</i> for each of the Twelve
                  Caesars. You can then exchange these for more valuable coins
                  (especially if you get lucky and mint a
                  <i>Legio Aureus</i> coin). There are also bonus coins which
                  besides being needed to complete the collection, provide
                  minting cost reduction.
                </li>
                <li class="ud-mb-4">
                  Trade coins in the Taezars market: if you have many coins of
                  same type you can trade them with other users in exchange for
                  XTZ or other coins.
                </li>
                <li class="ud-mb-4">
                  Be quick, complete all the <i>Cursus Honorum</i> and mint or
                  trade all <i>Legio Aureus</i> coins and try to be the
                  <strong>first player</strong> to get all of the
                  <strong>ONLY TEN</strong> aureus coins that are available for
                  each of the caesars and claim the Aerarium Saturni (it
                  accumulates
                  <strong>10% for each coin mint or claim</strong> for all
                  players!).
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="ud-flex ud-flex-wrap ud-mx-[-16px] ud-my-[-16px]">
          <div class="ud-w-full ud-px-4">
            <div
              class="
                ud-mx-auto ud-max-w-[770px] ud-rounded-md ud-overflow-hidden
                wow
                fadeInUp
              "
              data-wow-delay=".15s"
            >
              <div
                class="ud-flex ud-relative ud-items-center ud-justify-center"
              >
                <div>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/QL8ZLKGDr8c"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ud-absolute ud-bottom-0 ud-left-0 ud-right-0 ud-z-[-1]">
        <img src="images/video/shape.svg" alt="shape" class="ud-w-full" />
      </div>
    </section>

    <section id="pfps" class="ud-relative ud-z-10 ud-py-[100px]">
      <div class="ud-container">
        <div class="ud-flex ud-flex-wrap ud-mx-[10px]">
          <div class="ud-w-full ud-px-4">
            <div
              class="
                ud-mx-auto ud-max-w-[800px] ud-text-center ud-mb-20
                wow
                fadeInUp
              "
              data-wow-delay=".1s"
            >
              <h2
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-4
                "
              >
                PFPs
              </h2>
              <p
                class="
                  ud-text-body-color ud-text-base
                  md:ud-text-lg
                  ud-leading-relaxed
                  md:ud-leading-relaxed
                  ud-mb-8
                "
              >
                Taezars is not only hundredths of Roman ancient coins with their detailed description. We have also created 120 
                unique non-generative cartoonist style PFPs representing the Twelve Caesars from Julius Caesar to Domitian with 
                10 different styles for each one. <br/><br/>Go to the  <a
                    class="
                      ud-text-md
                      ud-font-medium
                      ud-inline-block
                      ud-text-gray-300
                      hover:ud-text-primary
                    "
                    target="_blank"
                    href="https://objkt.com/collection/KT1WZjZqzby4svYebE5NwGZDP6KrvupRKmw6"
                    >Taezars PFPs Collection</a
                  > and start collecting them!
              </p>
              <img src="../assets/pfps.jpeg" alt="shape" class="ud-w-full" />
          
            </div>
          </div>
        </div>
       
      </div>
      <div class="ud-absolute ud-bottom-0 ud-left-0 ud-right-0 ud-z-[-1]">
        <img src="images/video/shape.svg" alt="shape" class="ud-w-full" />
      </div>
    </section>


    <section id="comingsoon" class="ud-relative ud-z-10 ud-py-[20px]">
      <div class="ud-container ud-justify-center">
        <div class="ud-flex ud-justify-center ud-flex-wrap ud-mx-[-16px]">
          <div class="ud-w-full ud-px-4">
            <div
              class="
                ud-flex
                ud-flex-col
                ud-justify-center
                ud-items-center
                ud-mx-auto
                ud-max-w-[570px]
                ud-text-center
                ud-mb-20
                wow
                fadeInUp
              "
              data-wow-delay=".1s"
            >
              <h2
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-4
                "
              >
                Coming soon ...
              </h2>
              <div class="ud-px-4 ud-w-60 ud-max-w-full ud-text-center">
                <a href="/" class="ud-w-full ud-block ud-py-8 header-logo">
                  <div
                    style="font-family: 'RomanSD'"
                    class="
                      ud-text-black
                      dark:ud-text-white
                      ud-mb-0
                      ud-justify-center
                      ud-text-center
                      ud-items-center
                      ud-font-bold
                      ud-text-5xl
                      ud-flex
                      ud-flex-col
                    "
                  >
                    <div class="ud-text-yellow ud-mt-2 ud-pt-0">TAEZARS</div>
                    <div
                      class="
                        ud-p-0
                        ud-flex
                        ud-text-[9px]
                        ud-mt-[-6px]
                        ud-text-center
                        ud-justify-center
                      "
                    >
                      <div class="text-center">
                        <div class="ud-text-gray-400 ud-mt-1 ud-p-0">
                          VOLUME II: The Good Emperors
                        </div>
                        <div class="ud-text-gray-400 ud-m-0 ud-p-0 ud-mt-[2px]">
                          From Nerva to Comodo
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ud-absolute ud-bottom-0 ud-left-0 ud-right-0 ud-z-[-1]">
        <img src="images/video/shape.svg" alt="shape" class="ud-w-full" />
      </div>
    </section>
  </div>
</template>

<style>
.backImg {
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/caesars/1.jpeg");
  background-color: #0d133e;
  background-blend-mode: overlay;
  background-size: cover;
}
</style>

<script>
export default {
  name: "THome",
  data: () => ({}),
  created: async function () {
    window.scrollTo(0, 0);
  },
  mounted: function () {
    window.scrollTo(0, 0);
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");
    //console.log(this.main.tokenGroups);
    const pageLink = document.querySelectorAll(".menu-scroll");

    try {
      pageLink.forEach((elem) => {
        elem.addEventListener("click", (e) => {
          e.preventDefault();

          try {
            document.querySelector(elem.getAttribute("href")).scrollIntoView({
              behavior: "smooth",
              offsetTop: 1 - 60,
            });
          } catch (e) {
            //pass
          }
        });
      });
    } catch (e) {
      //pass
    }
  },
};
</script>