<template>
  <div class="backImg">
    <section
      id="home"
      class="
        ud-relative ud-z-10 ud-pt-[100px] ud-pb-[20px]
        md:ud-pt-[100px] md:ud-pb-[30px]
        xl:ud-pt-[100px] xl:ud-pb-[40px]
        2xl:ud-pt-[100px] 2xl:ud-pb-[50px]
        ud-min-h-full ud-w-full
      "
    >
      <div class="ud-container">
        <div class="ud-flex ud-flex-wrap">
          <div class="ud-w-full ud-px-4">
            <div
              class="ud-mx-auto ud-text-center ud-mb-10 ud-mt-4 wow fadeInUp"
              data-wow-delay=".1s"
            >
              <h2
                class="
                  ud-text-black
                  dark:ud-text-white
                  ud-font-bold ud-text-3xl
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                  ud-mb-6 ud-text-center
                "
                style="font-family: 'RomanSD'"
              >
                Mint
              </h2>
              <p
                class="
                  ud-text-sm ud-text-body-color ud-leading-relaxed
                  md:ud-leading-relaxed
                "
              >
                Sicily year 211 BC, Marco Claudio Marcelo, the sword of Rome,
                finally manages to take Syracuse, and the enormous riches
                obtained from the sack of the city offered Rome the possibility
                of a radical change: Silver displaced bronze as the main metal
                and the “argentum denarius” was introduced as the official
                currency of Rome. With the denarius, Roman coins ceased to be
                melted down and passed to be manufactured using the technique we
                know as minting. During the Republic and the first centuries of
                the empire, the mint was centralized in Rome, whose mint was in
                the Capitol, in the vicinity of the temple of Juno, from whose
                nickname, Moneta ("the one who warns"), comes the term money.
              </p>
              <p
                class="
                  ud-text-sm ud-mt-4 ud-text-body-color ud-leading-relaxed
                  md:ud-leading-relaxed
                "
              >
                Today the minting process is carried out by machines in
                factories, but at the time of the 12 Caesars the process was
                completely manual (so it is possible to find defects and errors
                typical of artisanal manufacturing) and it was carried out in
                the "officina monetalis”, under the responsibility of the
                “tresviri argento aere auro flando feriundo” (“the three men for
                the foundry and minting of gold, silver and bronze”), where two
                different dies were used to mint, one slightly concave that was
                fixed on an anvil (the obverse) and another slightly convex one
                that was mobile (the reverse). The “malleator” placed the blank
                in the fixed die and struck with the hammer (“malleolus”) on the
                mobile die in such a way that the pressure exerted by the blow
                embedded the blank in the uncut parts of the two dies.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--- NOTABLE MINTS-->
      <div class="ud-flex ud-flex-row ud-flex-wrap ud-justify-center ud-gap-4">
        <div
          class="
            ud-flex-grow ud-max-w-[385px] ud-mb-1
            sm:ud-mx-0
            ud-mx-4
            noselect
            ud-text-gray-400
          "
        >
          <div
            class="
              ud-p-2
              ud-rounded-t-xl
              ud-border
              ud-border-gray-800
              ud-border-b-0
              ud-text-white
              ud-text-center
            "
            style="
              /* From https://css.glass */
              background: rgba(35, 9, 66, 0.59);
              box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
              backdrop-filter: blur(5px);
              -webkit-backdrop-filter: blur(5px);
            "
          >
            Notable Mints
          </div>
          <div
            class="
              ud-text-center ud-mb-0 ud-h-[185px] ud-rounded-b-lg
              backImgMints
              ud-text-[10px] ud-pb-4
            "
            style="font-family: 'RomanSD'"
          >
            <div class="ud-h-[100%] ud-overflow-auto ud-contain ud-p-4">
              <transition name="fade" mode="out-in">
                <div
                  v-if="notableMints.length > 0"
                  class="ud-h-[100%] ud-w-full ud-m-0 ud-mb-8"
                >
                  <div
                    class="ud-mb-3 ud-w-full"
                    v-for="m in notableMints"
                    v-bind:key="m.id"
                  >
                    On {{ getHistoricalDate(m.timestamp) }}
                    <span class="ud-text-gray-200">{{
                      m.user.substring(0, 5) +
                      "..." +
                      m.user.substring(m.user.length - 5, m.user.length)
                    }}</span>
                    minted
                    <span class="ud-text-gray-200">{{
                      main.getTokenName(m.token)
                    }}</span>
                  </div>
                </div>
              </transition>
              <transition name="fade" mode="out-in">
                <div v-if="notableMints.length == 0 && notableMintsLoaded">
                  No notable mints yet.
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div
          class="
            ud-flex-grow ud-mb-8
            sm:ud-mx-0
            ud-mx-4
            noselect
            ud-text-white ud-max-w-[385px]
          "
        >
          <div
            class="
              ud-p-2
              ud-rounded-t-xl
              ud-border
              ud-border-gray-800
              ud-border-b-0
              ud-text-center
            "
            style="
              /* From https://css.glass */
              background: rgba(35, 9, 66, 0.59);
              box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
              backdrop-filter: blur(5px);
              -webkit-backdrop-filter: blur(5px);
            "
          >
            Aerarium Saturni
          </div>
          <div
            class="
              ud-text-center ud-p-0 ud-mb-0 ud-h-[185px]
              backImgSaturn
              ud-rounded-b-lg
            "
          >
            <claimPrize />
          </div>
        </div>
      </div>

      <div
        class="
          ud-flex
          ud-flex-row
          ud-flex-wrap
          ud-content-center
          ud-justify-items-center
          ud-justify-center
        "
      >
        <div class="ud-absolute ud-bottom-0 ud-left-0 ud-right-0 ud-z-[-1]">
          <img src="images/video/shape.svg" alt="shape" class="ud-w-full" />
        </div>

        <div class="ud-flex ud-flex-row ud-justify-center ud-flex-wrap">
          <coinMint
            class="ud-mb-2"
            ref="m0"
            :ownedQty="main.ownLegions"
            :totalQty="main.totalLegions"
            mintFunction="0"
            mintTitle="Legions"
            mintType="LEGIO"
            mintImage="icon_legion.png"
            mintDescription="There are 27 denarius legions and 12 aureus ones for a total of 39 legion coins. Silver legions are the base coin for completing the <a to='/cursus-honorum'>Cursus Honorum</a> which lead to the Twelve Caesars and Aureus legions (which are much more difficult to mint) are used to complete the Caesar's aureus. You can reduce the cost of minting legions to 0.25 tez by obtaining and holding the <i>Claudius - Praetorian Camp</i> bonus coin. "
            :canMint="true"
            :mintCost="this.main.LEGION_COST"
            :isReducedCost="this.main.legionDiscount"
            :mintReducedCost="this.main.LEGION_REDUCED_COST"
            :disableButton="globalDisabled"
            @minting="globalDisabled = true"
            @success="mintSuccess()"
            @error="globalDisabled = false"
          />
          <coinMint
            class="ud-mb-2"
            ref="m1"
            :ownedQty="main.ownGods"
            :totalQty="main.totalGods"
            mintFunction="1"
            mintTitle="Gods"
            mintType="GOD"
            mintImage="icon_god.png"
            mintDescription=" There are 40 different God coins to mint. Gods are used to complete some of the <a to='/cursus-honorum'>Cursus Honorum</a> which 
          lead to the Twelve Caesars. Minting Gods requires completing <i>Minting Gods</i> bonus which gives you the <i>Julius Caesar - Pontifex Maximus</i> coin.
          You can reduce the cost of minting gods by completing the <i>Apotheosis</i> bonus. By completing this you'll obtain Temple coins, by holding any of these, the cost of minting gods will be reduced to 0.40 tez."
            :canMint="this.main.canMintGods"
            :mintCost="this.main.GOD_COST"
            :isReducedCost="this.main.godDiscount"
            :mintReducedCost="this.main.GOD_REDUCED_COST"
            :disableButton="globalDisabled"
            @minting="globalDisabled = true"
            @success="mintSuccess()"
            @error="globalDisabled = false"
          />
          <coinMint
            class="ud-mb-2"
            ref="m2"
            :ownedQty="main.ownTemples"
            :totalQty="main.totalTemples"
            mintFunction="2"
            mintTitle="Temples"
            mintType="TEMPLE"
            mintImage="icon_temple.png"
            mintDescription="There are 10 different Temple coins to mint. Temples are used to complete parts of the <a to='/cursus-honorum'>Cursus Honorum</a> which lead to the 
          Twelve Caesars. Minting Temples requires the completion of <i>The Apotheosis</i> bonus which gives you the <i>Augustus - Templum Divi Iuli</i> coin.
          The cost of minting gods will be reduced to 0.40 tez."
            :canMint="this.main.canMintTemples"
            :mintCost="this.main.TEMPLE_COST"
            :isReducedCost="false"
            :disableButton="globalDisabled"
            @minting="globalDisabled = true"
            @success="mintSuccess()"
            @error="globalDisabled = false"
          />
          <coinMint
            class="ud-mb-2"
            ref="m3"
            :ownedQty="main.ownCivilWars"
            :totalQty="main.totalCivilWars"
            mintFunction="3"
            mintTitle="Civil Wars"
            mintType="CIVIL WARS"
            mintImage="icon_swords.png"
            mintDescription="There are 17 different Civil War coins to mint. Civil Wars are also used to complete many of the <a to='/cursus-honorum'>Cursus Honorum</a> which lead to the 
          Twelve Caesars. Minting Civil War coins requires the completion of <i>The Civil Wars</i> bonus which gives you the <i>Vespasian - Simpulum & Lituus</i> coin."
            :canMint="this.main.canMintCivilWars"
            :mintCost="this.main.CIVILWARS_COST"
            :isReducedCost="false"
            :disableButton="globalDisabled"
            @minting="globalDisabled = true"
            @success="mintSuccess()"
            @error="globalDisabled = false"
          />
        </div>
      </div>
    </section>
    <div>
      <h2
        class="
          ud-text-black
          dark:ud-text-white
          ud-font-bold ud-text-3xl
          sm:ud-text-4xl
          md:ud-text-[45px]
          ud-mb-8 ud-text-center
        "
        style="font-family: 'RomanSD'"
      >
        Bonus Coins
      </h2>
      <p
        class="
          ud-text-sm ud-text-body-color ud-leading-relaxed
          md:ud-leading-relaxed
          ud-text-center ud-mx-20 ud-mb-10
        "
      >
        Bonus coins are required to get access to minting god, temple and civil
        war coins. For instance, in order to be able to mint god coins you need
        to claim the <i>Minting Gods</i> which will give you the Julius Caesar
        Pontifex Maximus coin. You need to hold this coins in your balance to
        get advantage of its benefits. Bonus coins also provide bonuses that
        reduce the cost of minting legions and gods which are the base for most
        of the <i>Cursus Honorum</i> base requirements.
      </p>

      <div
        class="
          ud-flex
          ud-flex-row
          ud-flex-wrap
          ud-content-center
          ud-justify-items-center
          ud-justify-center
          ud-pb-[50px]
        "
      >
        <TClaimable
          ref="c0"
          :claimableType="0"
          :claimID="0"
          claimableTitle="Minting Gods"
          claimableBonus="<span style='color: #FBB03F; font-weight: normal;'>Allows the minting of God coins</span>"
          claimableDesc="The Pontifex Maximus was the religious head of the Roman state and was in charge, among other tasks, of the Pax Deorum (peace with the gods). Etymologically 'pontifex' comes to mean bridge builder, applied in the sense of propper communication between the people of Rome and the gods, and the correct performance of religious rites and customs. Julius Caesar became supreme pontiff in 63 BC and with Augustus, the first emperor, it would become a position associated with the emperor who was at the same time Pontifex Maximus."
          :disableButton="globalDisabled"
          @claiming="globalDisabled = true"
          @success="globalDisabled = false"
          @error="globalDisabled = false"
        />
        <TClaimable
          ref="c1"
          :claimableType="0"
          :claimID="1"
          claimableTitle="Castra Praetoria"
          claimableBonus="<span style='color: #FBB03F; font-weight: normal;'>Reduces legion minting cost to <strong>0.25 tez</strong></span>"
          claimableDesc="Created by Augustus, the Praetorian Guard was an elite unit divided into nine cohorts spread across the Italian peninsula. In the times of his successor Tiberius, the pressure of his leader Lucius Aelius Sejanus managed to get the different cohorts of the Praetorian guard to meet in a fort built on the walls of Rome under the excuse of protecting it from its political rivals. From the Praetorian Camp the fall of rival emperors was decided, reaching the murder as in the case of Caligula or Galba, and the rise to power of friendly emperors such as Claudius or Otho."
          :disableButton="globalDisabled"
          @claiming="globalDisabled = true"
          @success="claimSuccess()"
          @error="globalDisabled = false"
        />
        <TClaimable
          ref="c2"
          :claimableType="0"
          :claimID="2"
          claimableTitle="The Apotheosis"
          claimableBonus="<span style='color: #FBB03F; font-weight: normal;'>Allows minting of temple coins <br> Reduces Gods minting cost to <strong>0.40 tez</strong></span>"
          claimableDesc="Roman temples were one of the most important buildings in the city of Rome, they adorned its forum and had a religious and political function, worshiping the protective gods such as Jupiter and, since Augustus built a temple in Rome dedicated to Divus Julius (Julius Caesar deified), to the deified emperors. After his death, Augustus was also proclaimed divus and henceforth all emperors could be considered gods after their death."
          :disableButton="globalDisabled"
          @claiming="globalDisabled = true"
          @success="claimSuccess()"
          @error="globalDisabled = false"
        />
        <TClaimable
          ref="c3"
          :claimableType="0"
          :claimID="3"
          claimableTitle="The Civil Wars"
          claimableBonus="<span style='color: #FBB03F; font-weight: normal;'>Allows minting of Civil Wars coins</span>"
          claimableDesc="In March of the year 68 AC, Gaius Julius Vindex, propraetor of Gaul Lugdunense, rose up against Nero. Otho from Lusitania supported the uprising and encouraged Selvio Sulpicio Galba, governor of Hispania Tarraconense, to join them. The legions of Germany remained loyal to the emperor and Verginio Rufus defeated Vindex, but the fuse of the rebellion had lit and the Praetorian troops and the Senate of Rome proclaimed, on June 9, Galba as the successor of Nero, who was forced to commit suicide. The conflict did not end with the ascent of Galba to the imperial throne and the civil wars caused that during the year 69 AC, 4 emperors (Galba, Otho, Vitellius and Vespasian) would sit on the imperial throne."
          :disableButton="globalDisabled"
          @claiming="globalDisabled = true"
          @success="claimSuccess()"
          @error="globalDisabled = false"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.backImgSaturn {
  background-color: #1d2144;
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/backgrounds/temple_of_saturn.png");
}

.backImgMints {
  /*background-color: #02072b;*/
  background-color: #1d2144;
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/backgrounds/rome_mints.png");
}

.backImg {
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/caesars/8.jpeg");
  background-color: #0d133e;
  background-blend-mode: overlay;
  background-size: cover;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import coinMint from "../components/coinMint.vue";
import TClaimable from "../components/TClaimable.vue";
import claimPrize from "../components/claimPrize.vue";
import moment from "moment";

export default {
  name: "TMinter",
  components: {
    coinMint,
    TClaimable,
    claimPrize,
  },
  watch: {
    notableMints: {
      handler() {
        // pass
      },
      immediate: true,
      deep: true,
    },
  },
  data: () => ({
    main: undefined,
    globalDisabled: false,
    notableMints: [],
    notableMintsLoaded: false,
  }),
  methods: {
    getHistoricalDate(t) {
      return new moment(t).format("LLLL");
    },
    getNotableMints() {
      let x = Math.floor(Math.random() * 1e20 + 1);
      fetch("latest_aureus.json?r=" + x).then((response) => {
        response.json().then((data) => {
          this.notableMints = data;
          this.notableMintsLoaded = true;
        });
      });
    },
    async claimSuccess() {
      window.gtag('event', 'claim_success', {
        'app_name': 'Taezars',
        'screen_name': 'Minter'
      });
      await this.main.getBalances();
      this.main.setBalance();
      this.$forceUpdate();
      this.$nextTick(() => {
        this.globalDisabled = false;
        Object.keys(this.$refs).forEach((k) => {
          this.$refs[k].setData();
        });
      });
    },
    async mintSuccess() {
      window.gtag('event', 'mint_success', {
        'app_name': 'Taezars',
        'screen_name': 'Minter'
      });

      await this.main.getBalances();
      this.main.setBalance();
      this.$forceUpdate();
      this.$nextTick(() => {
        this.globalDisabled = false;
        Object.keys(this.$refs).forEach((k) => {
          this.$refs[k].setData();
        });
      });
    },
  },
  created: async function () {
    window.scrollTo(0, 0);
    this.main = this.$router.app.$children[0];
    await this.main.getBalances();
    this.$forceUpdate();
    this.getNotableMints();
    this.main.notableMintsTimer = setInterval(() => {
      console.log("Updating notable mints");
      this.getNotableMints();
    }, 10000);
  },
  mounted: function () {
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.main.notableMintsTimer);
    next();
  },
  beforeRouteUpdate() {
    this.main = this.$router.app.$children[0];
    this.main.getBalances();
    this.setBalance();
    this.$forceUpdate();
  },
};
</script>
