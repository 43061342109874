<template>
  <div>    
    <div class="ud-flex ud-flex-col ud-items-center">
      <div class="ud-flex ud-flex-row ud-flex-wrap">
        <!-- GIVE -->
        <div class="ud-flex ud-flex-col">
          <div
            class="
              ud-max-w-[380px]
              ud-text-white
              ud-text-center
              ud-font-bold
              ud-text-xl
              ud-border-b-0
              ud-rounded-t-lg
              ud-flex
              ud-flex-col
              ud-border-gray-800
              ud-p-4
              ud-m-1
              ud-mb-0
              ud-border
            "
            style="
                /* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "          
          >
            GIVE
          </div>
          <div
            class="
              ud-min-h-[500px]
              ud-max-w-[380px]
              ud-border
              ud-border-t-0
              ud-flex
              ud-flex-col
              ud-rounded-b-lg
              ud-border-gray-800
              ud-p-4
              ud-m-1
              ud-mt-0
            "
            style="background-color: #1d2144"
          >
            <h3
              class="
                ud-text-white ud-mb-1 ud-w-full ud-border-b ud-border-b-gray-500
              "
            >
              Coins
            </h3>
            <p class="ud-text-body-color ud-text-sm ud-mb-3">
              Select the coins you would like to offer in a trade. You can
              select up to five coins. These coins will be removed from your
              account balance while the trade offer remains active or is
              accepted.
            </p>
            <div
              v-for="(o, idx) in offerCombos"
              v-bind:key="o.id"
              class="ud-flex ud-mb-2 ud-w-full"
            >
              <vNumericUpDown
                class="ud-mr-1 ud-min-w-[50px] ud-max-h-[32px] noselect"
                v-if="o.selected > -1"
                :value.sync="o.qty"
                :max="o.bal"
                :min="1"
                :ref="'offerN' + idx"
              />
              <TSelect
                class="ud-w-[380px] ud-flex-grow-1"
                :disabled="creatingOffer"
                :ref="'offerAC' + idx"
                :els="getOfferNames(idx)"
                v-bind:selected.sync="o.selected"
                @change="
                  (sel, token_id, balance) =>
                    comboUpdateOffer(o, sel, token_id, balance)
                "
                :showBalance="true"
              ></TSelect>
              <div
                class="
                  ud-ml-2
                  ud-min-w-[10px]
                  ud-max-h-[40px]
                  ud-flex
                  ud-items-center
                  ud-text-gray-300
                  hover:ud-text-gray-100
                  ud-cursor-pointer
                "
                v-if="
                  offerCombos.length > 0 &&
                  o.id == offerCombos.length - 1 &&
                  offerCombos[0].selected > -1
                "
                @click="removeOfferCombo(o.id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ud-h-5 ud-w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>

              <div
                class="
                  ud-ml-2
                  ud-min-w-[10px]
                  ud-max-h-[40px]
                  ud-flex
                  ud-items-center
                  ud-text-gray-300
                  hover:ud-text-gray-100
                  ud-cursor-pointer
                "
                v-if="
                  offerCombos.length < COMBO_LIMIT &&
                  o.id == offerCombos.length - 1 &&
                  o.selected > -1
                "
                @click="addOfferCombo()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ud-h-5 ud-w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <h3
              class="
                ud-text-white
                ud-mb-1
                ud-mt-2
                ud-w-full
                ud-border-b
                ud-border-b-gray-500
              "
            >
              Tez
            </h3>
            <p class="ud-text-body-color ud-text-sm ud-mb-3">
              Choose the amount of tez you would like to offer in your trade.
              The amount of tez offered will be deducted from your Tezos account
              while the trade remains active or is completed. All token sells are subject to a non-refundable 20%
              commission that will be deducted when/if the trade completes.
            </p>
            <div class="ud-flex ud-w-full">
              <input
                class="
                  ud-w-[100px]
                  ud-bg-blue-800
                  ud-text-white
                  ud-outline-none
                  ud-rounded-md
                  ud-text-md
                  ud-p-1
                  ud-pr-3
                  ud-text-right
                "
                type="number"
                :min="0"
                v-model="offerTez"
                :disabled="askTez > 0"
                placeholder="0"
                @input="isOrderValid()"
              />
              <img
                class="ud-mt-[4px] ud-ml-1 ud-w-6 ud-h-6"
                src="../assets/tezos-logo.svg"
              />
            </div>
          </div>
        </div>
        <!-- ASK -->
        <div class="ud-flex ud-flex-col">
          <div
            class="
              ud-max-w-[380px]
              ud-text-white
              ud-text-center
              ud-font-bold
              ud-text-xl
              ud-border-b-0
              ud-rounded-t-lg
              ud-flex
              ud-flex-col
              ud-border
              ud-border-gray-800
              ud-p-4
              ud-m-1
              ud-mb-0
            "
            style="
                /* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "
          >
            RECEIVE
          </div>
          <div
            class="
              ud-min-h-[500px]
              ud-max-w-[380px]
              ud-border
              ud-border-t-0
              ud-flex
              ud-flex-col
              ud-rounded-b-lg
              ud-border-gray-800
              ud-p-4
              ud-m-1
              ud-mt-0              
            "
            style="background-color: #1d2144"
          >
            <h3
              class="
                ud-text-white ud-mb-1 ud-w-full ud-border-b ud-border-b-gray-500
              "
            >
              Coins
            </h3>
            <p class="ud-text-body-color ud-text-sm ud-mb-3">
              Select the coins you would like to receive in exchange for your
              offer. You can select up to five coins from all the Taezars
              collection. <br /><br />
            </p>
            <div
              v-for="(o, idx) in askCombos"
              v-bind:key="o.id"
              class="ud-flex ud-mb-2 ud-w-full"
            >
              <vNumericUpDown
                class="ud-mr-1 ud-min-w-[50px] ud-max-h-[32px] noselect"
                v-if="o.selected > -1"
                :value.sync="o.qty"
                :max="100"
                :min="1"
                :ref="'askN' + idx"
              />
              <TSelect
                class="ud-w-[380px] ud-flex-grow-1"
                :disabled="creatingOffer"
                :ref="'askAC' + idx"
                :els="getAskNames(idx)"
                v-bind:selected.sync="o.selected"
                @change="
                  (sel, token_id, balance) =>
                    comboUpdateAsk(o, sel, token_id, balance)
                "
              ></TSelect>
              <div
                class="
                  ud-ml-2
                  ud-min-w-[10px]
                  ud-max-h-[40px]
                  ud-flex
                  ud-items-center
                  ud-text-gray-300
                  hover:ud-text-gray-100
                  ud-cursor-pointer
                "
                v-if="
                  askCombos.length > 0 &&
                  o.id == askCombos.length - 1 &&
                  askCombos[0].selected > -1
                "
                @click="removeAskCombo(o.id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ud-h-5 ud-w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>

              <div
                class="
                  ud-ml-2
                  ud-min-w-[10px]
                  ud-max-h-[40px]
                  ud-flex
                  ud-items-center
                  ud-text-gray-300
                  hover:ud-text-gray-100
                  ud-cursor-pointer
                "
                v-if="
                  askCombos.length < COMBO_LIMIT &&
                  o.id == askCombos.length - 1 &&
                  o.selected > -1
                "
                @click="addAskCombo()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ud-h-5 ud-w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <h3
              class="
                ud-text-white
                ud-mb-1
                ud-mt-2
                ud-w-full
                ud-border-b
                ud-border-b-gray-500
              "
            >
              Tez
            </h3>
            <p class="ud-text-body-color ud-text-sm ud-mb-3">
              Choose the amount of tez you would like to receive in exchange for
              your coins. All token sells are subject to a non-refundable 20%
              commission that will be deducted when/if the trade completes.<br/><br/><br/>
            </p>
            <div class="ud-flex ud-w-full">
              <input
                class="
                  ud-w-[100px]
                  ud-bg-blue-800
                  ud-text-white
                  ud-outline-none
                  ud-rounded-md
                  ud-text-md
                  ud-p-1
                  ud-pr-3
                  ud-text-right
                "
                type="number"
                :min="0"
                v-model="askTez"
                :disabled="offerTez > 0"
                placeholder="0"
                @input="isOrderValid()"
              />
              <img
                class="ud-mt-[4px] ud-ml-1 ud-w-6 ud-h-6"
                src="../assets/tezos-logo.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="ud-min-w-[320px] ud-pr-2" >
        <button v-if="!toastVisible"
          :disabled="creatingOffer || !orderValid"
          @click="submitOffer()"
          class="
            ud-m-1 ud-mt-2
            disabled:ud-opacity-40
            ud-items-center
            ud-w-full
            ud-justify-center
            ud-block-inline
            ud-text-base
            ud-font-bold
            ud-text-white
            ud-bg-primary
            ud-py-3
            ud-px-8
            hover:ud-shadow-signUp hover:ud-bg-opacity-90
            ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
          "
        >
          <div v-if="!creatingOffer">Create Offer&nbsp;<span class="ud-text-red-400">{{main.MARKET_FEE}}&nbsp;tez</span></div>

          <div v-else>
            <svg
              class="
                ud-inline-block ud-animate-spin
                ud-ml--1
                ud-mr-1 ud-mb-[2px] ud-h-5 ud-w-5 ud-text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="ud-opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="ud-opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Creating offer...
          </div>
        </button>
        <t-toast class="ud-transition-all ud-m-1 ud-mt-2 ud-h-12
            disabled:ud-opacity-40
            ud-items-center
            ud-w-full
            ud-justify-center
            ud-block-inline" v-show="toastVisible" label="Trade offer successfully created."></t-toast>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>


<script>
import TSelect from "./ui/TSelect.vue";
import TToast from "./ui/TToast.vue";
import vNumericUpDown from "./ui/vNumericUpDown.vue";
import { OpKind } from "@taquito/taquito";
import {BigDecimal} from "bigdecimal";

export default {
  name: "TTrade",
  components: {
    TSelect,
    vNumericUpDown,
    TToast
  },
  data: () => ({
    toastVisible: false,
    allTokens: [],
    tab: "offer",
    error: undefined,
    showError: false,
    creatingOffer: false,
    offerCreated: false,
    COMBO_LIMIT: 5,
    confirmDialog: false,
    orderValid: false,
    offerCombos: [
      {
        id: 0,
        selected: -1,
        qty: 1,
        bal: 1,
        token_id: undefined,
      },
    ],
    askCombos: [
      {
        id: 0,
        selected: -1,
        qty: 1,
        token_id: undefined,
      },
    ],
    offers: [],
    offerNames: [],
    offerTez: 0,
    asks: [],
    askNames: [],
    askTez: 0,
  }),
  props: {
    balances: {},
  },
  created() {
    this.main = this.$router.app.$children[0];
    this.main.getBalances();
    this.main.userBalance.forEach((t) => {
      var p = {};
      p["token_id"] = t.token_id;
      p["name"] = t.name;
      this.asks.push(p);
      this.askNames.push(t.name);
      if (t.balance > 0) {
        p = {};
        p["token_id"] = t.token_id;
        p["name"] = t.name;
        p["bal"] = t.balance;
        this.offers.push(p);
        this.offerNames.push(t.name);
      }
    });
  },
  mounted() {    
    
  },
  updated() {},
  methods: {
    showToast() {
      this.toastVisible = true;
      setTimeout( () => { this.toastVisible = false; }, 3000);
    },
    getOrderBook(only_own) {
      var orderBook = [];
      this.main.orderBook.forEach((o) => {
        var entry = {};
        entry["offer_amount"] = o.offer_amount;
        entry["ask_amount"] = o.ask_amount;
        entry["ask_tokens"] = [];
        o.ask_tokens.forEach((a) => {
          var ask = {};
          ask["key"] = a.key;
          ask["token_id"] = a.token_id;
          ask["name"] = this.getTokenName(a.token_id);
          ask["rarity"] = this.getTokenRarity(a.token_id);
          ask["amount"] = a.amount;
          entry["ask_tokens"].push(ask);
        });

        entry["offer_tokens"] = [];
        o.offer_tokens.forEach((a) => {
          var offer = {};
          offer["key"] = a.key;
          offer["token_id"] = a.token_id;
          offer["name"] = this.getTokenName(a.token_id);
          offer["rarity"] = this.getTokenRarity(a.token_id);
          offer["amount"] = a.amount;
          entry["offer_tokens"].push(offer);
        });
        if (only_own) {
          if (o.owner == this.main.activeAccount) orderBook.push(entry);
        } else orderBook.push(entry);
      });
      return orderBook;
    },
    gettoken_id(name) {
      var ret = undefined;
      //console.log("looking up " + name + "...");
      this.main.userBalance.some((b) => {
        if (b.name == name) {
          //console.log("found " + b.token_id);
          ret = b.token_id;
          return;
        }
      });
      return ret;
    },
    getTokenName(id) {
      var ret = undefined;
      //console.log("looking up " + id + "...");
      this.main.userBalance.some((b) => {
        if (b.token_id == id) {
          //console.log("found " + b.name);
          ret = b.name;
          return;
        }
      });
      return ret;
    },
    getTokenRarity(id) {
      var ret = undefined;
      //console.log("looking up " + id + "...");
      this.main.userBalance.some((b) => {
        if (b.token_id == id) {
          ret = b.rarity;
          return;
        }
      });
      return ret;
    },
    getOfferNames(idx) {
      var usedTokens = [];

      this.offerCombos.forEach((c) => {
        if (c.id != idx) usedTokens.push(c.token_id);
      });

      //return this.offers;

      return {
        available: this.offers,
        taken: usedTokens,
      };
    },
    getAskNames(idx) {
      var usedTokens = [];

      this.askCombos.forEach((c) => {
        if (c.id != idx) usedTokens.push(c.token_id);
      });

      //return this.asks;

      return {
        available: this.asks,
        taken: usedTokens,
      };
    },
    comboUpdateOffer(o, sel, token_id, balance) {
      //console.log(o, sel, token_id, balance);
      this.$set(o, "selected", sel);
      this.$set(o, "token_id", token_id);
      this.$set(o, "bal", balance);
      this.$set(o, "qty", 1);
      var ref = "offerN" + o.id.toString();
      if (this.$refs[ref]) {
        if (this.$refs[ref][0]) this.$refs[ref][0].clear();
      }
      this.isOrderValid();
      //console.log("COMBOS");
      //console.log(this.offerCombos);
    },
    comboUpdateAsk(o, sel, token_id, balance) {
      //console.log(o, sel, token_id, balance);
      this.$set(o, "selected", sel);
      this.$set(o, "token_id", token_id);
      this.$set(o, "bal", balance);
      this.$set(o, "qty", 1);
      var ref = "askN" + o.id.toString();
      if (this.$refs[ref]) {
        if (this.$refs[ref][0]) this.$refs[ref][0].clear();
      }
      this.isOrderValid();
    },
    isOrderValid() {
      this.offerCreated = false;
      this.error = undefined;
      this.showError = false;
      if (this.askTez < 0) this.askTez = 0;
      if (this.offerTez < 0) this.offerTez = 0;

      this.offers.forEach((a) => {
        this.offerCombos.forEach((b) => {
          if (a.name == b.selected) {
            b.bal = a.bal;
          }
        });
      });

      var isValid = false;

      /// Offer token for tez
      if (
        this.offerCombos.length > 0 &&
        this.offerCombos[0].selected > -1 &&
        this.askTez > 0
      )
        isValid = true;

      /// Offer token for token
      if (
        this.offerCombos.length > 0 &&
        this.offerCombos[0].selected > -1 &&
        this.askCombos.length > 0 &&
        this.askCombos[0].selected > -1
      )
        isValid = true;

      /// Offer tez for token
      if (
        this.askCombos.length > 0 &&
        this.askCombos[0].selected > -1 &&
        this.offerTez > 0
      )
        isValid = true;

      this.orderValid = isValid;
    },
    addOfferCombo() {
      if (this.offerCombos.length < this.COMBO_LIMIT) {
        this.offerCombos.push({
          id: this.offerCombos.length,
          selected: -1,
          qty: 1,
          token_id: undefined,
        });
      }
      /// set focus
      //var ref = "offerAC" + (this.offerCombos.length - 1).toString();
      this.isOrderValid();
    },
    removeOfferCombo(cid) {
      if (this.offerCombos.length == 1) {
        this.$set(this.offerCombos[0], "selected", -1);
        var ref = "offerAC" + cid.toString();
        this.$refs[ref][0].clear();
      } else {
        this.offerCombos.pop();
      }
      this.isOrderValid();
    },
    addAskCombo() {
      if (this.askCombos.length < this.COMBO_LIMIT) {
        this.askCombos.push({
          id: this.askCombos.length,
          selected: -1,
          token_id: undefined,
          qty: 1,
        });
      }

      this.isOrderValid();
    },
    removeAskCombo(cid) {
      if (this.askCombos.length == 1) {
        this.$set(this.askCombos[0], "selected", -1);
        var ref = "askAC" + cid.toString();
        this.$refs[ref][0].clear();
      } else {
        this.askCombos.pop();
      }
      this.isOrderValid();
    },
    comboDisabled(c) {
      if (c.length == this.COMBO_LIMIT - 1) return true;
      return false;
    },
    async submitOffer() {
      this.creatingOffer = true;
      this.error = undefined;
      this.showError = false;
      this.confirmDialog = false;
      var offer = {};
      offer["offer_amount"] = this.offerTez ? this.offerTez : 0;
      offer["ask_amount"] = this.askTez ? this.askTez : 0;

      //console.log("offer combos...");
      offer["offer_tokens"] = [];
      this.offerCombos.forEach((o) => {
        //console.log(o);
        if (o.selected > -1) {
          var w = {};
          (w["token_id"] = o.token_id), (w["amount"] = o.qty);
          offer["offer_tokens"].push(w);
        }
      });

      offer["ask_tokens"] = [];
      this.askCombos.forEach((o) => {
        if (o.selected > -1) {
          var w = {};
          (w["token_id"] = o.token_id), (w["amount"] = o.qty);
          offer["ask_tokens"].push(w);
        }
      });

      this.main.tezos.setWalletProvider(this.main.wallet);

      const contract = await this.main.tezos.wallet.at(
        this.main.CONTRACT_ADDRESS
      );

      const market = await this.main.tezos.wallet.at(this.main.MARKET_ADDRESS);

      /// Operator for offer tokens

      var operators = [];
      offer["offer_tokens"].forEach((t) => {
        var v = {};
        v["add_operator"] = {};
        v.add_operator["owner"] = this.main.activeAccount;
        v.add_operator["operator"] = this.main.MARKET_ADDRESS;
        v.add_operator["token_id"] = t.token_id;
        operators.push(v);
      });

      const batch = await this.main.tezos.wallet.batch([
        {
          kind: OpKind.TRANSACTION,
          ...contract.methods.update_operators(operators).toTransferParams(),
        },
        {
          kind: OpKind.TRANSACTION,
          ...market.methods
            .add(
              1e6 * offer.ask_amount,
              offer.ask_tokens,
              1e6 * offer.offer_amount,
              offer.offer_tokens
            )
            .toTransferParams(),
          amount: new BigDecimal(offer.offer_amount).add(new BigDecimal(this.main.MARKET_FEE)).doubleValue(),
        },
      ]);

      batch
        .send()
        .then(async (op) => {
          //console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(1);
          return op.opHash;
        })
        .then(async (hash) => {
          console.log("OK: " + hash);
          this.main.getOrders();
          this.main.getBalances();
          this.main.setBalance();
          this.creatingOffer = false;
          this.offerCreated = true;
          this.clearOffer();
          this.showToast();
          this.$emit("success");
        })
        .catch((error) => {
          this.showError = true;
          this.error = error;
          this.creatingOffer = false;
          //console.log(error);
          console.log("Error: " + JSON.stringify(error, null, 2));
        });
    },
    clearOffer() {
      this.showError = false;
      this.error = undefined;
      this.creatingOffer = false;
      this.askTez = undefined;
      this.offerTez = undefined;
      this.offerCombos.splice(1, this.offerCombos.length);
      this.offerCombos[0].selected = -1;
      this.askCombos.splice(1, this.askCombos.length);
      this.askCombos[0].selected = -1;
      this.$refs["askAC0"][0].clear();
      this.$refs["offerAC0"][0].clear();
      this.orderValid = false;
    },
  },
};
</script>