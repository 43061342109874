<template>
  <div id="app" :class="{ 'ud-overflow-hidden': showWertModal}">
    <div
      v-show="showWertModal"
      @click="closeWertModal()"
      class="
        ud-fixed
        ud-text-6xl
        ud-z-50
        ud-bg-gray-600
        ud-inset-0
        ud-bg-opacity-50
        ud-overflow-y-hidden
        ud-h-full
        ud-w-full
        ud-flex        
        xs:ud-p-4
        ud-px-0
        ud-justify-center
        !ud-overscroll-contain                   
      "
      id="my-modal"
    >
   
      <div @click.stop @close="showWertModal = false" class="!ud-overscroll-contain ud-z-50 ud-mt-[95px] ud-mb-[40px] ud-min-w-[370px] ud-h-[560px]" id="wert-widget"></div>
    
     
    </div>
    <THeader
      :connected="walletConnected"
      :address="getActiveAccount()"
      @connect="connectWallet()"
      @disconnect="disconnectWallet()"
      @topup="topUpWallet()"
    />

    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
    <TFooter />
    <TBackTop v-if="!showWertModal"/>
  </div>
</template>
<style scoped>
.noscroll {
  overflow: hidden;
}
</style>

<script>
import { BeaconWallet } from "@taquito/beacon-wallet";
import { TezosToolkit } from "@taquito/taquito";
import { NetworkType } from "@airgap/beacon-sdk";
//import { InMemorySigner } from "@taquito/signer";
import * as numeral from "numeral";
import tokens from "../contracts/data/tokens.json";
import chdata from "../contracts/data/cursus_honorum.json";
import obtainedfrom from "../contracts/data/obtained_from.json";
import aureusdata from "../contracts/data/aureus.json";
import bonusdata from "../contracts/data/bonus_coins.json";
import TaezarsConfig from "../config-dev.json";
import THeader from "./components/THeader.vue";
import TFooter from "./components/TFooter.vue";
import TBackTop from "./components/TBackTop.vue";

export default {
  name: "App",
  components: {
    THeader,
    TFooter,
    TBackTop,
  },
  data: () => ({
    tokenData: tokens,
    chData: chdata,
    obtainedFrom: obtainedfrom,
    aureusData: aureusdata,
    bonusData: bonusdata,
    CDN: TaezarsConfig.CDN,
    CONTRACT_ADDRESS: TaezarsConfig.contract,
    MARKET_ADDRESS: TaezarsConfig.market_contract,
    TESSERAE_ADDRESS: TaezarsConfig.tesserae_contract,
    LEGION_COST: TaezarsConfig.legion_cost,
    LEGION_REDUCED_COST: TaezarsConfig.legion_reduced_cost,
    GOD_COST: TaezarsConfig.god_cost,
    GOD_REDUCED_COST: TaezarsConfig.god_reduced_cost,
    TEMPLE_COST: TaezarsConfig.temple_cost,
    CIVILWARS_COST: TaezarsConfig.civilwars_cost,
    GOD_ALLOW_TOKEN: TaezarsConfig.god_allow_token,
    TEMPLE_ALLOW_TOKEN: TaezarsConfig.temple_allow_token,
    CIVILWAR_ALLOW_TOKEN: TaezarsConfig.civilwar_allow_token,
    LEGION_REDUCED_COST_TOKEN: TaezarsConfig.legion_reduced_cost_token,
    GOD_REDUCED_COST_TOKEN: TaezarsConfig.god_reduced_cost_token,
    MARKET_FEE: TaezarsConfig.market_fee,
    NETWORK_TYPE: TaezarsConfig.network_type,
    RPCURL: TaezarsConfig.RPC,
    wallet: undefined,
    tezos: new TezosToolkit(TaezarsConfig.RPC),
    lambdaView: TaezarsConfig.lambda_view,
    walletConnected: false,
    walletBalance: 0,
    activeAccount: undefined,
    userBalance: [],
    permissions: undefined,
    drawer: null,
    offers: [],
    uniqueCoins: 0,
    completePct: 0,
    totalLegions: 0,
    ownLegions: 0,
    legionsCompletePct: 0,
    totalGods: 0,
    ownGods: 0,
    godsCompletePct: 0,
    totalTemples: 0,
    ownTemples: 0,
    totalAureus: 0,
    ownAureus: 0,
    templesCompletePct: 0,
    totalBonus: 0,
    ownBonus: 0,
    totalCivilWars: 0,
    ownCivilWars: 0,
    civilWarsCompletePct: 0,
    bonusCompletePct: 0,
    totalMissions: 0,
    ownMissions: 0,
    missionsCompletePct: 0,
    canMintGods: false,
    canMintTemples: false,
    canMintCivilWars: false,
    legionDiscount: false,
    godDiscount: false,
    prize_given: false,
    prize: 0,
    canClaimPrize: false,
    updatesPaused: false,
    updateTimer: undefined,
    rankingUpdateTimer: undefined,
    notableMintsTimer: undefined,
    rollsUpdateTimer: undefined,
    showWertModal: false,
  }),
  methods: {
    async wait(ms_to_wait) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(), ms_to_wait);
      });
    },
    pauseUpdates() {
      this.updatesPaused = true;
    },
    resumeUpdates() {
      this.updatesPaused = false;
    },
    initTemplate() {
      // ======= Sticky
      window.onscroll = function () {
        const ud_header = document.querySelector(".header");
        const sticky = ud_header.offsetTop;

        if (window.pageYOffset > sticky) {
          ud_header.classList.add("sticky");
        } else {
          ud_header.classList.remove("sticky");
        }

        // show or hide the back-top-top button
        const backToTop = document.querySelector(".back-to-top");
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          backToTop.style.display = "flex";
        } else {
          backToTop.style.display = "none";
        }
      };

      // ===== Faq accordion
      const faqs = document.querySelectorAll(".single-faq");
      faqs.forEach((el) => {
        el.querySelector(".faq-btn").addEventListener("click", () => {
          el.querySelector(".icon").classList.toggle("ud-rotate-180");
          el.querySelector(".faq-content").classList.toggle("ud-hidden");
        });
      });

      // ====== scroll top js
      function scrollTo(element, to = 0, duration = 500) {
        const start = element.scrollTop;
        const change = to - start;
        const increment = 20;
        let currentTime = 0;

        const animateScroll = () => {
          currentTime += increment;

          const val = Math.easeInOutQuad(currentTime, start, change, duration);

          element.scrollTop = val;

          if (currentTime < duration) {
            setTimeout(animateScroll, increment);
          }
        };

        animateScroll();
      }

      Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      document.querySelector(".back-to-top").onclick = () => {
        scrollTo(document.documentElement);
      };

      // ==== for menu scroll
      const pageLink = document.querySelectorAll(".menu-scroll");

      pageLink.forEach((elem) => {
        elem.addEventListener("click", (e) => {
          e.preventDefault();
          document.querySelector(elem.getAttribute("href")).scrollIntoView({
            behavior: "smooth",
            offsetTop: 1 - 60,
          });
        });
      });

      // section menu active
      function onScroll() {
        /*const sections = document.querySelectorAll(".menu-scroll");
        const scrollPos =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;

        for (let i = 0; i < sections.length; i++) {
          const currLink = sections[i];
          const val = currLink.getAttribute("href");
          console.log("VAL: " + val);
          const refElement = document.querySelector(val);
          const scrollTopMinus = scrollPos + 73;
          if (
            refElement.offsetTop <= scrollTopMinus &&
            refElement.offsetTop + refElement.offsetHeight > scrollTopMinus
          ) {
            document.querySelector(".menu-scroll").classList.remove("active");
            currLink.classList.add("active");
          } else {
            currLink.classList.remove("active");
          }
        }*/
      }

      window.document.addEventListener("scroll", onScroll);
    },
    closeWertModal() {
      this.showWertModal = false;   
      document.body.classList.toggle('ud-overflow-hidden', this.showWertModal);

    },
    topUpWallet() {  
      
      let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");

    
      navbarToggler.classList.toggle("navbarTogglerActive");
      navbarCollapse.classList.toggle("ud-hidden");
   

      this.showWertModal = true;   
      document.body.classList.toggle('ud-overflow-hidden', this.showWertModal);
      const wertWidget = new window.WertWidget({
          partner_id: "01GF0KN9VTDZJ6TNT5WSY2RHZX",
          container_id: "wert-widget",
          origin: "https://widget.wert.io",
          theme: "dark",
          commodities: "XTZ:Tezos",
          lang: "en",
          address: this.activeAccount,
          color_background: "#1d2144",
        });
        wertWidget.mount();
      
    },
    initWallet: async function () {
      return new Promise((resolve, reject) => {
        if (this.NETWORK_TYPE == "CUSTOM") {
          if (!this.wallet)
            this.wallet = new BeaconWallet({
              name: "Taezars",
              rpcUrl: TaezarsConfig.RPC,
            });
        } else {
          if (!this.wallet)
            this.wallet = new BeaconWallet({
              name: "Taezars",
            });
        }

        try {
          this.wallet.getPKH().then((address) => {
            this.activeAccount = address;
            console.log("ACTIVE ACCOUNT: " + this.activeAccount);
            this.walletConnected = true;
            this.setBalance();
            this.getBalances();
            this.getOrders();
            this.$forceUpdate();

            clearInterval(this.updateTimer);

            this.updateTimer = setInterval(() => {
              //if (!this.updatesPaused) {

              //console.log("Updating...");
              this.setBalance();
              this.getBalances();
              this.getOrders();
              this.$forceUpdate();
            }, 30000);

            resolve();
          });
        } catch {
          reject();
          console.log("No active wallet");
        }
      });
    },
    connectWallet: async function () {
      window.gtag("event", "connect_wallet", {
        app_name: "Taezars",
        screen_name: "Home",
      });

      try {
        var network_type = "CUSTOM";

        switch (this.NETWORK_TYPE) {
          case "CUSTOM":
            network_type = NetworkType.CUSTOM;
            break;
          case "GHOSTNET":
            network_type = NetworkType.GHOSTNET;
            break;
          case "MAINNET":
            network_type = NetworkType.MAINNET;
            break;
        }

        console.log("Requesting permissions...");
        this.wallet
          .requestPermissions({
            network: { type: network_type, rpcUrl: TaezarsConfig.RPC },
          })
          .then(() => this.wallet.getPKH())
          .then((address) => {
            this.activeAccount = address;
            this.tezos.setWalletProvider(this.wallet);
            this.initWallet();
          });
      } catch (error) {
        console.log("Got error:", error);
      }
    },
    disconnectWallet: async function () {
      await this.wallet.client.destroy();
      this.wallet = undefined;
      this.walletConnected = false;
      this.activeAccount = null;
      this.userBalance = [];
      location.reload();
    },
    getActiveAccount: function () {
      if (this.activeAccount) {
        return (
          this.activeAccount.substring(0, 5) +
          "..." +
          this.activeAccount.substring(
            this.activeAccount.length - 5,
            this.activeAccount.length
          )
        );
      }
    },
    getTokenID(name) {
      var ret = undefined;
      this.userBalance.some((b) => {
        if (b.name == name) {
          ret = b.token_id;
          return;
        }
      });
      return ret;
    },
    getTokenName(id) {
      var ret = undefined;
      this.userBalance.some((b) => {
        if (b.token_id == id) {
          ret = b.name;
          return;
        }
      });
      return ret;
    },
    getTokenSymbol(id) {
      var ret = undefined;
      this.userBalance.some((b) => {
        if (b.token_id == id) {
          ret = b.symbol;
          return;
        }
      });
      return ret;
    },
    getTokenGroup(id) {
      var ret = undefined;
      this.userBalance.some((b) => {
        if (b.token_id == id) {
          ret = b.group;
          return;
        }
      });
      return ret;
    },
    getTokenRarity(id) {
      var ret = undefined;
      this.userBalance.some((b) => {
        if (b.token_id == id) {
          ret = b.rarity;
          return;
        }
      });
      return ret;
    },
    getRarityColor(rarity) {
      if (rarity == "F") return "#264653";
      else if (rarity == "E") return "#8E6C15";
      else if (rarity == "D") return "#2a9d8f";
      else if (rarity == "C") return "#47360B";
      else if (rarity == "B") return "#f4a261";
      else if (rarity == "A") return "#e76f51";
      else return "#000000";
    },
    getTokenDesc(id) {
      var r = "";
      this.tokenData.some((t) => {
        if (t.token_id == id) {
          r = t.description_en;
          return;
        }
      });
      return r;
    },
    getTokenG(id) {
      var r = "";
      this.tokenData.some((t) => {
        if (t.token_id == id) {
          r = t.g;
          return;
        }
      });
      return r;
    },
    getTokenClass(id) {
      var r = "";
      this.tokenData.some((t) => {
        if (t.token_id == id) {
          r = t.class;
          return;
        }
      });
      return r;
    },
    getPrizeData() {
      this.tezos.contract.at(this.CONTRACT_ADDRESS).then((contract) => {
        contract.storage().then((storage) => {
          this.prize = storage.prize.valueOf();
          this.prize_given = storage.prize_given;
        });
      });
    },
    getOrders() {
      this.tezos.contract.at(this.MARKET_ADDRESS).then((contract) => {
        contract.storage().then((storage) => {
          //console.log(storage.orders);
          this.offers = [];
          storage.orders.valueMap.forEach((v, k) => {
            var entry = {};
            entry["key"] = parseInt(k.replace('"', ""));
            entry["owner"] = v.owner;
            entry["ask_amount"] = v.ask_amount.toNumber() / 1e6;
            entry["offer_amount"] = v.offer_amount.toNumber() / 1e6;
            entry["offer_tokens"] = [];
            var ok = 0;
            v.offer_tokens.forEach((ot) => {
              var offer_entry = {};
              offer_entry["key"] = ok;
              offer_entry["token_id"] = ot.token_id.toNumber();
              offer_entry["name"] = this.getTokenName(offer_entry["token_id"]);
              offer_entry["rarity"] = this.getTokenRarity(
                offer_entry["token_id"]
              );
              offer_entry["amount"] = ot.amount.toNumber();
              entry["offer_tokens"].push(offer_entry);
              ok++;
            });
            entry["ask_tokens"] = [];
            var ak = 0;
            v.ask_tokens.forEach((at) => {
              var ask_entry = {};
              ask_entry["key"] = ak;
              ask_entry["token_id"] = at.token_id.toNumber();
              ask_entry["name"] = this.getTokenName(ask_entry["token_id"]);
              ask_entry["rarity"] = this.getTokenRarity(ask_entry["token_id"]);
              ask_entry["amount"] = at.amount.toNumber();
              entry["ask_tokens"].push(ask_entry);
              ak++;
            });
            this.offers.push(entry);
          });
        });
      });
    },
    getOrderBook(only_own) {
      var ob = [];
      this.offers.forEach((o) => {
        if (only_own) {
          if (o.owner == this.activeAccount) ob.push(o);
        } else ob.push(o);
      });
      return ob;
    },
    getBalances() {
      return new Promise((resolve, reject) => {
        /*this.tezos.setProvider({
          signer: new InMemorySigner(
            "edsk3RFfvaFaxbHx8BMtEW1rKQcPtDML3LXjNqMNLCzC3wLC1bWbAt"
          ),
        });*/
        this.tezos.contract
          .at(this.CONTRACT_ADDRESS)
          .then((contract) => {
            /*console.log(
              "Retrieving token balance of address " + this.activeAccount
            );*/
            var request = [];
            for (var i = 0; i < this.tokenData.length; i++) {
              request.push({
                owner: this.activeAccount,
                token_id: i,
              });
            }
            return contract.views.balance_of(request).read();
          })
          .then((res) => {
            this.uniqueCoins = 0;
            this.totalLegions = 0;
            this.ownLegions = 0;
            this.legionsCompletePct = 0;

            this.totalAureus = 0;
            this.ownAureus = 0;

            this.totalGods = 0;
            this.ownGods = 0;
            this.godsCompletePct = 0;

            this.totalTemples = 0;
            this.ownTemples = 0;
            this.templesCompletePct = 0;

            this.totalCivilWars = 0;
            this.ownCivilWars = 0;
            this.civilWarsCompletePct = 0;

            this.totalBonus = 0;
            this.ownBonus = 0;
            this.bonusCompletePct = 0;

            this.totalMissions = 0;
            this.ownMissions = 0;
            this.missionsCompletePct = 0;

            this.tokenGroups = {};

            res.forEach((r) => {
              //console.log(r);

              var group = this.getTokenGroup(r.request.token_id);

              if (!this.tokenGroups[group]) {
                this.tokenGroups[group] = {};
                this.tokenGroups[group]["totalTokens"] = 0;
                this.tokenGroups[group]["ownedTokens"] = 0;
              }

              if (!this.tokenGroups[group].tokens)
                this.tokenGroups[group]["tokens"] = [];

              var g = {};
              g["token_id"] = r.request.token_id;
              g["symbol"] = this.getTokenSymbol(r.request.token_id);
              g["name"] = this.getTokenName(r.request.token_id);
              g["rarity"] = this.getTokenRarity(r.request.token_id);
              g["desc"] = this.getTokenDesc(r.request.token_id);
              g["g"] = this.getTokenG(r.request.token_id);
              g["own"] = r.balance.c[0] > 0 ? true : false;
              g["balance"] = r.balance.c[0];

              this.tokenGroups[group].totalTokens++;
              if (r.balance.c[0] > 0) this.tokenGroups[group].ownedTokens++;

              this.tokenGroups[group].tokens.push(g);

              if (this.getTokenClass(r.request.token_id) == "NFT") {
                this.totalAureus++;
              } else if (this.getTokenClass(r.request.token_id) == "Legio")
                this.totalLegions++;
              else if (this.getTokenRarity(r.request.token_id) == "G")
                this.totalGods++;
              else if (this.getTokenRarity(r.request.token_id) == "F")
                this.totalTemples++;
              else if (this.getTokenRarity(r.request.token_id) == "E")
                this.totalCivilWars++;
              else if (this.getTokenRarity(r.request.token_id) == "D")
                this.totalBonus++;
              else if (this.getTokenRarity(r.request.token_id) == "A")
                this.totalMissions++;
              if (r.balance.toNumber() > 0) {
                this.uniqueCoins++;
                if (this.getTokenClass(r.request.token_id) == "NFT")
                  this.ownAureus++;
                else if (this.getTokenClass(r.request.token_id) == "Legio")
                  this.ownLegions++;
                else if (this.getTokenRarity(r.request.token_id) == "G")
                  this.ownGods++;
                else if (this.getTokenRarity(r.request.token_id) == "F")
                  this.ownTemples++;
                else if (this.getTokenRarity(r.request.token_id) == "E")
                  this.ownCivilWars++;
                else if (this.getTokenRarity(r.request.token_id) == "D")
                  this.ownBonus++;
                else if (this.getTokenRarity(r.request.token_id) == "A")
                  this.ownMissions++;

                /// Can MINT
                if (r.request.token_id.toNumber() == this.GOD_ALLOW_TOKEN) {
                  this.canMintGods = true;
                } else if (
                  r.request.token_id.toNumber() == this.TEMPLE_ALLOW_TOKEN
                ) {
                  this.canMintTemples = true;
                  this.godDiscount = true;
                } else if (
                  r.request.token_id.toNumber() ==
                  this.LEGION_REDUCED_COST_TOKEN
                ) {
                  this.legionDiscount = true;
                } else if (
                  r.request.token_id.toNumber() == this.CIVILWAR_ALLOW_TOKEN
                ) {
                  this.canMintCivilWars = true;
                }
              }

              if (this.totalAureus == this.ownAureus) this.canClaimPrize = true;
              else this.canClaimPrize = false;

              this.$set(
                this.userBalance[r.request.token_id],
                "balance",
                r.balance.c[0]
              );
            });

            ///console.log(this.tokenGroups);

            /// Collecion complete PCT
            this.completePct =
              Math.round(
                (100 * 100 * this.uniqueCoins) / this.tokenData.length
              ) / 100;

            this.legionsCompletePct =
              Math.round((100 * 100 * this.ownLegions) / this.totalLegions) /
              100;

            this.godsCompletePct =
              Math.round((100 * 100 * this.ownGods) / this.totalGods) / 100;

            this.templesCompletePct =
              Math.round((100 * 100 * this.ownTemples) / this.totalTemples) /
              100;

            this.civilWarsCompletePct =
              Math.round(
                (100 * 100 * this.ownCivilWars) / this.totalCivilWars
              ) / 100;

            this.bonusCompletePct =
              Math.round((100 * 100 * this.ownBonus) / this.totalBonus) / 100;

            this.missionsCompletePct =
              Math.round((100 * 100 * this.ownMissions) / this.totalMissions) /
              100;

            this.getPrizeData();
            resolve();
          })
          .catch((error) => {
            console.log("Error: " + JSON.stringify(error, null, 2));
            reject();
          });
      });
    },
    setBalance: async function () {
      this.walletBalance = await this.tezos.tz.getBalance(this.activeAccount);
    },
    getBalance: function () {
      return numeral(Math.round((1e3 * this.walletBalance) / 1e6) / 1e3).format(
        "0,0.000"
      );
    },
  },
  created: async function () {
    //console.log(this.obtainedFrom);
    this.tokenData.forEach((t) => {
      t["balance"] = null;
      t["img"] = "coins/" + t.token_id + ".gif";
      t["g"] = this.obtainedFrom[t.token_id].g;
      this.userBalance.push(t);
    });
    await this.initWallet();

    //console.log(this.tokenData);
    //console.log(this.$router.currentRoute.fullPath);
  },
  mounted: async function () {
    this.initTemplate();
    this.$router.push("/");
  },
};
</script>
