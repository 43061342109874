<template>
 <div class="ud-flex ud-items-center ud-rounded-lg ud-bg-blue-800 ud-text-white ud-text-sm ud-font-bold ud-px-4 ud-py-3" role="alert">
  <svg class="ud-fill-current ud-w-4 ud-h-4 ud-mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
  <p>{{label}}</p>
</div>
</template>

<style scoped>
.noselect {
  outline: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.orange {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.blue {
  --tw-text-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-text-opacity));
}
</style>


<script>
export default {
  name: "TToast",
  data: () => ({
    showToast: false
  }),
  computed: {
    show: {
      // getter
      get() {
        return this.showToast;
      },
      // setter
      set(newValue) {       
        this.showToast = newValue;
        if (newValue == true) setTimeout( ()=>{ this.showToast = false; }, this.timeout)
      },
    },
  },
  props: {  
    timeout: {
      default: 3000
    },
    color: {
      default: "orange",
    },
    label: {
      default: "label",
    },
  },
  mounted() {},
  updated() {},
  methods: {},
};
</script>