<template>
  <a
    class="
      menu-scroll
      .ud-rounded-lg
      ud-w-[132px]
      ud-h-[40px]
      ud-flex
      ud-flex-col
      ud-p-3
      ud-pt-3
      ud-content-center
      ud-justify-center
      ud-justify-items-center
    "
    :href="href"
    v-bind:class="{
      'not-owned-card': !owned,
      rarityA: owned,
      /*rarityH: owned && coinRarity == 'H',
          rarityG: owned && coinRarity == 'G',
          rarityF: owned && coinRarity == 'F',
          rarityE: owned && coinRarity == 'E',
          rarityD: owned && coinRarity == 'D',
          rarityC: owned && coinRarity == 'C',
          rarityB: owned && coinRarity == 'B',
          rarityA: owned && coinRarity == 'A',*/
    }"
  >
    <div
      class="noselect ud-flex ud-flex-row"
      v-bind:class="{ '!ud-cursor-pointer': pointer }"
    >
      <div
        class="ud-flex-grow ud-text-[8px] outline ud-mt-0"
        style="font-family: 'RomanSD'"
        v-bind:class="{ '!ud-cursor-pointer': pointer }"
      >
        {{ splitName()[0] }}
      </div>
    </div>
    <div class="ud-flex" v-bind:class="{ '!ud-cursor-pointer': pointer }">
      <div
        class="noselect ud-flex-grow ud-text-[6px] ud-mt-[-2px] outline-gray"
        style="font-family: 'RomanSD'"
        v-bind:class="{ '!ud-cursor-pointer': pointer }"
      >
        {{ splitName()[1] }}
      </div>
      <div
        v-if="showBalance"
        class="
          ud-text-gray-400
          ud-text-right
          ud-font-bold
          ud-text-[10px]
          ud-mt-[-10px]
          ud-mr-[-6px]
        "
      >
        x<span class="ud-text-[14px]">{{ balance }}</span>
      </div>
    </div>
  </a>
</template>

<style scoped>
.not-owned {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: default;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: default;
}

.not-owned-title {
  color: darkgrey !important;
  background-color: transparent;
}

.rarityH {
  background-color: #9dc5c3;
  background-image: linear-gradient(315deg, #9dc5c3 0%, #5e5c5c 74%);
}

.rarityG {
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #2234ae 0%, #191714 74%);
}

.rarityF {
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);
}

.rarityE {
  background-color: #2f4353;
  background-image: linear-gradient(315deg, #d2ccc4 0%, #2f4353 74%);
}

.rarityD {
  background-color: #baba00;
  background-image: linear-gradient(315deg, #baba00 0%, #ba1313 74%);
}

.rarityC {
  background-color: #63d471;
  background-image: linear-gradient(315deg, #63d471 0%, #233329 74%);
}

.rarityB {
  background-color: #a55c1b;
  background-image: linear-gradient(315deg, #a55c1b 0%, #000000 74%);
}

.rarityA {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

.not-owned-card {
  background-color: #25426b;
}

.outline {
  color: black !important;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.outline-gray {
  color: black !important;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: darkgray;
}

.outlineDesc {
  color: white !important;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
</style>

<script>
export default {
  name: "coinChip",
  components: {},
  data: () => ({
    v: 0,
    viewImage: true,
    pointer: false,
  }),
  props: {
    href: {
      default: undefined,
    },
    value: {
      default: 0,
    },
    coinName: {
      default: "",
    },
    balance: {
      default: 0,
    },
    coinImg: {
      default: "",
    },
    coinDesc: {
      default: "",
    },
    coinRarity: {
      default: "F",
    },
    owned: {
      default: false,
    },
    showBalance: {
      default: false,
    },
  },
  mounted() {
    if (this.href) {
      this.pointer = true;
      const pageLink = document.querySelectorAll(".menu-scroll");

      try {
        pageLink.forEach((elem) => {
          elem.addEventListener("click", (e) => {
            e.preventDefault();

            try {
              document.querySelector(elem.getAttribute("href")).scrollIntoView({
                behavior: "smooth",
                offsetTop: 1 - 60,
              });
            } catch (e) {
              // pass
            }
          });
        });
      } catch (e) {
        //pass
      }
    }
  },
  updated() {},
  methods: {
    splitName() {
      var a = this.coinName.split(" - ");
      var r = [];
      a.forEach((t) => {
        r.push(t.trim());
      });
      if (r.length < 2) {
        r.push(a[0]);
      }
      return r;
    },
  },
};
</script>