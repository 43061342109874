<template>
  <div class="ud-flex ud-flex-col ud-items-center ud-justify-center">
    <div
      class="
        noselect
        ud-w-[318px]
        ud-text-white
        ud-text-center
        ud-font-bold
        ud-text-xl
        ud-flex
        ud-flex-col
        ud-m-0
        ud-p-0
        ud-opacity-90
        ud-rounded-t-xl
        ud-border
        ud-border-gray-800
        ud-border-b-0
      "
      style="
        /* From https://css.glass */
        background: rgba(35, 9, 66, 0.59);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
      "
      v-if="mintTitle"
    >
      <div
        class="
          ud-flex
          ud-items-center
          ud-justify-center
          ud-p-2
          ud-w-[320px]
          ud-bg-transparent
        "
      >
        <TProgress
          class="ud-m-2 ud-mt-0"
          color="blue"
          :label="mintTitle"
          :pct="false"
          :min="0"
          :max="totalQty"
          :value="ownedQty"
          :showValue="true"
        />
      </div>
    </div>
    <div
      class="
        ud-border
        ud-flex
        ud-flex-col
        ud-items-center
        ud-rounded-b-lg
        ud-border-gray-800
        ud-p-2
        ud-m-1
        ud-mt-0
        ud-pt-4
        ud-flex-grow
      "
      style="background-color: #1d2144"
    >
      <div class="ud-flex ud-flex-row">
        <div
          v-if="cards.length > 1"
          :class="{ 'ud-opacity-10': selectedCard == 0 }"
          class="
            ud-flex
            ud-w-[35px]
            ud-items-center
            ud-justify-center
            ud-mr-[-34px]
            ud-z-50
            ud-text-white
            hover:ud-shadow-signUp hover:ud-bg-opacity-90
            ud-rounded-md ud-transition ud-ease-in-up ud-duration-300 ud-my-0
          "
          @click="prev()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="ud-w-7 ud-h-7"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        <coinCard
          v-if="!minted || minting1 || minting5 || error"
          :coinName="mintType"
          :owned="false"
        ></coinCard>
        <!--<transition name="fade" mode="out-in">-->
        <coinCard
          :key="selectedCard"
          v-if="
            (!minting1 || minting5) && minted && !error && this.cards.length > 0
          "
          :coinName="this.cards[this.selectedCard].mintedName"
          :coinImg="this.cards[this.selectedCard].mintedImg"
          :coinDesc="this.cards[this.selectedCard].mintedDesc"
          :coinRarity="this.cards[this.selectedCard].mintedRarity"
          :balance="this.cards[this.selectedCard].diff"
          :newCard="this.cards[this.selectedCard].new"
          :owned="true"
        ></coinCard>
        <!--</transition>--->

        <div
          v-if="cards.length > 1"
          :class="{ 'ud-opacity-10': selectedCard == cards.length - 1 }"
          class="
            ud-flex
            ud-w-[35px]
            ud-items-center
            ud-justify-center
            ud-ml-[-34px]
            ud-z-50
            ud-text-white
            hover:ud-shadow-signUp hover:ud-bg-opacity-90
            ud-rounded-md ud-transition ud-ease-in-up ud-duration-300 ud-my-0
          "
          @click="next()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="ud-w-7 ud-h-7"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>

      <div
        class="
          ud-flex ud-justify-center ud-w-full ud-items-center ud-gap-2 ud-p-2
          noselect
        "
      >
        <button
          v-on:click="mint(mintFunction)"
          :disabled="minting1 || minting5 || !canMint || disableButton"
          class="
            ud-h-12
            disabled:ud-opacity-40
            ud-items-center
            ud-w-full
            ud-justify-center
            ud-block-inline
            ud-text-base
            ud-font-bold
            ud-text-white
            ud-bg-primary
            ud-py-0
            ud-px-0
            hover:ud-shadow-signUp hover:ud-bg-opacity-90
            ud-rounded-md
            ud-transition
            ud-ease-in-up
            ud-duration-300
            ud-basis-1/2
          "
        >
          <div class="ud-flex ud-flex-col" v-if="!minting1">
            <div>Mint <span class="ud-text-gray-300">1</span></div>
            <span class="ud-text-red-400 ud-text-xs ud-mt-[-4px]"
              >{{ cost }}&nbsp;tez</span
            >
          </div>
          <div v-else>
            <svg
              class="
                ud-inline-block ud-animate-spin
                ud-ml--1
                ud-mr-1 ud-mb-[2px] ud-h-5 ud-w-5 ud-text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="ud-opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="ud-opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Minting...
          </div>
        </button>
        <!--ud-hidden
          md:ud-inline-->
        <button
          v-on:click="mint5(mintFunction)"
          :disabled="minting1 || minting5 || !canMint || disableButton"
          class="
            ud-h-12
            disabled:ud-opacity-40
            ud-items-center
            ud-w-full
            ud-justify-center
            ud-block-inline
            ud-text-base
            ud-font-bold
            ud-text-white
            ud-bg-primary
            ud-py-0
            ud-px-0
            hover:ud-shadow-signUp hover:ud-bg-opacity-90
            ud-rounded-md
            ud-transition
            ud-ease-in-up
            ud-duration-300
            ud-basis-1/2
          "
        >
          <div class="ud-flex ud-flex-col" v-if="!minting5">
            <div>Mint <span class="ud-text-gray-300">5</span></div>
            <span class="ud-text-red-400 ud-text-xs ud-mt-[-4px]"
              >{{ 5 * cost }}&nbsp;tez</span
            >
          </div>
          <div v-else>
            <svg
              class="
                ud-inline-block ud-animate-spin
                ud-ml--1
                ud-mr-1 ud-mb-[2px] ud-h-5 ud-w-5 ud-text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="ud-opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="ud-opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Minting...
          </div>
        </button>
      </div>

      <div class="ud-flex-grow">
        <p
          class="
            ud-text-body-color ud-leading-relaxed
            md:ud-leading-relaxed
            ud-max-w-[300px]
            ud-text-justify
            ud-p-2
            ud-pt-0
            ud-mb-4
            ud-mt-2
            ud-text-xs
            noselect
            ud-flex-grow
          "
          v-html="mintDescription"
        ></p>
      </div>
    </div>
  </div>
</template>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: default;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script>
import coinCard from "../components/coinCard.vue";
import { OpKind } from "@taquito/taquito";
import TProgress from "./ui/TProgress.vue";

export default {
  name: "coinMint",
  components: {
    coinCard,
    TProgress,
  },
  props: {
    ownedQty: {
      default: 0,
    },
    totalQty: {
      default: 0,
    },
    mintFunction: {
      default: "legion",
    },
    mintTitle: {
      default: "",
    },
    mintDescription: {
      default: "",
    },
    mintCost: {
      default: 0.15,
    },
    mintReducedCost: {
      default: 0.1,
    },
    canMint: {
      default: false,
    },
    isReducedCost: {
      default: false,
    },
    mintImage: {
      default: "",
    },
    mintType: {
      default: "COIN",
    },
    disableButton: {
      default: false,
    },
  },
  computed: {
    cost() {
      return this.isReducedCost ? this.mintReducedCost : this.mintCost;
    },
  },
  data: () => ({
    main: null,
    minted: false,
    minting1: false,
    minting5: false,
    showError: false,
    error: undefined,
    cards: [],
    selectedCard: 0,
  }),
  created: async function () {
    this.main = this.$router.app.$children[0];
    ///this.cost = this.isReducedCost ? this.mintReducedCost : this.mintCost;
  },
  mounted: async function () {},
  methods: {
    setData() {
      console.log("Setting data...");
    },
    prev() {
      if (this.selectedCard > 0) this.selectedCard--;
    },
    next() {
      if (this.selectedCard + 1 < this.cards.length) this.selectedCard++;
    },
    dismiss() {
      this.error = undefined;
    },
    estimate() {
      return new Promise((resolve, reject) => {
        this.main.tezos.setWalletProvider(this.main.wallet);
        console.log(this.main.wallet);
        this.main.tezos.contract
          .at(this.main.CONTRACT_ADDRESS)
          .then((contract) => {
            const i = 0;
            return contract.methods.minter(i).toTransferParams({
              source: this.main.activeAccount,
              amount: this.cost,
            });
          })
          .then((op) => {
            console.log(`Estimating the smart contract call : `);
            console.log(op);
            return this.main.tezos.estimate.transfer(op);
          })
          .then((est) => {
            console.log(est);
            console.log(`
            burnFeeMutez : ${est.burnFeeMutez}, 
            gasLimit : ${est.gasLimit}, 
            minimalFeeMutez : ${est.minimalFeeMutez}, 
            storageLimit : ${est.storageLimit}, 
            suggestedFeeMutez : ${est.suggestedFeeMutez}, 
            totalCost : ${est.totalCost}, 
            usingBaseFeeMutez : ${est.usingBaseFeeMutez}`);
            resolve(est);
          })
          .catch((error) => {
            console.table(`Error: ${JSON.stringify(error, null, 2)}`);
            reject(error);
          });
      });
    },
    async mint() {
      window.gtag('event', 'mint1_clicked', {
        'app_name': 'Taezars',
        'screen_name': 'Minter'
      });
      this.$emit("minting");
      this.showError = false;
      this.error = undefined;
      this.minting1 = true;
      this.minting5 = false;
      this.minted = false;

      var gasLimit = 10000;
      var storageLimit = 150;
      var fee = 8000;    

      try {
        var est = await this.estimate();
        gasLimit = Math.max(2 * est.gasLimit, 10000);
        storageLimit = Math.max(2 * est.storageLimit, 150);
        fee = Math.max(1.5 * est.suggestedFeeMutez, 5000);
      } catch (err) {
        console.log("Estimation not available. Using default values.")      
      }

      this.cards = [];
      this.selectedCard = 0;

      /// Store current balance
      const prevBalance = JSON.parse(JSON.stringify(this.main.userBalance));
      var prevHeldTokens = [];
      prevBalance.forEach((t) => {
        var pt = {};
        pt["token_id"] = t.token_id;
        pt["balance"] = t.balance;
        prevHeldTokens.push(pt);
      });

      /// Call Mint

      this.main.tezos.setWalletProvider(this.main.wallet);

      this.main.tezos.wallet
        .at(this.main.CONTRACT_ADDRESS)
        .then((contract) => {
          return contract.methods.minter(this.mintFunction).send({
            storageLimit: storageLimit,
            fee: fee,
            gasLimit: gasLimit,
            amount: this.cost,
          });
        })
        .then(async (op) => {
          console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(1);
          return op.opHash;
        })
        .then(async (hash) => {
          this.newTokens = [];
          var curHeldTokens = [];

          await this.main.getBalances();
          const curBalance = JSON.parse(JSON.stringify(this.main.userBalance));

          curBalance.forEach((t) => {
            var ct = {};
            ct["token_id"] = t.token_id;
            ct["balance"] = t.balance;
            curHeldTokens.push(ct);
          });

          var newTokens = [];

          curHeldTokens.forEach((c) => {
            prevHeldTokens.forEach((p) => {
              if (c.token_id == p.token_id) {
                if (c.balance != p.balance) {
                  c.diff = c.balance - p.balance;
                  if (p.balance == 0) c.new = true;
                  else c.new = false;
                  newTokens.push(c);
                }
              }
            });
          });

          var cardsTemp = [];

          newTokens.forEach((t) => {
            var c = {};
            c.new = t.new;
            c.mintedImg = this.main.CDN + "/coins/" + t.token_id + ".gif";
            c.mintedName = this.main.getTokenName(t.token_id);
            c.mintedDesc = this.main.getTokenDesc(t.token_id);
            c.mintedRarity = this.main.getTokenRarity(t.token_id);
            cardsTemp.push(c);
          });

          this.cards = cardsTemp.sort(() => 0.5 - Math.random());

          console.log(this.cards);
          this.minted = true;
          this.minting1 = false;
          console.log("Operation injected: " + hash);
          this.$emit("success", hash);
        })
        .catch((error) => {
          this.error = error;
          this.showError = true;
          this.minting1 = false;
          console.log(error);
          console.log("Error: " + JSON.stringify(error, null, 2));
          this.$emit("error", error);
        });
    },
    async mint5() {
      window.gtag('event', 'mint5_clicked', {
        'app_name': 'Taezars',
        'screen_name': 'Minter'
      });
      this.$emit("minting");
      this.showError = false;
      this.error = undefined;
      this.minting5 = true;
      this.minting1 = false;
      this.minted = false;

      var gasLimit = 10000;
      var storageLimit = 150;
      var fee = 8000;    

      try {
        var est = await this.estimate();
        gasLimit = Math.max(2 * est.gasLimit, 10000);
        storageLimit = Math.max(2 * est.storageLimit, 150);
        fee = Math.max(1.5 * est.suggestedFeeMutez, 5000);
      } catch (err) {
        console.log("Estimation not available. Using default values.")      
      }      
     
      this.cards = [];
      this.selectedCard = 0;

      /// Store current balance
      const prevBalance = JSON.parse(JSON.stringify(this.main.userBalance));
      var prevHeldTokens = [];
      prevBalance.forEach((t) => {
        var pt = {};
        pt["token_id"] = t.token_id;
        pt["balance"] = t.balance;
        prevHeldTokens.push(pt);
      });

      /// Call Mint
      this.main.tezos.setWalletProvider(this.main.wallet);
      //console.log(this.main.tezos);
      //console.log(this.main.tezos._wallet.walletProvider);
      this.main.tezos.wallet
        .at(this.main.CONTRACT_ADDRESS)
        .then((contract) => {
          var operations = [];
          for (var i = 0; i < 5; i++) {
            operations.push({
              kind: OpKind.TRANSACTION,
              ...contract.methods.minter(this.mintFunction).toTransferParams(),
              storageLimit: storageLimit,
              fee: fee,
              gasLimit: gasLimit,
              amount: this.cost,
            });
          }

          console.log(operations);

          const batch = this.main.tezos.wallet.batch(operations);
          return batch.send();
        })
        .then(async (op) => {
          console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(1);
          return op.opHash;
        })
        .then(async (hash) => {
          this.newTokens = [];
          var curHeldTokens = [];

          console.log("getting balances...");
          await this.main.getBalances();
          console.log("done...");
          const curBalance = JSON.parse(JSON.stringify(this.main.userBalance));

          curBalance.forEach((t) => {
            var ct = {};
            ct["token_id"] = t.token_id;
            ct["balance"] = t.balance;
            curHeldTokens.push(ct);
          });

          var newTokens = [];

          curHeldTokens.forEach((c) => {
            prevHeldTokens.forEach((p) => {
              if (c.token_id == p.token_id) {
                if (c.balance != p.balance) {
                  c.diff = c.balance - p.balance;
                  if (p.balance == 0) c.new = true;
                  else c.new = false;
                  newTokens.push(c);
                }
              }
            });
          });

          var cardsTemp = [];

          newTokens.forEach((t) => {
            var c = {};
            c.diff = t.diff;
            c.new = t.new;
            c.mintedImg = this.main.CDN + "/coins/" + t.token_id + ".gif";
            c.mintedName = this.main.getTokenName(t.token_id);
            c.mintedDesc = this.main.getTokenDesc(t.token_id);
            c.mintedRarity = this.main.getTokenRarity(t.token_id);
            cardsTemp.push(c);
          });

          this.cards = cardsTemp.sort(() => 0.5 - Math.random());

          console.log(this.cards);
          this.minted = true;
          this.minting5 = false;
          console.log("Operation injected: " + hash);
          this.$emit("success", hash);
          //this.main.resumeUpdates();
        })
        .catch((error) => {
          this.error = error;
          this.showError = true;
          this.minting5 = false;
          //this.main.resumeUpdates();
          console.log(error);
          console.log("Error: " + JSON.stringify(error, null, 2));
          this.$emit("error", error);
        });
    },
  },
};
</script>
