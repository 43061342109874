<template>
  <div class="backImg">
    <section
      id="stats"
      class="
        ud-relative ud-z-10 ud-pt-[115px] ud-pb-[10px]
        md:ud-pt-[115px] md:ud-pb-[10px]
        xl:ud-pt-[115px] xl:ud-pb-[10px]
        2xl:ud-pt-[115px] 2xl:ud-pb-[10px]
        ud-min-h-full ud-w-full
        backImg
      "
    >
      <div
        class="
          ud-container ud-text-white ud-rounded ud-flex ud-px-5 ud-flex-col
        "
      >
        <div
          class="
            ud-flex
            ud-flex-row
            ud-flex-wrap
            ud-w-full
            ud-gap-4
            ud-background-transparent
          "
        >
          <div class="ud-flex-grow ud-min-w-[260px] sm:ud-w-[270px]">
            <div
              class="
                ud-p-2
                ud-rounded-t-xl
                ud-border
                ud-border-gray-800
                ud-border-b-0
                noselect
                ud-text-center
              "
              style="
                /* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "
            >
              Collector Ranking
            </div>

            <div
              class="
                ud-pt-[26px]
                ud-text-center
                ud-rounded-b
                ud-flex-grow
                ud-border
                ud-border-gray-800
                ud-h-[185px]
                rankBack
              "
            >
              <transition name="fade" mode="out-in">
                <div
                  class="ud-m-0 ud-flex-col ud-w-full"
                  v-if="ranking.length > 0"
                >
                  <div class="ud-flex ud-w-full ud-justify-center">
                    <div class="ud-w-[135px] ud-text-center noselect ud-mt-0">
                      <h1 class="ud-text-xs sm:ud-text-md">RANKING</h1>
                      <h1
                        class="
                          ud-text-md
                          ud-text-3xl
                          ud-mt-1
                          ud-text-gray-400
                          ud-font-bold
                        "
                      >
                        {{ getNumberWithOrdinal(userRank) }}
                      </h1>
                    </div>
                    <div class="ud-w-[135px] ud-text-center noselect">
                      <h1 class="ud-opacity-100 ud-text-xs sm:ud-text-md">
                        YOUR SCORE
                      </h1>
                      <h1
                        class="
                          ud-drop-shadow-xl
                          ud-shadow-black
                          ud-text-md
                          ud-text-3xl
                          ud-mt-1
                          ud-text-gray-400
                          ud-font-bold
                        "
                      >
                        {{ getNumber(userScore) }}
                      </h1>
                    </div>
                  </div>
                  <div class="ud-flex ud-w-full ud-justify-center ud-mt-4">
                    <div class="ud-w-[135px] ud-text-center noselect">
                      <h1 class="ud-text-xs sm:ud-text-md">COMPLETION</h1>
                      <h1
                        class="
                          ud-text-3xl ud-mt-1 ud-text-gray-400 ud-font-bold
                        "
                      >
                        {{ getNumber0dp(main.completePct) }}%
                      </h1>
                    </div>
                    <div class="ud-w-[135px] ud-text-center noselect">
                      <h1 class="ud-text-xs sm:ud-text-md">UNIQUE TOKENS</h1>
                      <h1
                        class="
                          ud-text-md
                          ud-text-3xl
                          ud-mt-1
                          ud-text-gray-400
                          ud-font-bold
                        "
                      >
                        {{ main.uniqueCoins }}
                      </h1>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>

          <div class="ud-flex-grow sm:ud-min-w-[380px]">
            <div
              class="
                ud-p-2
                ud-pl-0
                ud-rounded-t-xl
                ud-border
                ud-border-gray-800
                ud-border-b-0
                noselect
                ud-text-center
              "
              style="
                /* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "
            >
              Top Collectors
            </div>
            <div
              class="
                ud-text-center
                ud-pt-2
                ud-rounded-b
                ud-border
                ud-border-gray-800
                ud-h-[185px]
                ud-bg-transparent
                rankingBack
                ud-flex ud-justify-center
              "
            >
              <transition name="fade" mode="out-in">
                <div
                  v-if="ranking.length > 0"
                  class="ud-text-center ud-flex ud-justify-center"
                >
                  <table
                    v-if="ranking"
                    class="
                      table-auto
                      ud-margin-auto ud-w-full ud-text-center ud-border-separate
                    "
                  >
                    <thead class="ud-block ud-table-fixed ud-text-center">
                      <tr
                        class="
                          noselect
                          ud-text-xs
                          ud-border-b
                          ud-text-body
                          ud-border-spacing-2
                        "
                      >
                        <th class="ud-font-normal ud-w-10">Rank</th>
                        <th class="ud-font-normal ud-w-[95px] ud-text-left">
                          Address
                        </th>
                        <th class="ud-font-normal ud-w-[50px] ud-text-right">
                          Col
                        </th>
                        <th class="ud-font-normal ud-w-[50px] ud-text-right">
                          Tokens
                        </th>
                        <th class="ud-font-normal ud-w-[60px] ud-text-right">
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="
                        noselect
                        ud-block
                        ud-overflow-auto
                        ud-overscroll-contain
                        ud-h-[140px]
                      "
                    >
                      <tr
                        v-for="(r, idx) in ranking.slice(0, 25)"
                        v-bind:key="r.addr"
                        class="
                          ud-text-xs ud-text-gray-400 ud-border-spacing-2 ud-p-2
                        "
                      >
                        <td class="ud-w-10">
                          {{ getNumberWithOrdinal(idx + 1) }}
                        </td>
                        <td class="ud-w-[95px] ud-text-left">
                          {{
                            r.addr.substring(0, 5) +
                            "..." +
                            r.addr.substring(r.addr.length - 5, r.addr.length)
                          }}
                        </td>
                        <td class="ud-w-[50px] ud-text-right">
                          {{ getNumber1dp(r.completion) }}%
                        </td>
                        <td class="ud-w-[50px] ud-text-right">
                          {{ r.unique_tokens }}
                        </td>
                        <td class="ud-w-[60px] ud-text-right">
                          {{ getNumber(r.score) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </transition>
            </div>
          </div>

          <div class="ud-flex-grow ud-mb-0 noselect xs:ud-min-w-[220px]">
            <div
              class="
                ud-p-2
                ud-rounded-t-xl
                ud-border
                ud-border-gray-800
                ud-border-b-1
                ud-text-center
              "
              style="
                /* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "
            >
              Acta Populi
            </div>
            <div
              class="
                ud-text-left ud-p-3 ud-pr-0 ud-h-[185px] ud-text-[12px]
                md:ud-text-[10px]
                ud-rounded-b-lg ud-border
                rankingBack
                ud-border-gray-800 ud-border-t-0
              "
            >
              <transition name="fade" mode="out-in">
                <div
                  v-if="latest_txns.length > 0"
                  class="ud-h-[100%] ud-mb-0 ud-overflow-auto ud-contain"
                >
                  <div
                    class="ud-mb-[0px] ud-mt-0"
                    v-for="txn in latest_txns.slice(0, 100)"
                    v-bind:key="txn.id"
                  >
                    <div>
                      <span class="ud-text-gray-400">{{
                        txn.user.substring(0, 5) +
                        "..." +
                        txn.user.substring(txn.user.length - 5, txn.user.length)
                      }}</span>
                      <span
                        class="ud-w-[50px] ud-text-gray-400 ud-font-bold"
                        v-if="txn.action == 'minter'"
                      >
                        minted</span
                      >
                      <span
                        class="ud-w-[50px] ud-text-gray-400 ud-font-bold"
                        v-if="txn.action == 'complete'"
                      >
                        claimed</span
                      >
                      <div class="ud-text-gray-300">
                        <span> {{ main.getTokenName(txn.token) }} </span>
                      </div>
                    </div>
                    <div
                      class="
                        ud-mb-[2px]
                        ud-border-gray-900
                        ud-text-gray-500
                        ud-italic
                        ud-text-[10px]
                        md:ud-text-[8px]
                      "
                    >
                      <span class="">{{ getTimeAgo(txn.timestamp) }}</span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>

          <div class="ud-flex-grow ud-mb-4 noselect">
            <div
              class="
                ud-p-2
                ud-rounded-t-xl
                ud-border
                ud-border-gray-800
                ud-border-b-0
                ud-text-center
              "
              style="
                /* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "
            >
              Aerarium Saturni
            </div>
            <div
              class="
                ud-text-center ud-p-0 ud-mb-0 ud-h-[185px]
                backImgSaturn
                ud-rounded-b-lg
              "
            >
              <claimPrize />
            </div>
          </div>
        </div>

        <div
          class="
            ud-p-2 ud-rounded-t-xl ud-border ud-border-gray-800 ud-border-b-0
            noselect
            ud-text-center
          "
          style="
            /* From https://css.glass */
            background: rgba(35, 9, 66, 0.59);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
          "
        >
          Collection Index
        </div>
        <div
          class="
            ud-opacity-80 ud-p-4 ud-rounded-b ud-grid ud-grid-cols-3 ud-gap-2
            md:ud-grid-cols-3
            xl:ud-grid-cols-7
            ud-border ud-border-gray-800 ud-opacity-1
          "
          style="background-color: rgb(29, 33, 68)"
        >
          <TProgress
            class="ud-mb-0"
            color="orange"
            label="Collection"
            :pct="true"
            :min="0"
            :max="100"
            :value="main.completePct"
            :showValue="false"
          />
          <TProgress
            v-for="[k, v] in Object.entries(this.main.tokenGroups)"
            :link="'#' + k.replaceAll(' ', '')"
            :key="k"
            class="ud-mb-0"
            color="blue"
            :label="k"
            :pct="true"
            :min="0"
            :max="v.totalTokens"
            :value="v.ownedTokens"
            :showValue="false"
          />
        </div>
      </div>
    </section>

    <section
      id="home"
      class="
        ud-relative ud-pb-[30px]
        md:ud-pb-[30px]
        xl:ud-pb-[30px]
        2xl:ud-pb-[30px]
        ud-min-h-full ud-bg-transparent
      "
    >
      <div class="ud-container ud-min-h-full backImg">
        <div
          class="ud-text-white ud-text-center ud-text-xl ud-mt-0"
          v-if="!main.tokenGroups"
        >
          Please wait while loading your collection ...
          <svg class="animate-spin h-5 w-5 mr-3"></svg>
        </div>
        <div
          class="ud-flex ud-flex-wrap ud-mx-[-16px] ud-min-h-full"
          v-if="main"
        >
          <div
            class="noselect ud-w-full ud-px-4 ud-min-h-full"
            v-if="main.tokenGroups"
          >
            <div
              v-for="[g, v] in Object.entries(main.tokenGroups)"
              :key="g"
              class="
                ud-w-full ud-p-1 ud-mb-2 ud-flex ud-flex-col
                border-solid
                ud-border-gray-800 ud-rounded-md
              "
              data-wow-delay=".5s"
            >
              <a
                style="padding-top: 100px; margin-top: -100px"
                :id="g.replaceAll(' ', '')"
              >
              </a>
              <div
                class="
                  noselect
                  ud-text-center
                  ud-opacity-90
                  ud-flex-row
                  ud-flex
                  ud-p-3
                  ud-mb-0
                  ud-rounded-t-xl
                  ud-border
                  ud-border-gray-800
                  ud-border-b-0
                "
                style="
                  /* From https://css.glass */
                  background: rgba(35, 9, 66, 0.59);
                  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                  backdrop-filter: blur(5px);
                  -webkit-backdrop-filter: blur(5px);
                "
              >
                <div
                  class="
                    ud-text-2xl
                    ud-text-white
                    ud-flex-grow
                    ud-font-bold
                    ud-text-left
                    outline
                    ud-ml-2
                  "
                  style="font-family: 'RomanSD'"
                >
                  {{ g }}
                </div>

                <div
                  class="ud-text-gray-400 ud-font-bold ud-text-right ud-text-xl"
                >
                  {{ v.ownedTokens }}/{{ v.totalTokens }}
                </div>
              </div>
              <div
                class="
                  ud-opacity-90
                  ud-border
                  ud-items-center
                  ud-justify-items-center
                  ud-justify-center
                  ud-content-center
                  ud-flex
                  ud-flex-wrap
                  ud-rounded-b-xl
                  ud-border-gray-800
                "
                style="background-color: #1d2144"
              >
                <coinCard
                  v-for="n in v.tokens"
                  :key="n.token_id + n.name"
                  :coinName="n.name"
                  :coinDesc="n.desc"
                  :coinRarity="n.rarity"
                  :coinImg="main.CDN + '/coins/' + n.token_id + '.gif'"
                  :coinG="n.g"
                  :owned="n.own"
                  :balance="n.balance"
                  class="ud-m-2"
                ></coinCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
@media (min-width: 340px) {
  .xs\:ud-min-w-\[380px\] {
    min-width: 380px;
  }
  .xs\:ud-min-w-\[220px\] {
    min-width: 220px;
  }
}
.cristal {
  /* From https://css.glass */
  background: rgba(35, 9, 66, 0.59);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.colIndexBack {
  background-color: #1d2144;
  background-blend-mode: overlay !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/backgrounds/collection_index.png");
}

.rankBack {
  background-color: #1d2144;
  background-blend-mode: overlay !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/backgrounds/rank.png");
}
.txnsBack {
  background-color: #1d2144;
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/backgrounds/tezos-logo.svg");
}

.rankingBack {
  background-color: #1d2144;
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/backgrounds/ranking_back.png");
}

.backImgSaturn {
  /*background-color: #0d133e;*/
  background-color: #1d2144;
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/backgrounds/temple_of_saturn.png");
}

.backImg {
  background-image: url("https://delphin.fra1.cdn.digitaloceanspaces.com/caesars/0.jpeg");
  background-color: #0d133e;
  background-blend-mode: overlay;
  background-repeat: no-repeat !important;
  background-size: cover;
}

.outline {
  color: black !important;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.outlineGray {
  -webkit-text-fill-color: yellow; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: darkslategray;
}
</style>
<script>
import numeral from "numeral";
import coinCard from "../components/coinCard.vue";
import TProgress from "../components/ui/TProgress.vue";
import claimPrize from "../components/claimPrize.vue";
import moment from "moment";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);

export default {
  name: "TCollection",
  components: {
    coinCard,
    TProgress,
    claimPrize,
  },
  data: () => ({
    main: undefined,
    ranking: [],
    latest_txns: [],
    userRank: "-",
    userScore: "-",
    userUniqueTokens: 0,
    userCompletion: 0,
  }),
  computed: {},
  watch: {
    latest_txns: {
      handler() {
        // pass
      },
      immediate: true,
      deep: true,
    },
    ranking: {
      handler() {
        // pass
      },
      immediate: true,
      deep: true,
    },
  },
  created: async function () {
    window.scrollTo(0, 0);
    this.main = this.$router.app.$children[0];
    await this.main.getBalances();
    this.$forceUpdate();
    this.getRanking();
    this.getLatestTransactions();
    this.main.rankingUpdateTimer = setInterval(() => {
      console.log("Updating ranking and latest transactions");
      this.getRanking();
      this.getLatestTransactions();
    }, 10000);
  },
  mounted: async function () {
    let navbarToggler = document.querySelector("#navbarToggler");
    const navbarCollapse = document.querySelector("#navbarCollapse");
    navbarToggler.classList.remove("navbarTogglerActive");
    navbarCollapse.classList.add("ud-hidden");
    //console.log(this.main.tokenGroups);
    const pageLink = document.querySelectorAll(".menu-scroll");

    try {
      pageLink.forEach((elem) => {
        elem.addEventListener("click", (e) => {
          e.preventDefault();

          try {
            document.querySelector(elem.getAttribute("href")).scrollIntoView({
              behavior: "smooth",
              offsetTop: 1 - 60,
            });
          } catch (e) {
            //pass
          }
        });
      });
    } catch (e) {
      //pass
    }
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.main.rankingUpdateTimer);
    next();
  },
  methods: {
    getTimeAgo(t) {
      let timeAgo = new TimeAgo("en-US");
      return timeAgo.format(new moment(t).toDate());
    },
    getRanking() {
      let x = Math.floor(Math.random() * 1e20 + 1);
      fetch("ranking.json?r=" + x).then((response) => {
        response.json().then((data) => {
          this.ranking = [];
          Object.keys(data).forEach((a) => {
            if (a == this.main.activeAccount) {
              this.userScore = data[a].rarityScore;
              this.userUniqueTokens = data[a].uniqueTokens;
              this.userCompletion = data[a].completion;
            }
            this.ranking.push({
              addr: a,
              completion: data[a].completion,
              unique_tokens: data[a].uniqueTokens,
              score: data[a].rarityScore,
            });
          });

          this.ranking.sort((a, b) => a.score - b.score).reverse();
          for (var i = 0; i < this.ranking.length; i++) {
            if (this.ranking[i].addr == this.main.activeAccount)
              this.userRank = i + 1;
          }
        });
      });
    },
    getLatestTransactions() {
      let x = Math.floor(Math.random() * 1e20 + 1);
      fetch("latest_txns.json?r=" + x).then((response) => {
        response.json().then((data) => {
          this.latest_txns = data;
        });
      });
    },
    getNumber(n) {
      return numeral(n).format("0,0");
    },
    getNumber1dp(n) {
      return numeral(n).format("0,0.0");
    },
    getNumber0dp(n) {
      return numeral(n).format("0,0");
    },
    getNumberWithOrdinal(n) {
      var s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return numeral(n).format("0,0") + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRarityColor(rarity) {
      return this.main.getRarityColor(rarity);
    },
  },
};
</script>