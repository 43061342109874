<template>
  <div class="ud-flex ud-rounded-md ud-items-center ud-bg-blue-800">
    <div class="ud-text-gray-100 ud-flex-grow noselect">
      <div class="ud-flex-grow ud-text-xs ud-text-right ud-mr-1 ud-ml-2" v-if="showValue">
        {{ v }}
      </div>
    </div>
    <div class="ud-pr-1 ud-p-0 ud-cursor-pointer ud-flex ud-flex-col">
      <div @click="plus()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="ud-text-gray-300 hover:ud-text-gray-100 ud-h-3 ud-w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div @click="minus()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="ud-text-gray-300 hover:ud-text-gray-100 ud-h-3 ud-w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped>
.noselect {
  outline: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
</style>


<script>
export default {
  name: "vNumericUpDown",
  data: () => ({
    v: 1,
  }),
  computed: {
    value: {
      // getter
      get() {
        return this.v;
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.v = newValue;
      },
    },
  },
  props: {
    max: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    showValue: {
      type: Boolean,
      default: true,
    },
    increment: {
      type: Number,
      default: 1.0,
    },
  },
  mounted() {
    this.v = this.value;
  },
  updated() {
    this.v = this.value;
  },
  methods: {
    plus() {
      if (this.v < this.max)
        this.v =
          Math.round(
            100 * (parseFloat(this.value) + parseFloat(this.increment))
          ) / 100;
      this.$emit("update:value", this.v);
      this.$emit("update", this.v);
    },
    minus() {
      var sub =
        Math.round(100 * parseFloat(this.value - parseFloat(this.increment))) /
        100;     
      if (sub <= this.min) sub = this.min;
      this.v = parseFloat(sub);
      //if (this.value >= parseFloat(this.increment)) this.v = sub;
      this.$emit("update:value", this.v);
      this.$emit("update", this.v);
    },
    clear() {
      this.v = 1;
    },
  },
};
</script>