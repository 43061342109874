<template>
  <flipCard ref="flipCard">
    <div v-on:mouseleave="onLeave()" v-on:mouseover="onOver()" slot="front">
      <span
        v-if="newCard"
        class="
          ud-flex ud-absolute ud-top-4 ud-right-9 ud-h-1 ud-w-1 ud-text-xs
          noselect
          ud-text-gray-600
        "
        ><span class="ud-text-[8px] ud-mt-[-6px] ud-ml-[8px]">NEW</span>
        <span
          class="
            ud-animate-[ping_3s_ease-in-out_infinite]
            ud-absolute
            ud-inline-flex
            ud-h-full
            ud-w-full
            ud-rounded-full
            ud-bg-indigo-700
            ud-text-indigo-700
            ud-opacity-75
            ud-text-[8px]
          "
        ></span>

        <span
          class="
            ud-relative
            ud-text-xs
            ud-inline-flex
            ud-rounded-full
            ud-h-1
            ud-w-1
            ud-bg-orange-400
          "
        ></span>
      </span>
      <div
        v-on:mouseover="showG = true"
        v-on:mouseleave="showG = false"
        class="
          ud-rounded-lg
          ud-w-[285px]
          ud-h-[380px]
          ud-flex
          ud-flex-col
          ud-p-4
          ud-pt-2
          ud-content-center
          ud-items-center
          ud-border
          ud-border-gray-800
        "
        v-bind:class="{
          'not-owned-card': !owned,
          rarityA: owned,
          /*rarityH: owned && coinRarity == 'H',
          rarityG: owned && coinRarity == 'G',
          rarityF: owned && coinRarity == 'F',
          rarityE: owned && coinRarity == 'E',
          rarityD: owned && coinRarity == 'D',
          rarityC: owned && coinRarity == 'C',
          rarityB: owned && coinRarity == 'B',
          rarityA: owned && coinRarity == 'A',*/
        }"
      >
        <div class="noselect ud-flex ud-flex-row ud-mt-3">
          <div
            class="ud-flex-grow ud-text-[18px] outline"
            style="font-family: 'RomanSD'"
          >
            {{ splitName()[0] }}
          </div>
        </div>
        <div>
          <div
            class="
              noselect
              ud-flex-grow ud-text-[10px] ud-mt-[-4px]
              outline-gray
              ud-border-b ud-pb-1 ud-border-gray-800
            "
            style="font-family: 'RomanSD'"
          >
            {{ splitName()[1] }}
          </div>
        </div>
        <div
          v-if="!owned && coinG && showG"
          class="
            ud-transition-all
            noselect
            ud-max-w-[140px]
            ud-opacity-20
            ud-text-center
            ud-mt-24
            ud-pt-0
            ud-text-xs
            ud-uppercase
          "
        >
          <span class="ud-text-gray-400 ud-text-md ud-font-bold"
            >CAN BE OBTAINED FROM {{ coinG }}</span
          >
        </div>
        <div
          class="
            ud-mt-[-10px]
            ud-items-center
            ud-justify-items-center
            ud-justify-center
            ud-content-center
            ud-flex
            ud-flex-grow
          "         
        >
          <!--<video ref="coinVideo" width="220" height="220" loop autoplay="autoplay"
          class="ud-opacity-100 noselect"
          v-show="(owned && viewImage) && (isFirefox & mouseOver)"
          style="width: 220px; height: 220px"
          >
            <source ref="videoSource" type="video/webm" />
          </video>-->

          <LazyVideo
            ref="coinVideo"
            :attrs="{
              controls: false,
              playsinline: true,
              loop: true,
              autoplay: false,
            }"
            :poster="coinImg + '.png'"
            :pauseOnExit="true"
            class="ud-opacity-100 noselect"
            v-if="owned && viewImage && isFirefox && !user3d"
            style="width: 220px; height: 220px"
            loadOffset="100%"
            :src="coinImg + '.webm'"
          />
          <div v-if="user3d && owned">
            <model-viewer
              style="width: 220px; height: 220px"
              :src="getGLTF(coinImg)"             
              shadow-intensity="1"
              camera-controls
              touch-action="pan-y"
              disable-pan
              @progress="modelLoadProgress = $event.detail.totalProgress * 100"
            >
              <div slot="progress-bar">
                <TProgress
                  v-if="modelLoadProgress < 100"
                  min="0"
                  max="100"
                  :value.sync="modelLoadProgress"
                  class="ud-z-2000 ud-mt-[85px] ud-px-8"
                  :showValue="true"
                  color="blue"
                  pct="true"
                  label="Loading model..."
                />
              </div>
            </model-viewer>
          </div>

          <img
            class="ud-opacity-100 noselect"
            v-if="owned && viewImage && !isFirefox && !user3d"
            v-lazy="coinImg"
            style="width: 220px; height: 220px"
          />

          <!--<img
            class="ud-opacity-100 noselect"
            v-show="(owned && viewImage) && (isFirefox & !mouseOver)"
            v-lazy="coinImg + '.png'"
            style="width: 220px; height: 220px"
          />-->

          <!--<coinPlayer
            class="ud-opacity-100 noselect"          
            :src="coinImg"
            v-if="owned && viewImage"
            :width="220"
            :height="220"
          />-->
        </div>

        <div
          v-if="owned && balance > 1"
          class="noselect ud-text-right ud-mt-0 ud-pb-0"
        >
          <span class="ud-text-xs ud-text-gray-400">x</span
          ><span class="ud-text-gray-400 ud-text-md ud-font-bold">{{
            balance
          }}</span>
        </div>
      </div>
      <div
         v-if="owned"
        @click="flipCard()"
        class="ud-text-gray-600 hover:ud-text-gray-300 hover:ud-cursor-pointer"       
        style="position: absolute; bottom: 18px; right: 40px; width: 26px"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="ud-w-6 ud-h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      </div>
      <div
      v-if="owned"
        @click="toggle3D()"
        class="hover:ud-text-gray-300 hover:ud-cursor-pointer"
        style="position: absolute; bottom: 18px; left: 40px; width: 26px"
        :class="{ 'ud-text-indigo-800 hover:ud-text-indigo-500': user3d, 'ud-text-gray-600': !user3d }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="ud-w-6 ud-h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
          />
        </svg>
      </div>
    </div>

    <div  v-on:mouseleave="onLeave()" slot="back">
      <div
        v-on:click="flipCard()"
        class="
          ud-rounded-lg
          ud-w-[285px]
          ud-h-[380px]
          ud-flex
          ud-flex-col
          ud-p-4
          ud-text-white
          ud-text-[10px]
          ud-text-justify
          ud-border
          ud-border-gray-800
          noselect
        "
        v-bind:class="{
          'not-owned-card': !owned,
          rarityA: owned,
        }"
        style="color: #949db1"
      >
        {{ coinDesc }}
      </div>
    </div>
  </flipCard>
</template>

<style scoped>
img[lazy="loading"] {
  height: 80px !important;
  width: 80px !important;
  margin-top: -20px;
}

img[lazy="loaded"] {
  height: 220px;
  width: 220px;
}

.not-owned {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: default;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: default;
}

.not-owned-title {
  color: darkgrey !important;
  background-color: transparent;
}

.rarityH {
  background-color: #9dc5c3;
  background-image: linear-gradient(315deg, #9dc5c3 0%, #5e5c5c 74%);
}

.rarityG {
  background-color: #2234ae;
  background-image: linear-gradient(315deg, #2234ae 0%, #191714 74%);
}

.rarityF {
  background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #04619f 74%);
}

.rarityE {
  background-color: #2f4353;
  background-image: linear-gradient(315deg, #d2ccc4 0%, #2f4353 74%);
}

.rarityD {
  background-color: #baba00;
  background-image: linear-gradient(315deg, #baba00 0%, #ba1313 74%);
}

.rarityC {
  background-color: #63d471;
  background-image: linear-gradient(315deg, #63d471 0%, #233329 74%);
}

.rarityB {
  background-color: #a55c1b;
  background-image: linear-gradient(315deg, #a55c1b 0%, #000000 74%);
}

.rarityA {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

.not-owned-card {
  background-color: #25426b;
}

.outline {
  color: black !important;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.outline-gray {
  color: black !important;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: darkgray;
}

.outlineDesc {
  color: white !important;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
</style>

<script>
import flipCard from "../components/flipCard.vue";
import TProgress from "./ui/TProgress.vue";
//import coinPlayer from "../components/ui/coinPlayer.vue";

export default {
  name: "coinCard",
  components: {
    flipCard,
    TProgress,
  },

  data: () => ({
    isFirefox: false,
    mouseOver: false,
    showG: false,
    v: 0,
    viewImage: true,
    user3d: false,
    modelLoadProgress: 0,
  }),
  props: {
    value: {
      default: 0,
    },
    coinName: {
      default: "",
    },
    balance: {
      default: 0,
    },
    coinImg: {
      default: "",
    },
    coinDesc: {
      default: "",
    },
    coinRarity: {
      default: "F",
    },
    owned: {
      default: false,
    },
    coinG: {
      default: undefined,
    },
    newCard: {
      default: false,
    },
  },
  mounted() {
    this.main = this.$router.app.$children[0];
    this.isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;    
    ///this.isFirefox = true;
  },
  updated() {},
  methods: {
    getGLTF(v) {
      /// coinImg ends with coins/xxx.gif so we need to get string between the slash and the dot
      let s = v.lastIndexOf("/");
      let e = v.lastIndexOf(".");
      let r = this.main.CDN + "/gltf" + v.substring(s,e) + ".gltf";
      return r;
    },
    modelProgress(e) {
      console.log(100 * e.detail.totalProgress);
      this.modelLoadProgress = 100 * e.detail.totalProgress;
    },
    toggle3D() {
      this.user3d = !this.user3d;
      this.modelLoadProgress = 0;
    },   
    flipCard() {
      if (this.owned)
        this.$refs["flipCard"].flipped = !this.$refs["flipCard"].flipped;
    },
    onOver() {
      if (this.isFirefox) this.$refs.coinVideo.$el.play();
      this.mouseOver = true;
    },
    onLeave() {
      this.mouseOver = false;
      this.user3d = false;
      if (this.isFirefox) {
        this.$refs.coinVideo.$el.pause();
        this.$refs.coinVideo.$el.currentTime = 0;
      }
    },
    splitName() {
      var a = this.coinName.split(" - ");
      var r = [];
      a.forEach((t) => {
        r.push(t.trim());
      });
      if (r.length < 2) {
        r.push(a[0]);
      }
      return r;
    },
  },
};
</script>