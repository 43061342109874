<template>
  <div
    class="
      ud-flex
      ud-flex-col
      ud-text-center
      ud-h-full
      ud-items-center
      ud-justify-center
      ud-mt-0
      ud-mx-0
      ud-opacity-100
      ud-mb-0    
      ud-bg-transparent    
    "
  >
    <div class="ud-flex ud-h-full ud-flex-col">
      <div
        class="
          ud-text-body
          ud-opacity-100
          ud-text-xl
          ud-flex-grow
          ud-flex
          ud-flex-col
        "
      ></div>

      <div class="ud-w-full">
        <div
          class="ud-w-[360px] ud-h-[360px]"
          ref="canvas"
        ></div>
        <div v-if="!rolling && !rollingDone" class="ud-flex ud-gap-2">
          <button
            v-for="b in availableRolls" v-bind:key="b"
            v-on:click="roll(b)"
            class="
              disabled:ud-opacity-40
              ud-items-center
              ud-justify-center
              ud-block-inline
              ud-text-base
              ud-font-bold
              ud-text-white
              ud-bg-primary
              ud-py-2
              ud-px-2
              ud-w-full
              ud-mt-2
              hover:ud-shadow-signUp hover:ud-bg-opacity-90
              ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
            "
          >
          <div><span class="ud-text-gray-300">{{b}}</span> tez</div>
           
          </button>        
        </div>
        <div v-if="rolling" class="ud-flex ud-gap-2">
          <button
            v-if="!readyToRoll"       
            :disabled="!readyToRoll"    
            v-on:click="roll(b)"
            class="
              disabled:ud-opacity-40
              ud-items-center
              ud-justify-center
              ud-block-inline
              ud-text-base
              ud-font-bold
              ud-text-white
              ud-bg-primary
              ud-py-2
              ud-px-2
              ud-w-full
              ud-mt-2
              hover:ud-shadow-signUp hover:ud-bg-opacity-90
              ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
            "
          >
          <svg
              class="
                ud-inline-block ud-animate-spin
                ud-ml--1
                ud-mr-1 ud-mb-[2px] ud-h-5 ud-w-5 ud-text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="ud-opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="ud-opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          Getting ready to roll...
           
          </button>  
          <button
            v-if="readyToRoll || actualRolling"  
            :disabled="actualRolling"         
            v-on:click="randomDiceThrow(rollValues);"
            class="
              disabled:ud-opacity-40
              ud-items-center
              ud-justify-center
              ud-block-inline
              ud-text-base
              ud-font-bold
              ud-text-white
              ud-bg-primary
              ud-py-2
              ud-px-2
              ud-w-full
              ud-mt-2
              hover:ud-shadow-signUp hover:ud-bg-opacity-90
              ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
            "
          >
          <span v-if="!actualRolling">Ready to Roll</span>
          <span v-if="actualRolling">Alea Iacta Est</span>
          </button>
        
        </div>
        <div v-if="rollingDone" class="ud-flex ud-gap-2">
         
          <button                            
            v-on:click="rollingDone = false"
            class="
              disabled:ud-opacity-40
              ud-items-center
              ud-justify-center
              ud-block-inline
              ud-text-base
              ud-font-bold
              ud-text-white
              ud-bg-primary
              ud-py-2
              ud-px-2
              ud-w-full
              ud-mt-2
              hover:ud-shadow-signUp hover:ud-bg-opacity-90
              ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
            "
          >
          <span v-if="amountWon > 0">You won <span class="ud-text-gray-300">{{amountWon}}</span> tez</span>
          <span v-else>Better luck next time</span>
          </button>
        
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: default;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script>
import numeral from "numeral";
import * as THREE from "three";
import * as CANNON from "cannon";
import { DiceManager, DiceD6 } from "../dice.js";
import TrackballControls from "three-trackballcontrols";

export default {
  name: "diceRoller",
  components: {},
  props: {},
  data: function () {
    // SCENE
    const scene = new THREE.Scene();

    // CAMERA
    var SCREEN_WIDTH = 358,
      SCREEN_HEIGHT = 358;
    var VIEW_ANGLE = 45,
      ASPECT = SCREEN_WIDTH / SCREEN_HEIGHT,
      NEAR = 0.1,
      FAR = 200;
    const camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR);
    scene.add(camera);
    camera.position.set(10, 65, 40);

    // RENDERER
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // CONTROLS

    let ambientLight = new THREE.AmbientLight("#ffffff", 0.3);
    scene.add(ambientLight);

    let directionalLight = new THREE.DirectionalLight("#ffffff", 0.5);
    directionalLight.position.x = 0;
    directionalLight.position.y = 0;
    directionalLight.position.z = 0;
    scene.add(directionalLight);

    let light = new THREE.SpotLight(0xefdfd5, 1.3);
    light.position.y = 100;
    light.target.position.set(0, 0, 0);
    light.castShadow = true;
    light.shadow.camera.near = 50;
    light.shadow.camera.far = 110;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    scene.add(light);

    // FLOOR
    var floorMaterial = new THREE.MeshPhongMaterial({
      color: "#050525",
      side: THREE.DoubleSide,
    });
    var floorGeometry = new THREE.PlaneGeometry(1500, 1500, 10, 10);
    var floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.receiveShadow = true;
    floor.rotation.x = Math.PI / 2;
    scene.add(floor);

    // SKYBOX/FOG
    /*var skyBoxGeometry = new THREE.BoxGeometry(10000, 10000, 10000);
    var skyBoxMaterial = new THREE.MeshPhongMaterial({
      color: 0x000000,
      side: THREE.BackSide,
    });
    var skyBox = new THREE.Mesh(skyBoxGeometry, skyBoxMaterial);
    scene.add(skyBox);
    scene.fog = new THREE.FogExp2(0x9999ff, 0.00025);*/

    ////////////
    // CUSTOM //
    ////////////
    const world = new CANNON.World();

    world.gravity.set(0, -9.82 * 20, 0);
    world.broadphase = new CANNON.NaiveBroadphase();
    world.solver.iterations = 16;

    DiceManager.setWorld(world);

    //Floor
    let floorBody = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Plane(),
      material: DiceManager.floorBodyMaterial,
    });
    floorBody.quaternion.setFromAxisAngle(
      new CANNON.Vec3(1, 0, 0),
      -Math.PI / 2
    );
    world.add(floorBody);

    //Walls
    let wallBody = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Plane(),
      material: DiceManager.barrierBodyMaterial,
    });
    wallBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), Math.PI);
    wallBody.position.z = 26;
    world.add(wallBody);

    let wallBody2 = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Plane(),
      material: DiceManager.barrierBodyMaterial,
    });
    wallBody2.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 0, 1), Math.PI);
    wallBody2.position.z = -28;
    world.add(wallBody2);

    let wallBody3 = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Plane(),
      material: DiceManager.barrierBodyMaterial,
    });
    wallBody3.quaternion.setFromAxisAngle(
      new CANNON.Vec3(0, 1, 0),
      -Math.PI / 2
    );
    wallBody3.position.x = 20;
    world.add(wallBody3);

    let wallBody4 = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Plane(),
      material: DiceManager.barrierBodyMaterial,
    });
    wallBody4.quaternion.setFromAxisAngle(
      new CANNON.Vec3(0, 1, 0),
      Math.PI / 2
    );
    wallBody4.position.x = -20;
    world.add(wallBody4);

    const bone = new THREE.TextureLoader().load("images/dice/1/bone.png");
    bone.repeat.set(1.5, 1.5);
    bone.offset.set(-0.25, -0.25);

    const face1 = new THREE.TextureLoader().load("images/dice/1/1.png");
    face1.repeat.set(1.5, 1.5);
    face1.offset.set(-0.25, -0.25);

    const face2 = new THREE.TextureLoader().load("images/dice/1/2.png");
    face2.repeat.set(1.7, 1.7);
    face2.offset.set(-0.35, -0.35);

    const face3 = new THREE.TextureLoader().load("images/dice/1/3.png");
    face3.repeat.set(1.7, 1.7);
    face3.offset.set(-0.35, -0.35);

    const face4 = new THREE.TextureLoader().load("images/dice/1/4.png");
    face4.repeat.set(1.7, 1.7);
    face4.offset.set(-0.35, -0.35);

    const face5 = new THREE.TextureLoader().load("images/dice/1/5.png");
    face5.repeat.set(1.7, 1.7);
    face5.offset.set(-0.35, -0.35);

    const face6 = new THREE.TextureLoader().load("images/dice/1/6.png");
    face6.repeat.set(1.7, 1.7);
    face6.offset.set(-0.35, -0.35);

    const dieMaterial = [
      new THREE.MeshPhongMaterial({
        map: bone,
        transparent: false,
      }),
      new THREE.MeshPhongMaterial({
        map: bone,
        transparent: false,
      }),
      new THREE.MeshPhongMaterial({
        map: face1,
        transparent: false,
      }),
      new THREE.MeshPhongMaterial({
        map: face2,
        transparent: false,
      }),
      new THREE.MeshPhongMaterial({
        map: face3,
        transparent: false,
      }),
      new THREE.MeshPhongMaterial({
        map: face4,
        transparent: false,
      }),
      new THREE.MeshPhongMaterial({
        map: face5,
        transparent: false,
      }),
      new THREE.MeshPhongMaterial({
        map: face6,
        transparent: false,
      }),
    ];

    /// DICE
    var colors = ["#ff0000", "#ffff00", "#00ff00", "#0000ff", "#ff00ff"];
    var dice = [];
    for (var i = 0; i < 3; i++) {
      var die = new DiceD6({ size: 6, backColor: colors[i] });
      var obj = die.getObject();
      obj.material = dieMaterial;

      //console.log(obj);
      scene.add(obj);
      dice.push(die);
    }

    return {
      scene: scene,
      camera: camera,
      renderer: renderer,
      world: world,
      controls: [],
      dice: dice,
      availableRolls: [0.25, 0.5, 1],
      rolling: false,
      readyToRoll: false,
      actualRolling: false,
      rollingDone: false,
      winner: false,
      amountBet: 0,
      amountWon: 0,
      rollValues: [0,0,0]
    };
  },
  created: async function () {
    this.main = this.$router.app.$children[0];
  },
  mounted: async function () {
    this.$refs.canvas.appendChild(this.renderer.domElement);
    this.controls = new TrackballControls(this.camera, this.$refs.canvas);
    this.controls.noPan = true;
    this.controls.noRotate = true;
    this.controls.noZoom = true;

    this.animate();
    //this.createDice();
  },
  methods: {
    createDice() {
      /*var materials1 = [
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/1/1.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/1/2.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/1/3.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/1/4.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/1/5.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/1/6.png"),
        }),
      ];

      var materials2 = [
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/2/1.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/2/2.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/2/3.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/2/4.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/2/5.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/2/6.png"),
        }),
      ];

      var materials3 = [
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/3/1.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/3/2.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/3/3.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/3/4.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/3/5.png"),
        }),
        new THREE.MeshLambertMaterial({
          map: new THREE.TextureLoader().load("images/dice/3/6.png"),
        }),
      ];

      var diceBodyMaterial = new CANNON.Material();

      var dice1 = new THREE.Mesh(
        new RoundedBoxGeometry(5, 5, 5, 2, 0.5),
        materials1
      );
      dice1.reveiceShadow = true;
      dice1.castShadow = true;
      dice1.diceObject = this;
      dice1.body = new CANNON.Body({
        mass: this.mass,
        shape: dice1.geometry.cannon_shape,
        material:diceBodyMaterial,
      });
      dice1.body.linearDamping = 0.1;
      dice1.body.angularDamping = 0.1;
      //this.scene.add(dice1);
      this.world.add(dice1.body);
      this.dice.push(dice1);

      var dice2 = new THREE.Mesh( new RoundedBoxGeometry( 5, 5, 5, 2, 0.5 ), materials2 );      
      var dice3 = new THREE.Mesh( new RoundedBoxGeometry( 5, 5, 5, 2, 0.5 ), materials3 );     
      console.log(dice2, dice3);*/
    },
    estimate(amt) {
      return new Promise((resolve, reject) => {
        this.main.tezos.setWalletProvider(this.main.wallet);
        console.log(this.main.wallet);
        this.main.tezos.contract
          .at(this.main.TESSERAE_ADDRESS)
          .then((contract) => {
            return contract.methods.roll(null).toTransferParams({
              source: this.main.activeAccount,
              amount: Math.floor(10000*amt*1.05)/10000
            });
          })
          .then((op) => {
            console.log(`Estimating the smart contract call : `);
            console.log(op);
            return this.main.tezos.estimate.transfer(op);
          })
          .then((est) => {
            console.log(est);
            console.log(`
            burnFeeMutez : ${est.burnFeeMutez}, 
            gasLimit : ${est.gasLimit}, 
            minimalFeeMutez : ${est.minimalFeeMutez}, 
            storageLimit : ${est.storageLimit}, 
            suggestedFeeMutez : ${est.suggestedFeeMutez}, 
            totalCost : ${est.totalCost}, 
            usingBaseFeeMutez : ${est.usingBaseFeeMutez}`);
            resolve(est);
          })
          .catch((error) => {
            console.table(`Error: ${JSON.stringify(error, null, 2)}`);
            reject(error);
          });
      });
    },
    async roll(amt) {
      this.amountBet = amt;
      this.amountWon = 0;
    
      this.$emit("roll_start");
      this.rolling = true;
      this.showError = false;
      this.error = undefined;
      this.main.tezos.setWalletProvider(this.main.wallet);

      var gasLimit = 10000;
      var storageLimit = 150;
      var fee = 8000;

      try {
        var est = await this.estimate(amt);
        gasLimit = Math.max(2 * est.gasLimit, 10000);
        storageLimit = Math.max(2 * est.storageLimit, 150);
        fee = Math.max(1.5 * est.suggestedFeeMutez, 5000);
      } catch (err) {
        console.log("Estimation not available. Using default values.");
      }

      this.main.tezos.wallet
        .at(this.main.TESSERAE_ADDRESS)
        .then((contract) => {
          return contract.methods.roll().send({
            storageLimit: storageLimit,
            fee: fee,
            gasLimit: gasLimit,
            amount: Math.floor(10000*amt*1.05)/10000,
          });
        })
        .then(async (op) => {
          console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(1);
          return op.opHash;
        })
        .then(async (hash) => {
          console.log("OK: " + hash);
          
          console.log("Retrieving current rolls");
          const newVal = await this.getRolls();
          //if (prevBal.length < newVal.length) {
            this.readyToRoll = true;
            this.rollValues = newVal.d;            
          //}          
        })
        .catch((error) => {
          this.rolling = false;
          this.readyToRoll = false;
          this.error = error;
          this.showError = true;
          console.log(error);
          console.log("Error: " + JSON.stringify(error, null, 2));
          this.$emit("error", error);
        });
    },
    getRolls() {
      return new Promise((resolve, reject) => {       
        this.main.tezos.contract
          .at(this.main.TESSERAE_ADDRESS)
          .then((contract) => {   
            //console.log(contract.views);               
            return contract.contractViews.last_roll_of( this.main.activeAccount ).executeView({
              viewCaller: this.main.activeAccount
            });
          })
          .then((res) => {
            //console.log(JSON.parse(JSON.stringify(res)));
            resolve(JSON.parse(JSON.stringify(res)));
          })
          .catch((error) => {
            console.log(error);
            ///console.log("Error: " + JSON.stringify(error, null, 2));
            reject();
          });
      });
    },
    randomDiceThrow(values = [
      Math.floor(Math.random() * 6 + 1),
      Math.floor(Math.random() * 6 + 1),
      Math.floor(Math.random() * 6 + 1)
    ]) {

      this.actualRolling = true;

      var diceValues = [];

      for (var i = 0; i < this.dice.length; i++) {
        let yRand = Math.random() * 20;
        this.dice[i].getObject().position.x = -10 - (i % 3) * 1.5;
        this.dice[i].getObject().position.y = 20 + Math.floor(i / 3) * 1.5;
        this.dice[i].getObject().position.z = -5 + (i % 3) * 1.5;
        this.dice[i].getObject().quaternion.x =
          ((Math.random() * 90 - 45) * Math.PI) / 180;
        this.dice[i].getObject().quaternion.z =
          ((Math.random() * 90 - 45) * Math.PI) / 180;
        this.dice[i].updateBodyFromMesh();
        let rand = Math.random() * 5;
        this.dice[i]
          .getObject()
          .body.velocity.set(35 + rand, 45 + yRand, 45 + rand);
        this.dice[i]
          .getObject()
          .body.angularVelocity.set(
            40 * Math.random() - 10,
            40 * Math.random() - 10,
            40 * Math.random() - 10
          );
        
        //console.log("Dice " + (parseInt(i) + parseInt(1)).toString() + ": " + values[i]);
        diceValues.push({
          dice: this.dice[i],
          value: values[i],
        });
      }

      DiceManager.prepareValues(diceValues);
     
      setTimeout( () => {
        //console.log(values);      
        if ( (values[0] == values[1]) && (values[0]== values[2])) {
          this.amountWon = 10*this.amountBet;
        }
        else if (parseInt(values[0]) + parseInt(values[1]) + parseInt(values[2]) == 12) {
          this.amountWon = 5*this.amountBet;
        }
        else {
          this.amountWon = 0;
        }
        this.actualRolling = false;
        this.readyToRoll = false;
        this.rolling = false;
        this.rollingDone = true;
        this.$emit("roll_success");
      }, 3000 );
    },
    animate() {
      this.updatePhysics();
      this.render();
      this.update();
      requestAnimationFrame(this.animate);
    },
    updatePhysics() {
      //console.log(this.world);
      this.world.step(1.0 / 60.0);
      for (var i in this.dice) {
        this.dice[i].updateMeshFromBody();
      }
    },
    update() {
      this.controls.update();
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    getNumber(n) {
      return numeral(n).format("0,0.0");
    },
    canClaim() {
      if (this.main.canClaimPrize && !this.main.prize_given) {
        return true;
      }
      return false;
    },
    claim_prize() {
      this.claiming = true;
      this.main.tezos.setWalletProvider(this.main.wallet);
      this.main.tezos.wallet
        .at(this.main.CONTRACT_ADDRESS)
        .then((contract) => {
          return contract.methods.claim_prize().send();
        })
        .then(async (op) => {
          console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(1);
          return op.opHash;
        })
        .then(async (hash) => {
          console.log("SUCCESS:" + hash);
          this.claiming = false;
          this.main.canClaimPrize = false;
          this.main.getBalances();
        })
        .catch((error) => {
          console.log("Error: " + JSON.stringify(error, null, 2));
          this.claiming = false;
        });
    },
  },
};
</script>
