<template>
  <div @focusout="handleFocusOut" tabindex="0" class="ud-text-xs">
    <div
      v-if="els.available.length > 0"
      class="
        ud-flex ud-flex-row ud-text-gray-100
        noselect
        ud-bg-blue-800
        hover:ud-bg-blue-700
        ud-rounded-md ud-p-2
        
      "
      @click="expanded = !expanded"
    >
      <div v-if="v == -1" class="ud-text-left ud-align-middle ud-flex-grow">
        <span class="ud-italic">Select coin...</span>
      </div>
      <div v-else class="ud-text-left ud-align-middle ud-flex-grow">
        {{ els.available[selected].name }}
      </div>
      <div class="ud-text-right">
        <svg
          v-if="!expanded"
          xmlns="http://www.w3.org/2000/svg"
          class="ud-h-4 ud-w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
        <svg
          v-if="expanded"
          xmlns="http://www.w3.org/2000/svg"
          class="ud-h-4 ud-w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M5 15l7-7 7 7"
          />
        </svg>
      </div>
    </div>
    <transition name="slide">
      <div
        v-show="expanded"
        class="
          list          
          ud-max-h-[250px] ud-bg-gray-700 ud-mt-2 ud-rounded ud-overflow-auto ud-shadow-xl
        "
      >
        <div
          class="
            noselect
            block
            ud-z-50
            ud-p-2 ud-text-gray-200
            hover:ud-bg-gray-600 hover:ud-text-white
          "
          v-for="(e) in els.available.filter(t => !els.taken.includes(t.token_id))"
          v-bind:key="e.token_id"
          @click="selectItem(e.token_id)"
        >
          <div class="ud-m-0 ud-p-0">{{ e.name }}</div>
          <div v-if="showBalance" class="ud-p-0 ud-m-0 ud-text-xs">
            Balance: {{ e.bal }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.noselect {
  outline: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: pointer;
}
.list {
  transform-origin: top;
  transition: transform 0s ease-in-out;
  overscroll-behavior: contain;
  position: absolute;
}
.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
</style>

<script>
export default {
  name: "TSelect",
  components: {},
  data: () => ({
    expanded: false,
    v: -1,
  }),
  computed: {
    selected: {
      // getter
      get() {
        return this.v;
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.v = newValue;
      },
    },
  },
  props: {
    showBalance: {
      default: false,
    },
    els: {
      type: Object,
      default: new Object(),
    },
  },
  mounted() {
    //this.$emit("change", this.selected);
  },
  updated() {},
  methods: {
    handleFocusOut() {
      this.expanded = false;
    },
    clear() {
      this.selected = -1;
      this.v = -1;
    },
    selectItem(id) {           
      this.expanded = false;
      for (var i=0; i < this.els.available.length; i++) {
        if (this.els.available[i].token_id == id) this.v = i;
      }      
      this.$emit("change", this.v, this.els.available[this.v].token_id, this.els.available[this.v].bal);
      return;
    },
  },
};
</script>