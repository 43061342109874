<template>
  <div class="ud-w-full">
          <div class="ud-flex ud-justify-between ud-mb-0">
            <span
              class="
                ud-text-[11px] ud-text-blue-700
                dark:ud-text-white
              "
              >
              <span v-if="!link">{{label}}</span>
              <a :href="link" v-else class="hover:ud-underline menu-scroll">{{label}}</a>
              </span
            >
            <span v-if="showValue"
              class="
                ud-text-[11px] ud-text-blue-700
                dark:ud-text-white ud-mt-0
              "
              >
              <span v-if="pct">{{v}}%</span>
              <span v-else>{{value}}/{{max}}</span>
              </span
            >
          </div>
          <div
            class="
              ud-w-full ud-bg-gray-200 ud-rounded-full ud-h-2
              dark:ud-bg-gray-700
            "
          >
            <div
              class="ud-h-2 ud-rounded-full"
              :class="{ orange: (color == 'orange'),  blue: (color == 'blue')}"
              :style="{width: v+'%'}"             
            ></div>           
          </div>
        </div>
  
</template>

<style scoped>
.noselect {
  outline: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.orange {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.blue {
  --tw-text-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-text-opacity))
}
</style>


<script>
export default {
  name: "TProgress",
  data: () => ({
    v: 0
  }),
  props: {  
    showValue: {
      default: true
    },
    color: {
      default: "orange"
    },
    pct: {
      default: false
    },
    label: {
      default: "label"
    },
    min: {
      default: 0
    },
    max: {
      default: 100
    },
    value: {
      default: 50
    },
    link: {
      default: undefined
    }
    
  },
  watch: {
    value: {
      handler() {
        this.v = Math.round(100*(this.value/(this.max-this.min)));
      }

    }
  },
  mounted() {
    this.v = Math.round(100*(this.value/(this.max-this.min)));
  },
  updated() {    
    this.v = Math.round(100*(this.value/(this.max-this.min)));   
  },
  methods: {
   
  },
};
</script>