var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-scroll .ud-rounded-lg ud-w-[132px] ud-h-[40px] ud-flex ud-flex-col ud-p-3 ud-pt-3 ud-content-center ud-justify-center ud-justify-items-center",class:{
    'not-owned-card': !_vm.owned,
    rarityA: _vm.owned,
    /*rarityH: owned && coinRarity == 'H',
        rarityG: owned && coinRarity == 'G',
        rarityF: owned && coinRarity == 'F',
        rarityE: owned && coinRarity == 'E',
        rarityD: owned && coinRarity == 'D',
        rarityC: owned && coinRarity == 'C',
        rarityB: owned && coinRarity == 'B',
        rarityA: owned && coinRarity == 'A',*/
  },attrs:{"href":_vm.href}},[_c('div',{staticClass:"noselect ud-flex ud-flex-row",class:{ '!ud-cursor-pointer': _vm.pointer }},[_c('div',{staticClass:"ud-flex-grow ud-text-[8px] outline ud-mt-0",class:{ '!ud-cursor-pointer': _vm.pointer },staticStyle:{"font-family":"'RomanSD'"}},[_vm._v(" "+_vm._s(_vm.splitName()[0])+" ")])]),_c('div',{staticClass:"ud-flex",class:{ '!ud-cursor-pointer': _vm.pointer }},[_c('div',{staticClass:"noselect ud-flex-grow ud-text-[6px] ud-mt-[-2px] outline-gray",class:{ '!ud-cursor-pointer': _vm.pointer },staticStyle:{"font-family":"'RomanSD'"}},[_vm._v(" "+_vm._s(_vm.splitName()[1])+" ")]),(_vm.showBalance)?_c('div',{staticClass:"ud-text-gray-400 ud-text-right ud-font-bold ud-text-[10px] ud-mt-[-10px] ud-mr-[-6px]"},[_vm._v(" x"),_c('span',{staticClass:"ud-text-[14px]"},[_vm._v(_vm._s(_vm.balance))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }