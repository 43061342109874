import Vue from 'vue'
import VueRouter from 'vue-router'
import THome from '../views/THome.vue'
import TCollection from '../views/TCollection.vue'
import TMinter from '../views/TMinter.vue'
import TMarket from '../views/TMarket.vue'
import TCursus from '../views/TCursus.vue'
import TAureus from '../views/TAureus.vue'
import TTesserae from '../views/TTesserae.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'THome',
    component: THome
  },
  {
    path: '/collection',
    name: 'TCollection',
    component: TCollection
  },
  {
    path: '/coinminter',
    name: 'TMinter',
    component: TMinter
  },
  {
    path: '/market/:id',
    name: 'TMarket',
    component: TMarket
  },
  {
    path: '/cursus-honorum/:id',  
    name: 'TCursus',
    component: TCursus
  },
  {
    path: '/aureus',
    name: 'TAureus',
    component: TAureus
  },
  {
    path: '/games/tesserae',
    name: 'TTesserae',
    component: TTesserae
  }
]

const router = new VueRouter({
  mode: 'abstract',
  routes
})

export default router