<template>
  <div  
  class="ud-flex ud-flex-col 
         ud-content-center ud-text-center ud-items-center"
  >
  <a :id="'claimID' + reward" style="margin-top: -100px; padding-top: 100px"/>
    <div class="noselect" v-if="claimableTitle">
      <p
        class="
          ud-w-[320px]
          ud-text-white
          ud-text-center
          ud-font-bold
          ud-text-xl        
          ud-flex
          ud-flex-col        
          ud-p-4
          ud-m-1
          ud-mb-0      
          ud-opacity-90    
          noselect
          ud-rounded-t-xl ud-border ud-border-gray-800 
        "
        v-html="claimableTitle"
        style="
                /* From https://css.glass */
                background: rgba(35, 9, 66, 0.59);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
              "
      ></p>
    </div>
    <div
      class="
        ud-w-[320px]
        ud-border
        ud-border-t-0
        ud-flex
        ud-flex-col
        ud-rounded-b-lg
        ud-border-gray-800
        ud-p-4
        ud-m-1
        ud-mt-0
        ud-flex-grow
        ud-opacity-90
      "
      style="background-color: #1d2144"
    >
      <div>
        <coinCard
          v-if="reward"
          :coinName="this.main.getTokenName(this.reward)"
          :coinImg="this.main.CDN + '/coins/' + this.reward + '.gif'"
          :coinDesc="this.main.getTokenDesc(this.reward)"
          :coinRarity="this.main.getTokenRarity(this.reward)"
          :balance="coinBalance"
          :owned="main.userBalance[this.reward].balance >= 1 || claimed"
        ></coinCard>
      </div>
      <div
        class="
          ud-justify-center ud-block ud-items-center 
          lg:ud-pr-0
          ud-mt-2
        "
      >
        <button
          @click="complete()"
          :disabled="claiming || !canComplete || disableButton"
          class="
            ud-opacity-100
            disabled:ud-opacity-40
            ud-items-center
            ud-w-full
            ud-justify-center
            ud-block-inline
            ud-text-base
            ud-font-bold
            ud-text-white
            ud-bg-primary
            ud-py-3
            ud-px-8
            hover:ud-shadow-signUp hover:ud-bg-opacity-90
            ud-rounded-md ud-transition ud-ease-in-up ud-duration-300
          "
        >
          <div v-if="!claiming">
            Claim&nbsp;<span class="ud-text-red-400">{{ cost }}&nbsp;tez</span>
          </div>

          <div v-else>
            <svg
              class="
                ud-inline-block ud-animate-spin
                ud-ml--1
                ud-mr-1 ud-mb-[2px] ud-h-5 ud-w-5 ud-text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="ud-opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="ud-opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Claiming
          </div>
        </button>
      </div>
      <div class="ud-flex-grow">
        <p
          class="
            ud-text-body-color ud-leading-relaxed
            md:ud-leading-relaxed
            ud-max-w-[320px]
            ud-text-justify
            ud-p-0
            ud-pt-0
            ud-mb-1
            ud-mt-2
            ud-text-xs
            noselect
          "
          v-html="claimableDesc"
        ></p>
      </div>
      <div v-if="claimableBonus"  class="ud-flex-grow">
      
        <p
          class="
            ud-text-body-color ud-leading-relaxed
            md:ud-leading-relaxed
            ud-max-w-[320px]           
            ud-p-0
            ud-pt-0
            ud-mb-4
            ud-mt-2
            ud-text-xs
            noselect 
            ud-text-center           
          "
          v-html="claimableBonus"
        ></p>
      </div>
      <h4
        class="
          ud-text-white ud-text-[12px] ud-pb-0 ud-mt-2 ud-mb-1 ud-font-bold ud-border-b ud-border-gray-700
        "
      >
        Requirements
      </h4>
      <div
       class="ud-flex ud-flex-wrap  ud-justify-center ud-flex-row ud-m-0 ud-p-0"
      >
         <div
       
        v-for="[k, v] in Object.entries(reqs)"
        v-bind:key="k"
        class="ud-flex ud-flex-wrap ud-flex-row ud-m-0 ud-p-0"
      >
      
     
        <coinChip 
          :href="showPointer ? '#claimID'+k : undefined"
          class="ud-m-1 ud-opacity-100"
          v-for="t in v"
          v-bind:key="t"
          :coinName="main.getTokenName(k)"
          :coinImg="'coins/' + k + '.gif'"
          :coinDesc="main.getTokenDesc(k)"
          :coinRarity="main.getTokenRarity(k)"
          :owned="main.userBalance[k].balance >= t"
        />
     

      
        

       
      
      </div>

      </div>
     
    </div>
  </div>
</template>

<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  cursor: default;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

<script>
import coinCard from "../components/coinCard.vue";
import coinChip from "../components/coinChip.vue";

export default {
  name: "TClaimable",
  components: {
    coinChip: coinChip,
    coinCard: coinCard,
  },
  data: () => ({
    claimed: false,
    claiming: false,
    error: undefined,
    showError: false,
    reward: undefined,
    coinBalance: 0,
    reqs: [],
    cost: 0,
    canComplete: false,
  }),
  props: {
    showPointer: {
      default: false
    },
    claimID: {
      default: 0,
    },
    claimableType: {
      default: 0, // 0 bonus, 1 CH, 2 aureus
    },
    disableButton: {
      default: false,
    },
    claimableTitle: undefined,
    claimableDesc: undefined,
    claimableBonus: undefined,
  },
  watch: {
    claimID: {
      handler() {
        this.setData();
      },
      deep: true,
    }
  },
  created() {
    this.main = this.$router.app.$children[0];
  },
  mounted() {
    this.setData();
  },
  updated() {},
  methods: {
    getRequirements(rs) {
      var reqs = {};
      rs.forEach((m) => {
        reqs[m.token_id] = m.amount;
      });
      return reqs;
    },
    setData() {
      this.claimed = false;
      this.claiming = false;
      this.error = undefined;
      this.showError = false;
      this.reward = 0;
      this.reqs = [];
      this.cost = 0;
      this.canComplete = false;      

      if (this.claimableType == 1) {
        this.main.chData.forEach((m) => {
          if (m.claimID == this.claimID) {
            this.reward = m.token_id;
            this.reqs = this.getRequirements(m.reqs);
            this.cost = m.cost;
          } else {
            m.reqs.forEach((sm) => {
              if (sm.claimID == this.claimID) {
                this.reward = sm.token_id;
                this.reqs = this.getRequirements(sm.reqs);
                this.cost = sm.cost;
              } else {
                sm.reqs.forEach((s) => {
                  if (s.claimID == this.claimID) {
                    this.reward = s.token_id;
                    this.reqs = this.getRequirements(s.reqs);
                    this.cost = s.cost;
                  } else {
                    s.reqs.forEach((s1) => {
                      if (s1.claimID == this.claimID) {
                        this.reward = s1.token_id;
                        this.reqs = this.getRequirements(s1.reqs);
                        this.cost = s1.cost;
                      }
                    });
                  }
                });
              }
            });
          }
        });
      } else if (this.claimableType == 0) {
        this.main.bonusData.forEach((m) => {
          if (m.claimID == this.claimID) {
            this.reward = m.token_id;
            this.reqs = this.getRequirements(m.reqs);
            this.cost = m.cost;
          }
        });
      } else {
        this.main.aureusData.forEach((m) => {
          if (m.claimID == this.claimID) {
            this.reward = m.token_id;
            this.reqs = this.getRequirements(m.reqs);
            this.cost = m.cost;
          }
        });
      }
      var canComplete = true;
      Object.keys(this.reqs).forEach((k) => {
        if (this.main.userBalance[k].balance < this.reqs[k])
          canComplete = false;
      });
      this.canComplete = canComplete;
      
      this.coinBalance = this.main.userBalance[this.reward].balance;
    },
    complete() {
      this.$emit("claiming");
      this.claiming = true;
      this.showError = false;
      this.error = undefined;
      this.main.tezos.setWalletProvider(this.main.wallet);
      this.main.tezos.wallet
        .at(this.main.CONTRACT_ADDRESS)
        .then((contract) => {
          return contract.methods
            .complete(this.claimID)
            .send({ amount: this.cost });
        })
        .then(async (op) => {
          console.log(op);
          console.log("Awaiting for " + op.opHash + " to be confirmed...");
          await op.confirmation(1);
          return op.opHash;
        })
        .then(async (hash) => {
          console.log("OK: " + hash);
          this.rewardName = this.missionReward;
          var tid = this.main.getTokenID(this.rewardName);
          this.rewardDesc = this.main.getTokenDesc(tid);
          this.rewardRarity = this.main.getTokenRarity(tid);
          this.rewardImg = this.main.CDN + "/coins/" + tid + ".gif";
          this.claimed = true;
          this.claiming = false;
          /*this.main.getBalances();
          this.main.setBalance();*/
          this.$emit("success");
        })
        .catch((error) => {
          this.claimed = false;
          this.claiming = false;
          this.error = error;
          this.showError = true;
          console.log(error);
          console.log("Error: " + JSON.stringify(error, null, 2));
          this.$emit("error", error);
        });
    },
  },
};
</script>    